import React, { useEffect, useState } from 'react'
import { Button, DateProvider, Icon, Modal, Text, Input } from '../../../atoms'
import { Card, Unlogged } from '../../../molecules'

import { Auth, Client } from '../../../../contexts'
import { DateCalendar } from '@mui/x-date-pickers'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { formatSecondsToHHhmm } from '../../../../utils'
import placeholder from '../../../../assets/icons/Image.svg'

import dayjs from 'dayjs'
import fr from 'dayjs/locale'
import { useNavigate } from 'react-router-dom'
dayjs.locale(fr)

const Prestation = () => {
    const { prestationSelected, setPrestationSelected } = Client.useBasket()

    return (
        <Modal.default
            isOpen={prestationSelected}
            title={prestationSelected?.name}
            setIsOpen={() => setPrestationSelected(null)}
        >
            <Text.Paragraph.Normal className="text-[24px] font-medium text-center">
                {prestationSelected?.price}€ - {prestationSelected?.duration / 60000}min
            </Text.Paragraph.Normal>
            <Text.Paragraph.Normal className="text-center">{prestationSelected?.description}</Text.Paragraph.Normal>
            {prestationSelected?._photos.map((photo) => {
                return (
                    <div
                        key={photo._id}
                        className="w-[42vw] h-[42vw] bg-light-gray rounded relative border border-opacity-10"
                        style={{
                            backgroundImage: `url(${photo?.thumbnailUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                        }}
                    ></div>
                )
            })}
        </Modal.default>
    )
}

function ServerDay(props) {
    const { getMonth } = Client.useBasket()
    const {
        highlightedDays = [],
        day,
        lastDateFetch,
        outsideCurrentMonth,
        address,
        duration,
        loading,
        ...other
    } = props

    const isSelected = highlightedDays.filter((day) => day.date === dayjs(props.day).format('YYYY-MM-DD'))?.length > 0
    const isMonthLoading = !day.endOf('month').isBefore(lastDateFetch, 'day')

    useEffect(() => {
        if (!loading && isMonthLoading && day.endOf('month').isSame(day, 'day')) {
            console.log('need fetch for month', day)
            getMonth(day, day.startOf('month'), address, duration)
        }
    }, [outsideCurrentMonth])

    if (!isMonthLoading) {
        if (!outsideCurrentMonth) {
            return (
                <>
                    {props.isFirstVisibleCell &&
                        Array.from(
                            { length: day.startOf('month').day() > 1 ? day.startOf('month').day() - 1 : 7 },
                            (_, index) => index + 1
                        ).map((d) => (
                            <div className="relative mx-auto opacity-0" key={d}>
                                <PickersDay
                                    {...other}
                                    outsideCurrentMonth={outsideCurrentMonth}
                                    day={day.add(d, 'day')}
                                    onClick={() => {}}
                                />
                            </div>
                        ))}
                    <div className="relative mx-auto">
                        <div
                            className={`absolute top-0 bottom-0 rounded-full bg-purple ${
                                isSelected && !outsideCurrentMonth ? 'w-full h-full' : ''
                            } ${isSelected ? '' : 'bg-white z-50 h-full w-full opacity-60'}`}
                        ></div>
                        <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
                    </div>
                    {props.isLastVisibleCell &&
                        Array.from({ length: 7 - day.endOf('month').day() }, (_, index) => index + 1).map((d) => (
                            <div className="relative mx-auto opacity-0" key={d}>
                                <PickersDay
                                    {...other}
                                    outsideCurrentMonth={outsideCurrentMonth}
                                    day={day.add(d, 'day')}
                                    onClick={() => {}}
                                />
                            </div>
                        ))}
                </>
            )
        }
    } else {
        if (day.startOf('month').format('YYYY-MM-DD') === day.format('YYYY-MM-DD') && !outsideCurrentMonth)
            return (
                <div key={day.toString()} className="w-[90vw] h-full mx-auto text-center">
                    <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                    Mois en cours de chargement
                </div>
            )
        else return
    }
}

const Algorithme = () => {
    const { id, token, isLogged } = Auth.useAuth()
    const { address } = Client.useCoiffeur()
    const {
        prestations,
        algorithm,
        loading,
        getAlgorithmResult,
        setAlgorithm,
        results,
        step,
        setStep,
        changePrestation,
        bookgSchedule,
        setLoading,
        lastDateFetch,
    } = Client.useBasket()

    const [daySelected, setDaySelected] = useState()
    const [bookableDays, setbookableDays] = useState(results?.filter((result) => result.bookings?.length > 0))
    const [bookings, setBookings] = useState([])
    const [bookingSelected, setBookingSelected] = useState()

    useEffect(() => {
        setbookableDays(results?.filter((result) => result.bookings?.length > 0))
    }, [results])

    const [price, setPrice] = useState(0)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        if (prestations) {
            const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

            let newDuration = 0
            let newPrice = 0
            prestationsSelected.forEach((prestation) => {
                newDuration += (prestation.duration / 1000) * prestation.quantity
                const splitted = prestation.price.split(' ')
                splitted.forEach((split) => {
                    if (parseFloat(split).toString() != 'NaN') newPrice += parseFloat(split) * prestation.quantity
                })
            })

            setPrice(newPrice)
            setDuration(newDuration)
        }
    }, [prestations])

    useEffect(() => {
        if (daySelected) {
            const isSelected = bookableDays.filter((day) => day.date === daySelected.format('YYYY-MM-DD'))

            if (isSelected.length > 0) {
                setBookings(isSelected[0]?.bookings)
            } else {
                setBookings([])
            }
        }
    }, [daySelected])

    const [informationStep, setInformationStep] = useState(false)

    useEffect(() => {
        if (isLogged()) {
            if (bookingSelected) {
                bookgSchedule(daySelected.format('YYYY-MM-DD'), bookingSelected, address)
            }
        }
    }, [id, token])

    const [form, setForm] = useState('login')

    return (
        <Modal.default
            isOpen={step === 'schedule'}
            title={
                informationStep
                    ? `${form === 'login' ? 'Connectez vous' : 'Inscrivez vous'} pour prendre rendez-vous`
                    : "Choix de la date et de l'heure"
            }
            setIsOpen={() => {
                if (algorithm) {
                    if (informationStep) {
                        setInformationStep(false)
                    } else {
                        setAlgorithm(false)
                    }
                } else {
                    setStep('general')
                }
            }}
            confirmButton={
                algorithm ? (
                    informationStep ? (
                        <></>
                    ) : (
                        <Button.Primary
                            iconRight={<Icon.ArrowNext className={'w-5'} />}
                            onClick={() => {
                                setInformationStep(true)
                            }}
                            disabled={!bookingSelected?.start}
                        >
                            Suivant
                        </Button.Primary>
                    )
                ) : (
                    <Button.Primary
                        iconRight={<Icon.ArrowNext className="w-5" />}
                        onClick={async () => {
                            await getAlgorithmResult(address)
                            setLoading(false)
                        }}
                        disabled={prestations?.filter((prestation) => prestation.quantity > 0).length === 0}
                    >
                        Suivant
                    </Button.Primary>
                )
            }
        >
            {algorithm ? (
                informationStep ? (
                    <>
                        <Unlogged.Booking.AuthForm form={form} setForm={setForm} />
                    </>
                ) : (
                    <div>
                        <DateProvider>
                            <DateCalendar
                                value={daySelected}
                                onChange={(value) => {
                                    setDaySelected(value)
                                }}
                                views={['day']}
                                slots={{ day: ServerDay }}
                                slotProps={{
                                    day: {
                                        highlightedDays: bookableDays,
                                        lastDateFetch: lastDateFetch,
                                        address: address,
                                        duration: duration,
                                        loading: loading,
                                    },
                                }}
                                maxDate={dayjs().add(365, 'day')}
                            />
                        </DateProvider>

                        <div className="grid gap-3">
                            <Text.Paragraph.Normal className="capitalize text-center font-medium text-[18px]">
                                {daySelected && daySelected.locale('fr').format('dddd DD MMMM')}
                            </Text.Paragraph.Normal>
                            {bookings.length > 0 && (
                                <div className="grid grid-cols-3 gap-3">
                                    {bookings.map((booking) => {
                                        return (
                                            <div className="mx-auto" key={booking.start}>
                                                <div
                                                    className={`transition-all py-2 px-4 text-center border border-black rounded ${
                                                        booking.start === bookingSelected?.start
                                                            ? 'bg-blue'
                                                            : 'border-purple bg-light-gray'
                                                    }`}
                                                    onClick={() => setBookingSelected(booking)}
                                                >
                                                    {dayjs('2000-01-01')
                                                        .set('hour', booking.start / 3600)
                                                        .set('minutes', (booking.start / 60) % 60)
                                                        .format('HH[h]mm')}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                )
            ) : (
                <div className="grid gap-10 mb-10">
                    <div className="grid gap-4">
                        <Text.Paragraph.Normal className="text-[px]">Prestations</Text.Paragraph.Normal>
                        {prestations?.map((prestation, index) => {
                            if (prestation.quantity > 0) {
                                return (
                                    <Card.PrestationRecap
                                        key={prestation._id}
                                        srcImage={`${
                                            prestation._photos?.length > 0
                                                ? prestation._photos[0].thumbnailUrl
                                                : placeholder
                                        }`}
                                        alt={`Avatar Boy`}
                                        title={prestation.name}
                                        subTitle={`${prestation.price} - ${prestation.duration / 1000 / 60}min`}
                                        quantity={prestation.quantity}
                                        changePrestation={(quantity) => changePrestation(index, prestation, quantity)}
                                    />
                                )
                            }
                        })}
                    </div>
                    <div className="grid gap-4">
                        <Text.Paragraph.Normal>Total</Text.Paragraph.Normal>
                        <div className="grid grid-cols-2">
                            <div className="flex items-center">
                                <Icon.Coins className="w-9" />
                                <Text.Paragraph.Normal>{price}€</Text.Paragraph.Normal>
                            </div>
                            <div className="flex items-center">
                                <Icon.Clock className="w-9" />
                                <Text.Paragraph.Normal>{duration / 60}min</Text.Paragraph.Normal>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-4">
                        <Text.Paragraph.Normal>Adresse</Text.Paragraph.Normal>
                        <Text.Paragraph.Normal className="italic">{address.formatted}</Text.Paragraph.Normal>
                    </div>
                </div>
            )}
        </Modal.default>
    )
}

const Success = () => {
    const { coiffeurSelected } = Client.useCoiffeur()
    const { prestations, dateSelected, timeSelected, step, setStep } = Client.useBasket()

    const [price, setPrice] = useState(0)
    const [priceVariable, setPriceVariable] = useState(false)
    const [localPrestations, setLocalPrestations] = useState([])

    useEffect(() => {
        if (prestations) {
            const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

            let newPrice = 0
            let isPriceVariable = false
            prestationsSelected.forEach((prestation) => {
                const splitted = prestation.price.split(' ')
                splitted.forEach((split) => {
                    if (parseFloat(split).toString() != 'NaN') newPrice += parseFloat(split) * prestation.quantity
                })
                if (splitted.length > 1) {
                    isPriceVariable = true
                }
            })

            const prestationsArray = []
            prestationsSelected.map((prestation) => {
                for (let i = 0; i < prestation.quantity; i++) {
                    prestationsArray.push(prestation._id)
                }
            })
            setLocalPrestations(prestationsSelected)
            setPrice(newPrice)
            setPriceVariable(isPriceVariable)
        }
    }, [prestations])

    const displayPrice = priceVariable ? `À partir de ${price}€` : `${price}€`

    return (
        <Modal.default
            title={`Vous recevrez un mail de validation lorsque votre coiffeur aura confirmé votre rendez-vous`}
            isOpen={step === 'confirm'}
            setIsOpen={() => {
                setStep()
            }}
            // confirmButton={<Button.Primary iconLeft={<Icon.CalendarCheck />}>Ajouter à mon calendrier</Button.Primary>}
            backButton={<></>}
        >
            <Card.PrestationRecapConfirmed
                srcImage={`${coiffeurSelected?._profil?.avatar?.thumbnailUrl}`}
                title={`${coiffeurSelected?._profil.firstName} ${coiffeurSelected?._profil.lastName}`}
                subTitle={localPrestations.map(
                    (prestation, index) => `${index !== 0 ? ' - ' : ''}${prestation.name} (${prestation.type})`
                )}
                infoRdv={`${dayjs(dateSelected).format('ddd DD MMMM')} à ${formatSecondsToHHhmm(
                    timeSelected
                )} - ${displayPrice}€`}
                text={'Vous recevrez un mail de validation lorsque votre coiffeur aura confirmé votre rendez-vous'}
            />
        </Modal.default>
    )
}

const Address = () => {
    const { address, coiffeurSelected, coiffeurs, getCoiffeurs } = Client.useCoiffeur()
    const { step, setStep } = Client.useBasket()

    const [verified, setVerified] = useState(false)
    const [isInTheZone, setIsInTheZone] = useState(false)
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setLoading(false)
        console.log('coiffeur error', coiffeurs)
        if (coiffeurs) {
            let coiffeurInZone = coiffeurs?.filter(
                (coiffeur) =>
                    coiffeur._id == coiffeurSelected._id &&
                    address?.formatted === coiffeur.addressOfTheZone?.formatted &&
                    coiffeur.isInTheZone
            )
            if (coiffeurInZone?.length > 0) {
                setIsInTheZone(true)
                setVerified(true)
            } else {
                setVerified(false)
            }
        }
    }, [coiffeurs])

    useEffect(() => {
        if (
            coiffeurs?.filter((coiffeur) => coiffeur._id == coiffeurSelected._id).length <= 0 ||
            address?.formatted === ''
        ) {
            setStep('address')
        }
    }, [step])

    if (step === 'address')
        return (
            <div className="grid gap-5">
                <Text.h2>Veuillez renseigner votre adresse</Text.h2>
                <Text.Paragraph.Normal>
                    Pour prendre rendez-vous, vous devez renseigner l'adresse de votre domicile afin de vérifier que ce
                    coiffeur puisse se déplacer chez vous
                </Text.Paragraph.Normal>
                <Input.Searchbar
                    setValue={async (value) => {
                        setLoading(true)
                        await getCoiffeurs(value)
                    }}
                    setLoading={(value) => {
                        if (value) {
                            setLoading(value)
                        }
                    }}
                />
                {loading ? (
                    <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                ) : verified ? (
                    isInTheZone ? (
                        <Button.Primary iconLeft={<Icon.ArrowNext />} onClick={() => setStep()}>
                            Réserver
                        </Button.Primary>
                    ) : (
                        <>
                            <Text.Paragraph.Normal>
                                Ce professionnel ne se déplace pas dans votre secteur
                            </Text.Paragraph.Normal>
                        </>
                    )
                ) : (
                    <>
                        {address?.formatted !== '' ? (
                            <>
                                <Text.Paragraph.Normal>
                                    Ce professionnel ne se déplace pas dans votre secteur
                                </Text.Paragraph.Normal>
                                <Button.Primary iconLeft={<Icon.ArrowNext />} onClick={() => navigate('/')}>
                                    Rechercher un coiffeur
                                </Button.Primary>
                            </>
                        ) : (
                            <>
                                <Text.Paragraph.Normal>Veuillez saisir une adresse</Text.Paragraph.Normal>
                            </>
                        )}
                    </>
                )}
            </div>
        )
}

export { Prestation, Algorithme, Success, Address }
