import React, { useEffect, useState } from 'react'

import dayjs from 'dayjs'

import { Button, List, Modal, Icon, Input, Text, Loader } from '../../atoms'
import { Hairdresser as Components, Return } from '..'
import { Image } from '../../organisms'

import { Hairdresser } from '../../../contexts'

import { formatSecondsToInput, arraysEqual } from '../../../utils'

const Navigation = () => {
    const { activeView, setActiveView } = Hairdresser.usePrestation()

    return (
        <div className="grid gap-5">
            <Text.h1 className="text-center">Mes prestations</Text.h1>
            <div className="grid grid-cols-3 gap-3">
                <div
                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                        activeView === 'femme' ? 'bg-blue border border-black' : 'bg-light-gray'
                    }`}
                    onClick={() => setActiveView('femme')}
                >
                    Femme
                </div>
                <div
                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                        activeView === 'homme' ? 'bg-blue border border-black' : 'bg-light-gray'
                    }`}
                    onClick={() => setActiveView('homme')}
                >
                    Homme
                </div>
                <div
                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                        activeView === 'enfant' ? 'bg-blue border border-black' : 'bg-light-gray'
                    }`}
                    onClick={() => setActiveView('enfant')}
                >
                    Enfant
                </div>
            </div>
        </div>
    )
}

const Prestations = () => {
    const { prestations, activeView, handleSave, setStep } = Hairdresser.usePrestation()

    const [prestationSorted, setPrestationSorted] = useState([])
    const [forfait, setForfait] = useState([])

    useEffect(() => {
        const prestationsClassic = prestations.filter((prestation) => {
            if (prestation.type.toLowerCase().indexOf(activeView) !== -1) {
                if (!prestation.forfait) return true
            }
            return false
        })

        const prestationsForfait = prestations.filter((prestation) => {
            if (prestation.type.toLowerCase().indexOf(activeView) !== -1) {
                if (prestation.forfait) return true
            }
            return false
        })

        setPrestationSorted(prestationsClassic)
        setForfait(prestationsForfait)
    }, [prestations, activeView])

    return (
        <div className="grid gap-5">
            <List.ListWithPics title={'Prestations'}>
                <Components.DragAndDrop.Prestations
                    initialItems={prestationSorted}
                    setNewValues={(value) => handleSave([...value, ...forfait])}
                />
            </List.ListWithPics>
            <List.ListWithPics title={'Forfaits'}>
                <Components.DragAndDrop.Prestations
                    initialItems={forfait}
                    setNewValues={(value) => handleSave([...value, ...prestationSorted])}
                />
            </List.ListWithPics>
            <div className="mx-auto">
                <Button.Primary onClick={() => setStep('new')} iconLeft={<Icon.Add className="w-5" />}>
                    Ajouter une prestation
                </Button.Primary>
            </div>
        </div>
    )
}

const Form = () => {
    const { step, prestationSelected, setStep, handleCreate } = Hairdresser.usePrestation()

    const [forfait, setForfait] = useState(false)
    const [name, setName] = useState('')
    const [type, setType] = useState('Femmes')
    const [price, setPrice] = useState()
    const [duration, setDuration] = useState()
    const [description, setDescription] = useState('')
    const [priceVariable, setPriceVariable] = useState(false)

    useEffect(() => {
        if (prestationSelected) {
            setForfait(prestationSelected.forfait)
            setName(prestationSelected.name)
            setType(prestationSelected.type)
            setPrice(prestationSelected.price)
            setDuration(formatSecondsToInput(prestationSelected.duration / 1000))
            setDescription(prestationSelected.description)
        } else {
            setForfait()
            setName()
            setType()
            setPrice()
            setDuration()
            setDescription()
        }
    }, [prestationSelected])

    return (
        <>
            <Modal.default
                title="Ajouter une prestation"
                isOpen={step === 'new'}
                setIsOpen={() => {}}
                backMethod={step === 'new' ? () => setStep() : () => setStep('new')}
                confirmButton={
                    <Button.Primary
                        iconLeft={<Icon.Add className="w-5" />}
                        onClick={async () => {
                            await handleCreate(
                                forfait,
                                name,
                                type,
                                priceVariable ? `A partir de ${price}` : price,
                                duration * 1000,
                                description
                            )
                            setStep()
                        }}
                    >
                        Ajouter
                    </Button.Primary>
                }
            >
                <div className="grid gap-8">
                    <div className="grid grid-cols-2 gap-3">
                        <div
                            className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                !forfait ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setForfait(false)}
                        >
                            Prestation
                        </div>
                        <div
                            className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                forfait ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setForfait(true)}
                        >
                            Forfait
                        </div>
                    </div>
                    <div className="grid">
                        <Input.Text.Classic
                            labelValue={'Nom de la prestation'}
                            placeholder={'Coupe femme'}
                            value={name}
                            setValue={(value) => setName(value)}
                        />
                        <div className="grid gap-2 my-4">
                            <p className="text-lg font-medium">Catégorie</p>
                            <div className="grid grid-cols-3 gap-3">
                                <div
                                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                        type === 'Femmes' ? 'bg-purple border border-black' : 'bg-light-gray'
                                    }`}
                                    onClick={() => setType('Femmes')}
                                >
                                    Femme
                                </div>
                                <div
                                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                        type === 'Hommes' ? 'bg-purple border border-black' : 'bg-light-gray'
                                    }`}
                                    onClick={() => setType('Hommes')}
                                >
                                    Homme
                                </div>
                                <div
                                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                        type === 'Enfants' ? 'bg-purple border border-black' : 'bg-light-gray'
                                    }`}
                                    onClick={() => setType('Enfants')}
                                >
                                    Enfant
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="ml-2 text-lg font-medium">Prix</label>
                                <div className="flex ml-2 space-x-1">
                                    <Input.ChecboxLittle
                                        initialValue={priceVariable}
                                        setValue={(value) => setPriceVariable(value)}
                                    />
                                    <p className="text-xs">A partir de</p>
                                </div>
                                <Input.Text.Classic
                                    value={price}
                                    setValue={(value) => setPrice(value)}
                                    rightIcon={<Icon.Coins className="w-5" />}
                                    hideClearButton={true}
                                />
                            </div>

                            <Input.Date.Time
                                labelValue={'Durée'}
                                value={dayjs()
                                    .set('hours', duration / 3600)
                                    .set('minute', (duration / 60) % 60)}
                                onSave={(value) => {
                                    const tempDate = dayjs(value, 'HH:mm')
                                    setDuration(tempDate.locale('fr').hour() * 3600 + tempDate.minute() * 60)
                                }}
                            />
                        </div>
                        <Input.Text.TextArea
                            labelValue="Description"
                            placeholder="Description pour le client"
                            value={description}
                            setValue={(value) => setDescription(value)}
                        />
                    </div>
                </div>
            </Modal.default>
        </>
    )
}

const Modification = () => {
    const { prestationSelected, setStep, setPrestationSelected, handleEdit, handleDelete } = Hairdresser.usePrestation()

    const [forfait, setForfait] = useState(false)
    const [name, setName] = useState('')
    const [type, setType] = useState('Femmes')
    const [price, setPrice] = useState()
    const [duration, setDuration] = useState()
    const [description, setDescription] = useState('')
    const [photos, setPhotos] = useState()
    const [deleteIndex, setDeleteIndex] = useState()
    const [priceVariable, setPriceVariable] = useState(false)

    const [addPhoto, setAddPhoto] = useState(false)
    const [deletePrestation, setDeletePrestation] = useState(false)

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (prestationSelected) {
            setForfait(prestationSelected.forfait)
            setName(prestationSelected.name)
            setType(prestationSelected.type)
            setDuration(prestationSelected.duration / 1000)
            setDescription(prestationSelected.description)

            const splitted = prestationSelected.price.split(' ')
            splitted.forEach((split) => {
                if (parseFloat(split).toString() != 'NaN') setPrice(parseFloat(split))
            })
            if (splitted.length > 1) {
                setPriceVariable(true)
            }

            if (!photos) {
                setPhotos(prestationSelected._photos ? prestationSelected._photos : [])
            }

            setLoaded(true)
        } else {
            setForfait()
            setName()
            setType()
            setPrice()
            setDuration()
            setDescription()
            setPhotos([])
        }
    }, [prestationSelected])

    const handleSave = async () => {
        await handleEdit(
            forfait,
            name,
            type,
            priceVariable ? `A partir de ${price}` : price.toString(),
            duration * 1000,
            description,
            photos
        )
    }

    useEffect(() => {
        if (prestationSelected) {
            if (photos) {
                if (!arraysEqual(photos, prestationSelected._photos)) {
                    handleSave()
                }
            }
        }
    }, [photos])

    useEffect(() => {
        if (prestationSelected) {
            if (duration) {
                handleSave()
            }
        }
    }, [type])

    return (
        <>
            {loaded ? (
                <div className="grid gap-5">
                    <Return
                        onClick={async () => {
                            handleSave()
                            setStep()
                            setPrestationSelected()
                        }}
                    >
                        <div
                            onClick={() => {
                                setDeletePrestation(true)
                            }}
                        >
                            <Icon.Trash className="w-7" />
                        </div>
                    </Return>
                    <div className="grid gap-4">
                        <Input.Text.Classic
                            labelValue={'Nom de la prestation'}
                            placeholder={'Coupe femme'}
                            value={name}
                            setValue={(value) => setName(value)}
                        />
                        <div className="grid gap-2 my-4">
                            <p className="text-lg font-medium">Catégorie</p>
                            <div className="grid grid-cols-3 gap-3">
                                <div
                                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                        type === 'Femmes' ? 'bg-purple border border-black' : 'bg-light-gray'
                                    }`}
                                    onClick={() => setType('Femmes')}
                                >
                                    Femme
                                </div>
                                <div
                                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                        type === 'Hommes' ? 'bg-purple border border-black' : 'bg-light-gray'
                                    }`}
                                    onClick={() => setType('Hommes')}
                                >
                                    Homme
                                </div>
                                <div
                                    className={`inline-block px-4 py-2 rounded transition-all text-center ${
                                        type === 'Enfants' ? 'bg-purple border border-black' : 'bg-light-gray'
                                    }`}
                                    onClick={() => setType('Enfants')}
                                >
                                    Enfant
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <label className="ml-2 text-lg font-medium">Prix</label>
                                <div className="flex ml-2 space-x-1">
                                    <Input.ChecboxLittle
                                        initialValue={priceVariable}
                                        setValue={(value) => setPriceVariable(value)}
                                    />
                                    <p className="text-xs">A partir de</p>
                                </div>
                                <Input.Text.Classic
                                    value={price}
                                    setValue={(value) => setPrice(value)}
                                    rightIcon={<Icon.Coins className="w-5" />}
                                    hideClearButton={true}
                                />
                            </div>
                            <Input.Date.Time
                                labelValue={'Durée'}
                                value={dayjs()
                                    .set('hours', duration / 3600)
                                    .set('minute', (duration / 60) % 60)}
                                onChange={(value) => console.log(value)}
                                onSave={(value) => {
                                    const tempDate = dayjs(value, 'HH:mm')
                                    setDuration(tempDate.locale('fr').hour() * 3600 + tempDate.minute() * 60)
                                }}
                            />
                        </div>
                        <Input.Text.TextArea
                            labelValue="Description"
                            placeholder="Description pour le client"
                            value={description}
                            setValue={(value) => setDescription(value)}
                            onBlur={() => handleSave()}
                        />
                        <div className="grid gap-2">
                            <Text.Paragraph.Normal className="text-xl font-medium">
                                Galerie photos
                            </Text.Paragraph.Normal>
                            <div className="grid grid-cols-2 gap-2">
                                {photos?.map((photo, index) => {
                                    return (
                                        <>
                                            <div
                                                key={photo._id}
                                                className="relative rounded-lg w-[40vw] h-[40vw] bg-light-gray border border-black border-opacity-10"
                                                style={{
                                                    backgroundImage: `url(${photo.thumbnailUrl})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center center',
                                                    backgroundSize: 'cover',
                                                }}
                                            >
                                                <div
                                                    className="absolute p-2 rounded-full bottom-2 right-2 bg-light-gray"
                                                    onClick={() => {
                                                        setDeleteIndex(index)
                                                    }}
                                                >
                                                    <Icon.Trash className="w-6" />
                                                </div>
                                            </div>
                                        </>
                                    )
                                })}
                                <div
                                    className="w-[40vw] h-[40vw] bg-light-gray flex items-center justify-center rounded-lg relative"
                                    onClick={() => {
                                        setAddPhoto(true)
                                    }}
                                >
                                    <Icon.Camera className="text-gray-500 w-14" />
                                    <Icon.Add className="absolute w-4 text-gray-500 right-11 top-11" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Image.Upload
                        title="Ajouter un média"
                        setIsOpen={() => setAddPhoto(false)}
                        isOpen={addPhoto}
                        afterUpload={async (value) => {
                            const newPhotos = [...photos]
                            newPhotos.push(value)
                            setPhotos(newPhotos)
                            setAddPhoto(false)
                        }}
                    />
                    <Modal.default
                        title={'Supprimer le média ?'}
                        isOpen={typeof deleteIndex === 'number'}
                        setIsOpen={() => setDeleteIndex()}
                        backButton={<Button.Secondary onClick={() => setDeleteIndex()}>Annuler</Button.Secondary>}
                        confirmButton={
                            <Button.Red
                                onClick={() => {
                                    const newPhotos = [...photos]
                                    newPhotos.splice(deleteIndex, 1)

                                    setPhotos(newPhotos)
                                    setDeleteIndex()
                                }}
                            >
                                Confirmer
                            </Button.Red>
                        }
                    ></Modal.default>
                    <Modal.default
                        title={'Supprimer la prestation ?'}
                        isOpen={deletePrestation}
                        setIsOpen={() => setDeletePrestation(false)}
                        backButton={
                            <Button.Secondary onClick={() => setDeletePrestation(false)}>Annuler</Button.Secondary>
                        }
                        confirmButton={
                            <Button.Red
                                onClick={() => {
                                    handleDelete()
                                }}
                            >
                                Confirmer
                            </Button.Red>
                        }
                    ></Modal.default>
                </div>
            ) : (
                <Loader />
            )}
        </>
    )
}

export { Navigation, Prestations, Form, Modification }
