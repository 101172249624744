import React, { useEffect, useState } from 'react'
import { Card, Return } from '../../../components/molecules'
import { Client as ClientComponents } from '../../../components/organisms'

import Boy from '../../../assets/avatar/Boy.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { Client } from '../../../contexts'
import { Text } from '../../../components/atoms'

const Services = () => {
    const navigate = useNavigate()
    const { userId } = useParams()
    const { coiffeurSelected, getCoiffeurById, coiffeurs, setCoiffeurSelected, address } = Client.useCoiffeur()
    const [loading, setLoading] = useState(false)
    const { prestations, setStep, step } = Client.useBasket()

    useEffect(() => {
        if (prestations) {
            if (typeof prestations[0]?.quantity === 'number') {
                setLoading(false)
            }
        }
    }, [prestations])

    useEffect(() => {
        if (!coiffeurSelected) {
            setLoading(true)
            getCoiffeurById(userId)
        }
    }, [])

    useEffect(() => {
        if (coiffeurs) {
            let coiffeurInZone = coiffeurs.filter(
                (coiffeur) =>
                    coiffeur._id == coiffeurSelected._id &&
                    address?.formatted === coiffeur.addressOfTheZone?.formatted &&
                    coiffeur.isInTheZone
            )
            console.log(coiffeurInZone)
            if (coiffeurInZone.length > 0) {
                setCoiffeurSelected(coiffeurInZone[0])
                setStep()
            } else {
                setStep('address')
            }
        }
    }, [coiffeurs])
    
    console.log(coiffeurs)

    return (
        <div className="">
            {loading ? (
                <div></div>
            ) : (
                <>
                    <Return
                        className="mb-8"
                        onClick={() => {
                            navigate(`/client/hairdresser/${coiffeurSelected?._id}/details`)
                        }}
                    />
                    <Card.HairdresserPresentation
                        srcImage={`${
                            coiffeurSelected?._profil?.avatar ? coiffeurSelected?._profil?.avatar.thumbnailUrl : Boy
                        }`}
                        alt={`Avatar professionnel de beauté Iléa`}
                        title={`${coiffeurSelected?._profil.firstName} ${coiffeurSelected?._profil.lastName}`}
                        xp={`${coiffeurSelected?._profil.yearsExperience} années d'expériences`}
                        note={`5`}
                    />
                    <ClientComponents.Basket.Prestations />
                    <div className="pb-14"></div>
                    {step !== 'address' && (
                        <div className="fixed flex justify-center bottom-0 py-3 bg-white w-[100%] z-[50] left-0">
                            <div
                                className={`group flex items-center max-[350px]:px-3 px-5 py-3 bg-purple w-fit rounded-[50px] border-[1.5px] border-black hover:bg-black hover:text-white focus:px-8 disabled:opacity-60 disabled:bg-black disabled:text-white active:text-white active:text-opacity-80 transition-all gap-3`}
                                onClick={() => {
                                    if (coiffeurSelected.isInTheZone) {
                                        setStep('schedule')
                                    } else {
                                        setStep('address')
                                    }
                                }}
                            >
                                <Text.Clickable>Réserver</Text.Clickable>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default Services
