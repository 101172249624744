import React, { useEffect, useState } from 'react'
import { Button, Icon, Input, Modal, Text, List as ListAtom } from '../../../atoms'

import { Hairdresser } from '../../../../contexts'
import { Search, List } from '../../../organisms/HairDresser/Clients'
import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'
dayjs.locale(fr)
import Boy from '../../../../assets/avatar/Boy.svg'
import Girl from '../../../../assets/avatar/Girl.svg'
import { Link } from '@nextui-org/react'
import { formatSecondsToHHhmm, prestationObjectsToArray } from '../../../../utils'
import { useNavigate } from 'react-router'

const Delete = () => {
    const { date, handleDate } = Hairdresser.useAgenda()
    const { handleBookingSelected, bookingSelected, method, deleteBooking } = Hairdresser.useBooking()

    const [reason, setReason] = useState('')

    const [background, setBackground] = useState(Boy)
    useEffect(() => {
        if (bookingSelected) {
            setBackground(
                bookingSelected._client?._profil?.avatar?.url
                    ? bookingSelected._client._profil.avatar.url
                    : Math.random() > 0.5
                    ? Boy
                    : Girl
            )
        }
    }, [bookingSelected])

    return (
        <Modal.default
            isOpen={method === 'delete'}
            title="Annuler la réservation ?"
            setIsOpen={(value) => {
                if (!value) handleBookingSelected(null, null)
            }}
            confirmButton={
                <Button.Red
                    onClick={async () => {
                        await deleteBooking(reason)
                        handleDate(date)
                        handleBookingSelected(null, null)
                    }}
                    iconRight={<Icon.ArrowNext className="w-5" />}
                >
                    Annuler
                </Button.Red>
            }
        >
            {bookingSelected && (
                <div className="grid gap-3 text-center">
                    <p className="text-[20px] font-medium leading-8 text-center">
                        {bookingSelected._prestation &&
                            (() => {
                                const countMap = new Map()
                                bookingSelected._prestation.forEach((prestation) => {
                                    countMap.set(prestation.name, (countMap.get(prestation.name) || 0) + 1)
                                })

                                return [...countMap.keys()]
                                    .map((name) => {
                                        const count = countMap.get(name)
                                        return count > 1 ? `${name} x${count}` : name
                                    })
                                    .join(', ')
                            })()}
                    </p>
                    <p className="text-[24px] font-medium leading-8 text-center capitalize">
                        {dayjs(bookingSelected.date)
                            .set('second', bookingSelected.from)
                            .format('ddd DD MMMM - HH[h]mm')}
                    </p>
                    <Text.Paragraph.Normal>{bookingSelected.address?.formatted}</Text.Paragraph.Normal>
                    <div className="flex items-center mx-auto space-x-3">
                        <div
                            style={{
                                backgroundImage: `url(${background})`,
                                backgroundPosition: `center center`,
                                backgroundSize: '100%',
                            }}
                            className="w-10 h-10 rounded-full bg-purple"
                        />
                        <Text.Paragraph.Normal>
                            {bookingSelected._client?._profil?.firstName} {bookingSelected._client?._profil?.lastName}
                        </Text.Paragraph.Normal>
                    </div>
                    <Input.Text.TextArea
                        labelValue="Motif d'annulation (optionnel)"
                        placeholder={`Votre commentaire pour ${bookingSelected._client?._profil?.firstName}`}
                        value={reason}
                        setValue={(value) => setReason(value)}
                    />
                </div>
            )}
        </Modal.default>
    )
}

const Update = () => {
    const { handleBookingSelected, bookingSelected, method, setMethod, editBooking } = Hairdresser.useBooking()
    const { setDate } = Hairdresser.useAgenda()

    const steps = ['general', 'date']
    const [step, setStep] = useState(steps[0])

    const [inputDate, setInputDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [from, setFrom] = useState()
    const [comment, setComment] = useState('')

    const [background, setBackground] = useState(Boy)

    useEffect(() => {
        if (bookingSelected) {
            setInputDate(bookingSelected.date)
            setFrom(bookingSelected.from)
            setBackground(
                bookingSelected._client?._profil?.avatar?.url
                    ? bookingSelected._client._profil.avatar.url
                    : Math.random() > 0.5
                    ? Boy
                    : Girl
            )
        }
    }, [bookingSelected])
    return (
        <Modal.default
            isOpen={method === 'update'}
            title={step === steps[0] ? 'Décaler la réservation ?' : "Choix du jour et de l'heure"}
            setIsOpen={() => {}}
            backButton={
                step === steps[0] ? (
                    <Button.Secondary iconLeft={<Icon.ArrowBack className="w-5" />} onClick={() => setMethod('closed')}>
                        Retour
                    </Button.Secondary>
                ) : (
                    <Button.Secondary iconLeft={<Icon.ArrowBack className="w-5" />} onClick={() => setStep(steps[0])}>
                        Retour
                    </Button.Secondary>
                )
            }
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={
                        step === steps[0]
                            ? async () => {
                                  await editBooking(
                                      dayjs(inputDate).format('YYYY-MM-DD'),
                                      {
                                          from: from,
                                          to: from + bookingSelected.duration,
                                      },
                                      comment
                                  )
                                  handleBookingSelected(null, null)
                              }
                            : () => setStep(steps[0])
                    }
                >
                    Décaler
                </Button.Primary>
            }
        >
            {step === steps[0] && (
                <div className="grid gap-3 text-center">
                    <p className="text-[20px] font-medium leading-8 text-center">
                        {bookingSelected?._prestation &&
                            (() => {
                                const countMap = new Map()
                                bookingSelected._prestation.forEach((prestation) => {
                                    countMap.set(prestation.name, (countMap.get(prestation.name) || 0) + 1)
                                })

                                return [...countMap.keys()]
                                    .map((name) => {
                                        const count = countMap.get(name)
                                        return count > 1 ? `${name} x${count}` : name
                                    })
                                    .map((name, index) => {
                                        const prestation = bookingSelected._prestation.find((p) => p.name === name)
                                        return (
                                            <p className="text-[20px] font-medium leading-8 text-center" key={index}>
                                                {name}{' '}
                                                {prestation &&
                                                    prestation.price !== undefined &&
                                                    `- ${prestation.price}€`}
                                            </p>
                                        )
                                    })
                            })()}
                    </p>

                    <p className="text-[24px] font-medium leading-8 text-center capitalize">
                        {dayjs(bookingSelected?.date)
                            .set('second', bookingSelected?.from)
                            .format('ddd DD MMMM - HH[h]mm')}
                    </p>
                    <Text.Paragraph.Normal>{bookingSelected?.address?.formatted}</Text.Paragraph.Normal>
                    <div className="flex items-center mx-auto space-x-3">
                        <div
                            style={{
                                backgroundImage: `url(${background})`,
                                backgroundPosition: `center center`,
                                backgroundSize: '100%',
                            }}
                            className="w-10 h-10 rounded-full bg-purple"
                        />
                        <Text.Paragraph.Normal>
                            {bookingSelected?._client?._profil?.firstName} {bookingSelected?._client?._profil?.lastName}
                        </Text.Paragraph.Normal>
                    </div>
                    {bookingSelected?._client?._profil?.address && (
                        <div className="grid gap-1">
                            <Text.Paragraph.Normal className="text-lg font-medium text-left">
                                Date et heure
                            </Text.Paragraph.Normal>
                            <Button.Secondary
                                className="flex justify-between w-full capitalize"
                                iconRight={<Icon.Calendar className="w-5" />}
                                onClick={() => {
                                    setStep(steps[1])
                                }}
                            >
                                {dayjs(inputDate).set('second', from).locale('fr').format('ddd DD MMMM - HH[h]mm')}
                            </Button.Secondary>
                        </div>
                    )}
                    <Input.Text.TextArea
                        labelValue="Motif de modification"
                        placeholder={`Votre commentaire pour ${bookingSelected?._client?._profil?.firstName}`}
                        value={comment}
                        setValue={(value) => setComment(value)}
                    />
                </div>
            )}
            {step === steps[1] && (
                <div className="grid gap-3">
                    <Input.Date.Day
                        value={dayjs(inputDate)}
                        setValue={(value) => {
                            setInputDate(dayjs(value).format('YYYY-MM-DD'))
                            setDate(dayjs(value).format('YYYY-MM-DD'))
                        }}
                    />
                    <Input.Date.Time
                        value={dayjs('2000-00-00')
                            .set('hours', from / 3600)
                            .set('minute', (from / 60) % 60)}
                        onChange={(value) => console.log(value)}
                        onSave={(value) => {
                            const tempDate = dayjs(value, 'HH:mm')
                            setFrom(tempDate.locale('fr').hour() * 3600 + tempDate.minute() * 60)
                        }}
                    />
                </div>
            )}
        </Modal.default>
    )
}

const Create = () => {
    const navigate = useNavigate()

    const { handleBookingSelected, method, setMethod, creatBooking } = Hairdresser.useBooking()
    const { clientSelected, setClientSelected } = Hairdresser.useClients()
    const { prestations, activeView, setActiveView } = Hairdresser.usePrestation()
    const { handleDate, date, setDate } = Hairdresser.useAgenda()

    const [prestationsSelected, setPrestationsSelected] = useState(prestations)
    const [inputDate, setInputDate] = useState(date)
    const [daySelected, setDaySelected] = useState(dayjs().format('YYYY-MM-DD'))

    useEffect(() => {
        setInputDate(date)
        setDaySelected(date)
    }, [date])

    const steps = ['general', 'client', 'prestations', 'horaire']
    const [step, setStep] = useState(steps[0])
    const [from, setFrom] = useState(dayjs().hour() * 3600 + dayjs().minute() * 60)

    useEffect(() => {
        setPrestationsSelected(prestations.map((prestation) => ({ ...prestation, quantity: 0 })))
    }, [prestations])

    const [note, setNote] = useState('')
    const [price, setPrice] = useState(0)
    const [priceVariable, setPriceVariable] = useState(false)
    const [duration, setDuration] = useState(0)
    const [to, setTo] = useState()

    const changePrestation = (index, prestation, quantity) => {
        const newPrestations = [...prestationsSelected]
        const newPrestationSelected = { ...prestation, quantity: quantity }
        newPrestations[index] = newPrestationSelected

        setPrestationsSelected(newPrestations)
    }

    useEffect(() => {
        if (from != null && duration != null) {
            const fromTo = from + duration
            setTo(fromTo)
        }
    }, [from, duration])

    useEffect(() => {
        const newPrestationsSelected = prestationsSelected.filter((prestation) => prestation.quantity > 0)

        let newDuration = 0
        let newPrice = 0
        let newPriceVariable = false

        newPrestationsSelected.forEach((prestation) => {
            newDuration += (prestation.duration / 1000) * prestation.quantity

            const splitted = prestation.price.split(' ')
            splitted.forEach((split) => {
                if (parseFloat(split).toString() != 'NaN') newPrice += parseFloat(split) * prestation.quantity
            })
            if (splitted.length > 1) {
                newPriceVariable = true
            }
        })

        setPrice(newPrice)
        setPriceVariable(newPriceVariable)
        setDuration(newDuration)
    }, [prestationsSelected])

    useEffect(() => {
        if (clientSelected?._id) {
            setStep(steps[0])
        }
    }, [clientSelected])

    return (
        <Modal.default
            isOpen={method === 'create'}
            title={
                step === steps[0]
                    ? 'Ajouter un RDV'
                    : step === steps[1]
                    ? 'Choisir un client'
                    : step === steps[2]
                    ? 'Choisir les prestations'
                    : "Choix de la date et de l'heure"
            }
            setIsOpen={() => {}}
            backMethod={step === steps[0] ? () => setMethod('closed') : () => setStep(steps[0])}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={async () => {
                        if (step === steps[0]) {
                            if (daySelected && from && to) {
                                await creatBooking(
                                    daySelected,
                                    prestationObjectsToArray(prestationsSelected),
                                    { ...clientSelected._user },
                                    from,
                                    to,
                                    duration,
                                    price,
                                    note,
                                    priceVariable
                                )

                                handleDate(daySelected)
                                setClientSelected()
                                handleBookingSelected(null, null)
                                setPrestationsSelected([])
                                setNote('')
                            }
                        } else {
                            setStep(steps[0])
                        }
                    }}
                >
                    {step === steps[0] ? 'Ajouter' : 'Valider'}
                </Button.Primary>
            }
        >
            {step === steps[0] && (
                <div className="grid gap-3 text-center">
                    <div className="grid gap-1">
                        <Text.Paragraph.Normal className="text-lg font-medium text-left">Client</Text.Paragraph.Normal>
                        <Button.Secondary
                            className="flex justify-between w-full capitalize"
                            iconRight={<Icon.Search className="w-5" />}
                            onClick={() => setStep(steps[1])}
                        >
                            {clientSelected
                                ? `${clientSelected?._user?._profil?.firstName} ${clientSelected?._user?._profil?.lastName}`
                                : 'Rechercher'}
                        </Button.Secondary>
                    </div>
                    <div className="grid gap-1">
                        <Text.Paragraph.Normal className="text-lg font-medium text-left">Adresse</Text.Paragraph.Normal>
                        <Text.Paragraph.Normal className="text-left">
                            {clientSelected
                                ? clientSelected?._user?._profil?.address?.formatted
                                : 'Sélectionne un client'}
                        </Text.Paragraph.Normal>
                    </div>
                    {prestationsSelected.filter((prestation) => prestation.quantity > 0).length > 0 ? (
                        <div className="grid gap-4 p-3 rounded">
                            <div
                                className="flex justify-between text-xl font-medium lext-left"
                                onClick={() => setStep(steps[2])}
                            >
                                Sélectionner <Icon.ArrowNext className="w-4" />
                            </div>

                            {prestationsSelected.map((prestation, index) => {
                                if (prestation.quantity > 0) {
                                    return (
                                        <div className="grid grid-cols-12 gap-2" key={prestation._id}>
                                            <div className="col-span-2 p-3 text-gray-500 rounded-full bg-light-gray">
                                                <Icon.Image className="w-5" />
                                            </div>
                                            <div className="grid col-span-6 gap-1">
                                                <Text.Paragraph.Normal>{prestation.name}</Text.Paragraph.Normal>
                                                <Text.Paragraph.Small>
                                                    {prestation.price}€ - {prestation.duration / 60000}min
                                                </Text.Paragraph.Small>
                                            </div>
                                            <div className="grid items-center grid-cols-3 col-span-4 gap-2 px-4 py-3 text-center border border-black rounded-full">
                                                <div
                                                    onClick={() => {
                                                        changePrestation(
                                                            index,
                                                            prestation,
                                                            prestation.quantity > 1 ? prestation.quantity - 1 : 0
                                                        )
                                                    }}
                                                >
                                                    <Icon.Less className="w-4" />
                                                </div>
                                                <Text.Paragraph.Normal>
                                                    {prestation.quantity || 0}
                                                </Text.Paragraph.Normal>
                                                <div
                                                    onClick={() => {
                                                        changePrestation(index, prestation, prestation.quantity + 1)
                                                    }}
                                                >
                                                    <Icon.Add className="w-4" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            <div className="flex space-x-3">
                                <Text.Paragraph.Normal>Total:</Text.Paragraph.Normal>
                                <Text.Paragraph.Normal>
                                    {priceVariable ? 'À partir de ' : ''}
                                    {price}€ -{' '}
                                </Text.Paragraph.Normal>
                                <Text.Paragraph.Normal>{duration / 60}min</Text.Paragraph.Normal>
                            </div>
                        </div>
                    ) : (
                        <div className="grid gap-1">
                            <Text.Paragraph.Normal className="text-lg font-medium text-left">
                                Prestations
                            </Text.Paragraph.Normal>
                            <Button.Secondary
                                className="flex justify-between w-full capitalize"
                                iconRight={<Icon.ArrowNext className="w-5" />}
                                onClick={() => {
                                    setStep(steps[2])
                                    setPrestationsSelected(
                                        prestations.map((prestation) => ({ ...prestation, quantity: 0 }))
                                    )
                                }}
                            >
                                Sélectionner
                            </Button.Secondary>
                        </div>
                    )}

                    {clientSelected?._user?._profil?.address &&
                        prestationsSelected.filter((prestation) => prestation.quantity > 0).length !== 0 && (
                            <div className="grid gap-1">
                                <Text.Paragraph.Normal className="text-lg font-medium text-left">
                                    Date et heure
                                </Text.Paragraph.Normal>
                                <Button.Secondary
                                    className="flex justify-between w-full capitalize"
                                    iconRight={<Icon.Calendar className="w-5" />}
                                    onClick={() => {
                                        setStep(steps[3])
                                    }}
                                >
                                    <>
                                        {dayjs(inputDate).format('MM/DD/YYYY')} - {formatSecondsToHHhmm(from)}{' '}
                                    </>
                                </Button.Secondary>
                            </div>
                        )}
                    <Input.Text.TextArea
                        labelValue="Commentaire"
                        placeholder="Votre commentaire privé"
                        value={note}
                        setValue={(value) => {
                            setNote(value)
                        }}
                    />
                </div>
            )}
            {step === steps[1] && (
                <div className="grid gap-3">
                    <Button.Primary
                        onClick={() => {
                            navigate('/hairdresser/clients?new')
                        }}
                    >
                        Créer un client
                    </Button.Primary>
                    <Search />
                    <List />
                </div>
            )}
            {step === steps[2] && (
                <div className="grid gap-6">
                    <div className="grid grid-cols-3 gap-3">
                        <button
                            className={`inline-block px-4 py-2 rounded transition-all ${
                                activeView === 'femme' ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setActiveView('femme')}
                        >
                            Femme
                        </button>
                        <button
                            className={`inline-block px-4 py-2 rounded transition-all ${
                                activeView === 'homme' ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setActiveView('homme')}
                        >
                            Homme
                        </button>
                        <button
                            className={`inline-block px-4 py-2 rounded transition-all ${
                                activeView === 'enfant' ? 'bg-blue border border-black' : 'bg-light-gray'
                            }`}
                            onClick={() => setActiveView('enfant')}
                        >
                            Enfant
                        </button>
                    </div>
                    <div className="grid gap-3">
                        <ListAtom.ListWithPics title="Prestations">
                            {
                                //map asyc
                                prestationsSelected.map((prestation, index) => {
                                    if (prestation.type.toLowerCase().indexOf(activeView) !== -1) {
                                        if (!prestation.forfait)
                                            return (
                                                <div
                                                    className="grid items-center grid-cols-12 gap-3"
                                                    key={prestation._id}
                                                >
                                                    <div className="col-span-2 p-3 text-gray-500 rounded-full bg-light-gray">
                                                        <Icon.Image className="w-5" />
                                                    </div>
                                                    <div className="grid col-span-6 gap-1">
                                                        <Text.Paragraph.Normal>{prestation.name}</Text.Paragraph.Normal>
                                                        <Text.Paragraph.Small>
                                                            {prestation.price}€ - {prestation.duration / 60000}min
                                                        </Text.Paragraph.Small>
                                                    </div>
                                                    <div className="grid items-center grid-cols-3 col-span-4 gap-2 px-4 py-3 text-center border border-black rounded-full">
                                                        <div
                                                            onClick={() => {
                                                                changePrestation(
                                                                    index,
                                                                    prestation,
                                                                    prestation.quantity > 1
                                                                        ? prestation.quantity - 1
                                                                        : 0
                                                                )
                                                            }}
                                                        >
                                                            <Icon.Less className="w-4" />
                                                        </div>
                                                        <Text.Paragraph.Normal>
                                                            {prestation.quantity || 0}
                                                        </Text.Paragraph.Normal>
                                                        <div
                                                            onClick={() => {
                                                                changePrestation(
                                                                    index,
                                                                    prestation,
                                                                    prestation.quantity + 1
                                                                )
                                                            }}
                                                        >
                                                            <Icon.Add className="w-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                    }
                                })
                            }
                        </ListAtom.ListWithPics>
                    </div>
                    <div>
                        {prestationsSelected?.filter(
                            (prestation) =>
                                prestation.forfait && prestation.type.toLowerCase().indexOf(activeView) !== -1
                        ).length > 0 && (
                            <div className="grid gap-3">
                                <ListAtom.ListWithPics title="Forfait">
                                    {prestationsSelected.map((prestation, index) => {
                                        if (prestation.type.toLowerCase().indexOf(activeView) !== -1) {
                                            if (prestation.forfait)
                                                return (
                                                    <div
                                                        className="grid items-center grid-cols-12 gap-3"
                                                        key={prestation._id}
                                                    >
                                                        <div className="col-span-2 p-3 text-gray-500 rounded-full bg-light-gray">
                                                            <Icon.Image className="w-5" />
                                                        </div>
                                                        <div className="grid col-span-6 gap-1">
                                                            <Text.Paragraph.Normal>
                                                                {prestation.name}
                                                            </Text.Paragraph.Normal>
                                                            <Text.Paragraph.Small>
                                                                {prestation.price}€ - {prestation.duration / 60000}
                                                                min
                                                            </Text.Paragraph.Small>
                                                        </div>
                                                        <div className="grid items-center grid-cols-3 col-span-4 gap-2 px-4 py-3 text-center border border-black rounded-full">
                                                            <div
                                                                onClick={() => {
                                                                    changePrestation(
                                                                        index,
                                                                        prestation,
                                                                        prestation.quantity > 1
                                                                            ? prestation.quantity - 1
                                                                            : 0
                                                                    )
                                                                }}
                                                            >
                                                                <Icon.Less className="w-4" />
                                                            </div>
                                                            <Text.Paragraph.Normal>
                                                                {prestation.quantity || 0}
                                                            </Text.Paragraph.Normal>
                                                            <div
                                                                onClick={() => {
                                                                    changePrestation(
                                                                        index,
                                                                        prestation,
                                                                        prestation.quantity + 1
                                                                    )
                                                                }}
                                                            >
                                                                <Icon.Add className="w-4" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    })}
                                </ListAtom.ListWithPics>
                            </div>
                        )}
                    </div>
                    <div className="grid gap-4 p-3 border rounded border-blue">
                        <Text.Paragraph.Normal className="font-medium font-xl">Récap</Text.Paragraph.Normal>
                        {prestationsSelected.filter((prestation) => prestation.quantity > 0).length > 0 ? (
                            prestationsSelected.map((prestation, index) => {
                                if (prestation.quantity > 0) {
                                    return (
                                        <div className="grid grid-cols-12 gap-2" key={prestation._id}>
                                            <div className="col-span-2 p-3 text-gray-500 rounded-full bg-light-gray">
                                                <Icon.Image className="w-5" />
                                            </div>
                                            <div className="grid col-span-6 gap-1">
                                                <Text.Paragraph.Normal>{prestation.name}</Text.Paragraph.Normal>
                                                <Text.Paragraph.Small>
                                                    {prestation.price}€ - {prestation.duration / 60000}min
                                                </Text.Paragraph.Small>
                                            </div>
                                            <div className="grid items-center grid-cols-3 col-span-4 gap-2 px-4 py-3 text-center border border-black rounded-full">
                                                <div
                                                    onClick={() => {
                                                        changePrestation(
                                                            index,
                                                            prestation,
                                                            prestation.quantity > 1 ? prestation.quantity - 1 : 0
                                                        )
                                                    }}
                                                >
                                                    <Icon.Less className="w-4" />
                                                </div>
                                                <Text.Paragraph.Normal>
                                                    {prestation.quantity || 0}
                                                </Text.Paragraph.Normal>
                                                <div
                                                    onClick={() => {
                                                        changePrestation(index, prestation, prestation.quantity + 1)
                                                    }}
                                                >
                                                    <Icon.Add className="w-4" />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        ) : (
                            <Text.Paragraph.Normal>Aucune prestation sélectionnée</Text.Paragraph.Normal>
                        )}
                    </div>
                </div>
            )}
            {step === steps[3] && (
                <div className="grid gap-3">
                    <Input.Date.Day
                        value={dayjs(inputDate)}
                        setValue={(value) => {
                            setDaySelected(dayjs(value).format('YYYY-MM-DD'))
                            setInputDate(dayjs(value).format('YYYY-MM-DD'))
                            setDate(dayjs(value).format('YYYY-MM-DD'))
                        }}
                    />
                    <Input.Date.Time
                        value={dayjs(from)
                            .set('hours', from / 3600)
                            .set('minute', (from / 60) % 60)}
                        onChange={() => {}}
                        onSave={(value) => {
                            const tempDate = dayjs(value, 'HH:mm')
                            setFrom(tempDate.locale('fr').hour() * 3600 + tempDate.minute() * 60)
                        }}
                    />
                </div>
            )}
        </Modal.default>
    )
}

const CreatePrivate = () => {
    const { handleBookingSelected, method, createPrivate, setMethod } = Hairdresser.useBooking()
    const { date, handleDate } = Hairdresser.useAgenda()

    const steps = ['general', 'start', 'end']
    const [step, setStep] = useState(steps[0])

    const [allDay, setAllDay] = useState(false)
    const [day, setDay] = useState(date)
    const [dayEnd, setDayEnd] = useState(date)
    const [start, setStart] = useState(36000)
    const [end, setEnd] = useState(36000)

    useEffect(() => {
        setDay(date)
        setDayEnd(date)
    }, [date])

    return (
        <Modal.default
            isOpen={method === 'create-private'}
            title={
                step === steps[0]
                    ? 'Ajouter une indisponibilité'
                    : step === steps[1]
                    ? 'Date et heure de début'
                    : 'Date et heure de fin'
            }
            setIsOpen={(value) => {
                console.log(value)
            }}
            backMethod={step === steps[0] ? () => setMethod('closed') : () => setStep(steps[0])}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={
                        step === steps[0]
                            ? async () => {
                                  await createPrivate(day, start, allDay ? 23 * 3600 : end, dayEnd, allDay)
                                  handleBookingSelected(null, null)
                                  handleDate(day)
                              }
                            : () => setStep(steps[0])
                    }
                >
                    {step === steps[0] ? 'Ajouter' : 'Confirmer'}
                </Button.Primary>
            }
        >
            {step === steps[0] && (
                <div className="grid gap-3 pb-4 text-center">
                    <div className="flex mb-4 space-x-4">
                        <Input.Checkbox initialValue={allDay} setValue={(value) => setAllDay(value)} />{' '}
                        <Text.Paragraph.Normal>Journée entière</Text.Paragraph.Normal>
                    </div>
                    {!allDay ? (
                        <div className="grid gap-1">
                            <Text.Paragraph.Normal className="text-lg font-medium text-left">
                                Date et heure de début
                            </Text.Paragraph.Normal>
                            <Button.Secondary
                                className="flex justify-between w-full capitalize"
                                iconRight={<Icon.Calendar className="w-5" />}
                                onClick={() => setStep(steps[1])}
                            >
                                {dayjs(day)
                                    .set('hours', start / 3600)
                                    .set('minute', (start / 60) % 60)
                                    .format('ddd DD MMMM - HH[h]mm')}
                            </Button.Secondary>
                        </div>
                    ) : (
                        <Input.Date.Day
                            value={dayjs(day)}
                            setValue={(value) => {
                                setDay(value)
                            }}
                        />
                    )}
                    {!allDay ? (
                        <div className="grid gap-1">
                            <Text.Paragraph.Normal className="text-lg font-medium text-left">
                                Date et heure de fin
                            </Text.Paragraph.Normal>
                            <Button.Secondary
                                className="flex justify-between w-full capitalize"
                                iconRight={<Icon.Calendar className="w-5" />}
                                onClick={() => setStep(steps[2])}
                            >
                                {dayjs(dayEnd)
                                    .set('hours', end / 3600)
                                    .set('minute', (end / 60) % 60)
                                    .format('ddd DD MMMM - HH[h]mm')}
                            </Button.Secondary>
                        </div>
                    ) : (
                        <Input.Date.Day
                            value={dayjs(dayEnd)}
                            setValue={(value) => {
                                setDayEnd(value)
                            }}
                        />
                    )}
                </div>
            )}
            {step === steps[1] && (
                <div className="grid gap-3 pb-4">
                    <Input.Date.Day
                        labelValue="Date"
                        value={dayjs(day)}
                        setValue={(value) => {
                            setDay(value)
                        }}
                    />
                    <Input.Date.Time
                        labelValue="Heure"
                        value={dayjs('2000-00-00')
                            .set('hours', start / 3600)
                            .set('minute', (start / 60) % 60)}
                        onChange={(value) => console.log(value)}
                        onSave={(value) => {
                            const tempDate = dayjs(value, 'HH:mm')
                            setStart(tempDate.locale('fr').hour() * 3600 + tempDate.minute() * 60)
                        }}
                    />
                </div>
            )}{' '}
            {step === steps[2] && (
                <div className="grid gap-3 pb-4">
                    <Input.Date.Day
                        value={dayjs(dayEnd)}
                        setValue={(value) => {
                            setDayEnd(value)
                        }}
                    />
                    <Input.Date.Time
                        labelValue="Heure de fin"
                        value={dayjs('2000-00-00')
                            .set('hours', end / 3600)
                            .set('minute', (end / 60) % 60)}
                        onChange={(value) => console.log(value)}
                        onSave={(value) => {
                            const tempDate = dayjs(value, 'HH:mm')
                            setEnd(tempDate.locale('fr').hour() * 3600 + tempDate.minute() * 60)
                        }}
                    />
                </div>
            )}
        </Modal.default>
    )
}

const General = () => {
    const { handleBookingSelected, bookingSelected, method } = Hairdresser.useBooking()

    const [background, setBackground] = useState(Boy)

    useEffect(() => {
        if (bookingSelected) {
            setBackground(
                bookingSelected._client?._profil?.avatar?.url
                    ? bookingSelected._client._profil.avatar.url
                    : Math.random() > 0.5
                    ? Boy
                    : Girl
            )
        }
    }, [bookingSelected])

    const prestationsDisplay = bookingSelected?._prestation
        .reduce((accumulator, currentValue) => {
            const valueFind = accumulator.find((item) => item.name === currentValue.name)

            if (valueFind) {
                valueFind.count = (valueFind.count || 1) + 1
            } else {
                accumulator.push({ ...currentValue, count: 1 })
            }
            return accumulator
        }, [])
        .map((prestation) => `${prestation.name}${prestation.count > 1 ? ` x${prestation.count}` : ''}`)
        .join(', ')

    return (
        <Modal.default
            isOpen={method === 'general'}
            title={
                <span className="capitalize">
                    {dayjs(bookingSelected?.date).format('ddd DD MMM')} de {formatSecondsToHHhmm(bookingSelected?.from)}{' '}
                    à {formatSecondsToHHhmm(bookingSelected?.to)}
                </span>
            }
            setIsOpen={(value) => {
                if (!value) handleBookingSelected(null, null)
            }}
            backButton={
                <Button.Secondary
                    iconLeft={<Icon.ArrowBack />}
                    onClick={() => {
                        handleBookingSelected(null, null)
                    }}
                >
                    Retour
                </Button.Secondary>
            }
            confirmButton={
                <Link
                    href={`https://www.waze.com/ul?ll=${bookingSelected?.address?.lat}%2C${bookingSelected?.address?.lon}&navigate=yes`}
                    target="_blank"
                >
                    <Button.Primary iconRight={<Icon.ArrowNext className="w-5" />}>Itinéraire</Button.Primary>
                </Link>
            }
        >
            {bookingSelected && (
                <div className="grid gap-3 text-center">
                    <p className="text-[20px] font-medium leading-8 text-center">{prestationsDisplay}</p>
                    <p className="text-[20px] font-medium leading-8 text-center capitalize">
                        {bookingSelected.price}€ - {bookingSelected.duration / 60}min
                    </p>
                    <Text.Paragraph.Normal>{bookingSelected.address?.formatted}</Text.Paragraph.Normal>
                    <div className="flex flex-row justify-center space-x-6">
                        <div className="flex justify-center space-x-3">
                            <a href={`tel:${bookingSelected._client?._profil?.phoneNumber?.phone}`}>
                                <Icon.Phone className="w-5" />
                            </a>
                            <a href={`sms:${bookingSelected._client?._profil?.phoneNumber?.phone}`}>
                                <Icon.Message className="w-5" />
                            </a>
                        </div>
                        <Text.Paragraph.Normal>
                            {bookingSelected._client?._profil?.phoneNumber?.phone}
                        </Text.Paragraph.Normal>
                    </div>
                    <div className="flex items-center mx-auto space-x-3">
                        <div
                            style={{
                                backgroundImage: `url(${background})`,
                                backgroundPosition: `center center`,
                                backgroundSize: '100%',
                            }}
                            className="w-10 h-10 rounded-full bg-purple"
                        />
                        <Text.Paragraph.Normal>
                            {bookingSelected._client?._profil?.firstName} {bookingSelected._client?._profil?.lastName}
                        </Text.Paragraph.Normal>
                    </div>
                    <div>
                        {bookingSelected.note?.map((bookingNote) => (
                            <Text.Paragraph.Normal className="italic" key={bookingNote._id}>
                                {bookingNote.value}
                            </Text.Paragraph.Normal>
                        ))}
                    </div>
                </div>
            )}
        </Modal.default>
    )
}

const GeneralWaiting = () => {
    const { handleBookingSelected, bookingSelected, method } = Hairdresser.useBooking()

    const [background, setBackground] = useState(Boy)

    useEffect(() => {
        if (bookingSelected) {
            setBackground(
                bookingSelected._client?._profil?.avatar?.url
                    ? bookingSelected._client._profil.avatar.url
                    : Math.random() > 0.5
                    ? Boy
                    : Girl
            )
        }
    }, [bookingSelected])

    return (
        <Modal.default
            isOpen={method === 'generalWaiting'}
            title={
                <span className="capitalize">
                    {dayjs(bookingSelected?.date).format('ddd DD MMM')} de {formatSecondsToHHhmm(bookingSelected?.from)}{' '}
                    à {formatSecondsToHHhmm(bookingSelected?.to)}
                </span>
            }
            setIsOpen={(value) => {
                if (!value) handleBookingSelected(null, null)
            }}
            backButton={
                <Button.Red
                    iconLeft={<Icon.ArrowBack />}
                    onClick={() => {
                        handleBookingSelected(null, null)
                    }}
                >
                    Retour
                </Button.Red>
            }
        >
            {bookingSelected && (
                <div className="grid gap-3 text-center">
                    <p className="text-[20px] font-medium leading-8 text-center">
                        {bookingSelected._prestation &&
                            (() => {
                                const countMap = new Map()
                                bookingSelected._prestation.forEach((prestation) => {
                                    countMap.set(prestation.name, (countMap.get(prestation.name) || 0) + 1)
                                })

                                return [...countMap.keys()]
                                    .map((name) => {
                                        const count = countMap.get(name)
                                        return count > 1 ? `${name} x${count}` : name
                                    })
                                    .join(', ')
                            })()}
                    </p>
                    <p className="text-[20px] font-medium leading-8 text-center capitalize">
                        {bookingSelected.price}€ - {bookingSelected.duration / 60}min
                    </p>
                    <Text.Paragraph.Normal>{bookingSelected.address?.formatted}</Text.Paragraph.Normal>
                    <div className="flex items-center mx-auto space-x-3">
                        <div
                            style={{
                                backgroundImage: `url(${background})`,
                                backgroundPosition: `center center`,
                                backgroundSize: '100%',
                            }}
                            className="w-10 h-10 rounded-full bg-purple"
                        />
                        <Text.Paragraph.Normal>
                            {bookingSelected._client?._profil?.firstName} {bookingSelected._client?._profil?.lastName}
                        </Text.Paragraph.Normal>
                    </div>
                </div>
            )}
        </Modal.default>
    )
}

const Accept = () => {
    const { handleBookingSelected, bookingSelected, method, acceptBooking } = Hairdresser.useBooking()

    const [background, setBackground] = useState(Boy)

    useEffect(() => {
        if (bookingSelected) {
            setBackground(
                bookingSelected._client?._profil?.avatar?.url
                    ? bookingSelected._client._profil.avatar.url
                    : Math.random() > 0.5
                    ? Boy
                    : Girl
            )
        }
    }, [bookingSelected])

    return (
        <Modal.default
            isOpen={method === 'accept'}
            title={`${dayjs(bookingSelected?.date).format('ddd DD MMM')} de ${formatSecondsToHHhmm(
                bookingSelected?.from
            )} à ${formatSecondsToHHhmm(bookingSelected?.to)}`}
            setIsOpen={(value) => {
                if (!value) handleBookingSelected(null, null)
            }}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="w-5" />}
                    onClick={async () => {
                        await acceptBooking()
                        handleBookingSelected(null, null)
                    }}
                >
                    Confirmer
                </Button.Primary>
            }
        >
            {bookingSelected && (
                <div className="grid gap-3 text-center">
                    <p className="text-[20px] font-medium leading-8 text-center">
                        {bookingSelected._prestation &&
                            (() => {
                                const countMap = new Map()
                                bookingSelected._prestation.forEach((prestation) => {
                                    countMap.set(prestation.name, (countMap.get(prestation.name) || 0) + 1)
                                })

                                return [...countMap.keys()]
                                    .map((name) => {
                                        const count = countMap.get(name)
                                        return count > 1 ? `${name} x${count}` : name
                                    })
                                    .join(', ')
                            })()}
                    </p>
                    <p className="text-[20px] font-medium leading-8 text-center capitalize">
                        {bookingSelected.price}€ - {bookingSelected.duration / 60}min
                    </p>
                    <Text.Paragraph.Normal>{bookingSelected.address?.formatted}</Text.Paragraph.Normal>
                    <div className="flex items-center mx-auto space-x-3">
                        <div
                            style={{
                                backgroundImage: `url(${background})`,
                                backgroundPosition: `center center`,
                                backgroundSize: '100%',
                            }}
                            className="w-10 h-10 rounded-full bg-purple"
                        />
                        <Text.Paragraph.Normal>
                            {bookingSelected._client?._profil?.firstName} {bookingSelected._client?._profil?.lastName}
                        </Text.Paragraph.Normal>
                    </div>
                </div>
            )}
        </Modal.default>
    )
}

const DeleteBreak = () => {
    const { handleBookingSelected, bookingSelected, method } = Hairdresser.useBooking()

    return (
        <Modal.default
            isOpen={method === 'private'}
            title={
                <span className="capitalize">
                    {dayjs(bookingSelected?.date).format('ddd DD MMM')} de {formatSecondsToHHhmm(bookingSelected?.from)}{' '}
                    à {formatSecondsToHHhmm(bookingSelected?.to)}
                </span>
            }
            setIsOpen={(value) => {
                if (!value) handleBookingSelected(null, null)
            }}
            backButton={
                <Button.Secondary iconLeft={<Icon.ArrowBack />} onClick={() => handleBookingSelected(null, null)}>
                    Retour
                </Button.Secondary>
            }
            confirmButton={
                <Button.Red iconLeft={<Icon.Close />} onClick={() => handleBookingSelected('delete', bookingSelected)}>
                    Annuler
                </Button.Red>
            }
        >
            {bookingSelected && (
                <div className="grid gap-3 text-center">
                    {bookingSelected._prestation?.map((prestation) => (
                        <p className="text-[20px] font-medium leading-8 text-center" key={prestation._id}>
                            {prestation.name} - {prestation.price}€
                        </p>
                    ))}
                    <p className="text-[20px] font-medium leading-8 text-center">
                        Indisponibilité de {bookingSelected.duration / 60}min
                    </p>
                </div>
            )}
        </Modal.default>
    )
}

const AddNote = () => {
    const { method, handleBookingSelected, bookingSelected, addNote } = Hairdresser.useBooking()
    const [note, setNote] = useState('')

    useEffect(() => {
        if (bookingSelected && bookingSelected.note?.length > 0) {
            setNote(bookingSelected.note[0].value)
        }
    }, [bookingSelected])

    return (
        <Modal.default
            title={'Ajouter un commentaire'}
            isOpen={method === 'note'}
            setIsOpen={() => {
                setNote('')
                handleBookingSelected(null, null)
            }}
            confirmButton={
                <Button.Primary
                    iconRight={<Icon.ArrowNext className="h-8" />}
                    onClick={async () => {
                        await addNote(bookingSelected._client?._id, bookingSelected?._id, note)
                        handleBookingSelected(null, null)
                        setNote('')
                    }}
                >
                    Ajouter
                </Button.Primary>
            }
        >
            <Input.Text.TextArea
                placeholder={`Votre commentaire privé`}
                value={note}
                setValue={(value) => setNote(value)}
            />
        </Modal.default>
    )
}

export { Delete, Update, Create, CreatePrivate, General, Accept, DeleteBreak, AddNote, GeneralWaiting }
