/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'
import { useProfil } from '../profil/context'
import { useCoiffeur } from '../coiffeur'
import { prestationObjectsToArray } from '../../../utils'

import { socket } from '../../../api/api'

const BasketContext = createContext({
    prestations: Array,
    prestationSelected: Object,
    activeView: String,
    algorithm: Boolean,
    loading: Boolean,
    results: Array,
    step: String,
    dateSelected: String,
    timeSelected: String,
    setActiveView: (value) => {},
    setPrestationSelected: (value) => {},
    changePrestation: (index, prestation, quantity) => {},
    getAlgorithmResult: () => {},
    setAlgorithm: (value) => {},
    bookgSchedule: async (date, event, address) => {},
    setStep: (value) => {},
    bookgScheduleUnlogged: async (date, event, address, firstName, lastName, phoneNumber) => {},
    clearBasket: () => {},
    setPrestations: (value) => {},
})

export function BasketContextProvider({ children }) {
    const location = useLocation()
    const navigate = useNavigate()
    const { handleRequest } = useRequest()
    const { token, id } = useAuth()
    const { coiffeurSelected, isFavorite } = useCoiffeur()

    const [prestations, setPrestations] = useState([])
    const [prestationSelected, setPrestationSelected] = useState(null)
    const [activeView, setActiveView] = useState('femme')

    const [algorithm, setAlgorithm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState([])
    const [step, setStep] = useState('general')
    const [lastDateFetch, setLastDateFetch] = useState()

    const [dateSelected, setDateSelected] = useState()
    const [timeSelected, setTimeSelected] = useState()

    useEffect(() => {
        let newPrestations = []

        if (coiffeurSelected) {
            const params = new URL(document.location).searchParams
            const prestationParam = params.get('prestation')
            const prestationArray = prestationParam ? prestationParam.split(',') : []

            newPrestations = coiffeurSelected._profil._prestations.map((prestation) => {
                return { ...prestation, quantity: 0 }
            })

            prestationArray.forEach((prestationId) => {
                coiffeurSelected._profil._prestations.forEach((prestation, index) => {
                    if (prestation._id === prestationId) {
                        newPrestations[index] = { ...prestation, quantity: newPrestations[index].quantity + 1 }
                    }
                })
            })
        }

        setPrestations(newPrestations)
    }, [coiffeurSelected])

    const changePrestation = (index, prestation, quantity) => {
        const newPrestations = [...prestations]
        const newPrestationSelected = { ...prestation, quantity: quantity }
        newPrestations[index] = newPrestationSelected

        setPrestations(newPrestations)
    }

    const getAlgorithmResult = async (address) => {
        setAlgorithm(true)
        setLoading(true)
        const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

        let duration = 0
        let price = 0
        prestationsSelected.forEach((prestation) => {
            duration += (prestation.duration * prestation.quantity) / 1000
            const splitted = prestation.price.split(' ')
            splitted.forEach((split) => {
                if (parseFloat(split).toString() != 'NaN') price += parseFloat(split) * prestation.quantity
            })
        })

        const response = await handleRequest(
            'post',
            `client/algorithme/week`,
            {
                coiffeurId: coiffeurSelected._id,
                clientLocation: [address.lon, address.lat],
                duration: duration,
                firstDayOfCalcul: dayjs().add(1, 'day').format('YYYY-MM-DD'),
            },
            token,
            null,
            true
        )

        const resultArrayToPut = response.data.bookables
        if (response?.data?.bookables) {
            await getOneMoreWeek(dayjs().add(8, 'day').format('YYYY-MM-DD'), address, duration, resultArrayToPut, false)
        }
        return
    }

    const getOneMoreWeek = async (date, address, duration, resultArrayToPut, lastOne) => {
        const response = await handleRequest(
            'post',
            `client/algorithme/week`,
            {
                coiffeurId: coiffeurSelected._id,
                clientLocation: [address.lon, address.lat],
                duration: duration,
                firstDayOfCalcul: date,
            },
            token,
            null,
            true
        )

        const updatedResults = [...resultArrayToPut, ...response.data.bookables]
        setResults(updatedResults)
        setLastDateFetch(date)

        if (!lastOne) {
            let isLastOne = dayjs(date).diff(dayjs().format('YYYY-MM-DD'), 'day') > 120
            getOneMoreWeek(dayjs(date).add(8, 'day').format('YYYY-MM-DD'), address, duration, updatedResults, isLastOne)
            return
        } else {
            setLoading(false)
        }
    }

    const getMonth = async (lastDayOfMonth, firstDayOfMonth, address, duration) => {
        let resultArrayToPut = [...results]
        let currentWeekStart = firstDayOfMonth.startOf('week')
        let lastDate = firstDayOfMonth

        while (dayjs(lastDate).isBefore(lastDayOfMonth, 'day')) {
            const response = await handleRequest(
                'post',
                `client/algorithme/week`,
                {
                    coiffeurId: coiffeurSelected._id,
                    clientLocation: [address.lon, address.lat],
                    duration: duration,
                    firstDayOfCalcul: currentWeekStart.format('YYYY-MM-DD'),
                },
                token,
                null,
                true
            )

            resultArrayToPut = [...resultArrayToPut, ...response.data.bookables]
            lastDate = response.data.bookables[response.data.bookables.length - 1].date

            currentWeekStart = currentWeekStart.add(1, 'week').startOf('week')
        }
        setResults(resultArrayToPut)
        setLastDateFetch(lastDayOfMonth.add(1, 'day').format('YYYY-MM-DD'))

        return
    }

    const { favorites, handleSaveAttribute, getProfil } = useProfil()
    const addToFavorites = async () => {
        const favorite = favorites ? isFavorite(coiffeurSelected) : true

        if (!favorite) {
            const newFavorites = favorites.map((coiffeur) => coiffeur._owner._id)
            newFavorites.push(coiffeurSelected._id)
            await handleSaveAttribute('favorites', newFavorites)
            await getProfil()
        }
    }

    const bookgSchedule = async (date, event, address) => {
        const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

        let duration = 0
        let price = 0
        let priceVariable = false

        prestationsSelected.forEach((prestation) => {
            duration += (prestation.duration * prestation.quantity) / 1000

            const splitted = prestation.price.split(' ')
            splitted.forEach((split) => {
                if (parseFloat(split).toString() != 'NaN') price += parseFloat(split) * prestation.quantity
            })

            if (splitted.length > 1) priceVariable = true
        })

        const prestationsArray = prestationObjectsToArray(prestationsSelected)

        const response = await handleRequest(
            'post',
            `client/bookings/${coiffeurSelected._id}`,
            {
                date: date,
                prestations: prestationsArray,
                start: event.start,
                end: event.end,
                client: id,
                price: price,
                duration: duration,
                address: address,
                priceVariable: priceVariable,
            },
            token
        )

        if (response?.data) {
            await addToFavorites()
            setDateSelected(date)
            setTimeSelected(event.start)
            setAlgorithm(false)
            setStep('confirm')
            setTimeout(() => {
                setStep()
                navigate('/client/reservations', { state: { from: location.pathname } })
            }, 2000)
        }

        socket.emit('add_newBooking', { data: response.data })
    }
    const bookgScheduleUnlogged = async (date, event, address, firstName, lastName, phoneNumber) => {
        const prestationsSelected = prestations.filter((prestation) => prestation.quantity > 0)

        let duration = 0
        let price = 0
        let priceVariable = false

        prestationsSelected.forEach((prestation) => {
            duration += (prestation.duration * prestation.quantity) / 1000

            const splitted = prestation.price.split(' ')
            splitted.forEach((split) => {
                if (parseFloat(split).toString() != 'NaN') price += parseFloat(split) * prestation.quantity
            })

            if (splitted.length > 1) priceVariable = true
        })

        const prestationsArray = prestationObjectsToArray(prestationsSelected)

        const response = await handleRequest('post', `client/bookings/unlogged/${coiffeurSelected._id}`, {
            date: date,
            prestations: prestationsArray,
            start: event.start,
            end: event.end,
            price: price,
            duration: duration,
            address: address,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            priceVariable,
        })

        if (response?.data) {
            setDateSelected(date)
            setTimeSelected(event.start)
            setAlgorithm(false)
            setStep('confirm')
        }
    }

    const clearBasket = () => {
        setPrestations(prestations.map((prestation) => ({ ...prestation, quantity: 0 })))
        setDateSelected()
        setTimeSelected()
        setResults([])
    }

    return (
        <BasketContext.Provider
            value={{
                prestations: prestations,
                prestationSelected: prestationSelected,
                activeView: activeView,
                algorithm: algorithm,
                loading: loading,
                results: results,
                step: step,
                dateSelected: dateSelected,
                timeSelected: timeSelected,
                lastDateFetch: lastDateFetch,
                setActiveView: setActiveView,
                setPrestationSelected: setPrestationSelected,
                changePrestation: changePrestation,
                getAlgorithmResult: getAlgorithmResult,
                setAlgorithm: setAlgorithm,
                bookgSchedule: bookgSchedule,
                setStep: setStep,
                bookgScheduleUnlogged: bookgScheduleUnlogged,
                clearBasket: clearBasket,
                setPrestations: setPrestations,
                setLoading: setLoading,
                getMonth: getMonth,
            }}
        >
            {children}
        </BasketContext.Provider>
    )
}

export const useBasket = () => useContext(BasketContext)
