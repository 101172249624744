import React, { useEffect, useState } from 'react'

import { Icon, Map, Text } from '../../../atoms'
import { Card, Navigation as Handler } from '../../../molecules'

import { formatSecondsToHHhmm } from '../../../../utils'
import { Hairdresser } from '../../../../contexts'
import dayjs from 'dayjs'
import { Hairdresser as Components } from '../../../molecules'

const Navigation = ({ activeView, setActiveView }) => {
    const { date, data, setChangeDateSchedules } = Hairdresser.useAgenda()

    const [horaires, setHoraires] = useState([])

    useEffect(() => {
        if (data.loadedDays[date]) {
            setHoraires([{ start: data.loadedDays[date]?.startOfDay, end: data.loadedDays[date]?.endOfDay }])
        }
    }, [date, data])

    return (
        <div className="grid items-center grid-cols-4">
            <div className="flex items-center col-span-3 space-x-2">
                <Text.Paragraph.Normal className="text-[24px] font-bold capitalize">
                    {dayjs(date).format('ddd DD MMMM')}
                </Text.Paragraph.Normal>
                <div
                    className="bg-blue border border-black rounded px-2 text-[12px]"
                    onClick={() => {
                        setChangeDateSchedules(data.loadedDays[date])
                    }}
                >
                    {data.loadedDays[date] ? (
                        <>
                            {data.loadedDays[date].isWorkingDay ? (
                                <>
                                    {horaires.map((bloc, index) => (
                                        <>
                                            <span>
                                                {formatSecondsToHHhmm(bloc.start)} - {formatSecondsToHHhmm(bloc.end)}
                                            </span>
                                            {index !== horaires.length - 1 && <br />}
                                        </>
                                    ))}
                                </>
                            ) : (
                                'Repos'
                            )}
                        </>
                    ) : (
                        'Non définis'
                    )}
                </div>
            </div>
            <div className="flex items-center justify-end">
                <Handler.ToggleTab
                    icons={[
                        { icon: <Icon.Map className="w-4" />, key: 'map' },
                        { icon: <Icon.List className="w-4" />, key: 'list' },
                    ]}
                    onChange={(value) => {
                        setActiveView(value)
                    }}
                    active={activeView}
                />
            </div>
            <Components.Modal.WorkHours.default />
        </div>
    )
}

const ListView = ({ bookings }) => {
    const { handleBookingSelected } = Hairdresser.useBooking()

    return (
        <div className="relative grid h-auto gap-1">
            {bookings?.length > 1 && (
                <div className="absolute w-1 h-[95%] bg-gray-300 left-[72px] top-[13px] z-[11]"></div>
            )}
            {bookings?.map((booking, index) => {
                const startOfEvent = dayjs(booking.date)
                    .set('hours', booking.from / 3600)
                    .set('minutes', (booking.from / 60) % 60)

                const endOfEvent = dayjs(booking.date)
                    .set('hours', booking.to / 3600)
                    .set('minutes', (booking.to / 60) % 60)

                if (booking.state !== 'cancel' && booking.state !== 'waiting') {
                    if (!booking.isPrivate) {
                        return (
                            <Card.BookingEvent
                                key={booking._id}
                                start={formatSecondsToHHhmm(booking.from)}
                                prestations={booking._prestation}
                                client={booking._client}
                                address={booking.address}
                                isCurrent={dayjs().isAfter(startOfEvent) && dayjs().isBefore(endOfEvent)}
                                lastIndex={index === bookings.length - 1 && index !== 0}
                                deleteBooking={() => {
                                    handleBookingSelected('delete', booking)
                                }}
                                updateBooking={() => handleBookingSelected('update', booking)}
                                selectBooking={() => handleBookingSelected('general', booking)}
                                addNote={() => handleBookingSelected('note', booking)}
                            />
                        )
                    } else {
                        return (
                            <Card.BookingEvent
                                key={booking._id}
                                start={formatSecondsToHHhmm(booking.from)}
                                hoursBreakStart={formatSecondsToHHhmm(booking.from)}
                                prestations={booking._prestation}
                                client={booking._client}
                                address={booking.address}
                                isCurrent={dayjs().isAfter(startOfEvent) && dayjs().isBefore(endOfEvent)}
                                lastIndex={index === bookings.length - 1 && index !== 0}
                                deleteBooking={() => {
                                    handleBookingSelected('delete', booking)
                                }}
                                updateBooking={() => handleBookingSelected('update', booking)}
                                selectBooking={() => handleBookingSelected('general', booking)}
                                addNote={() => handleBookingSelected('note', booking)}
                                indisponible={true}
                                hoursBreakEnd={formatSecondsToHHhmm(booking.to)}
                            />
                        )
                    }
                }
            })}
        </div>
    )
}

const Bookings = () => {
    const [dataView, setDataView] = useState('list')

    const { data, date } = Hairdresser.useAgenda()
    const { handleBookingSelected } = Hairdresser.useBooking()

    return (
        <div className="grid gap-6">
            <Navigation activeView={dataView} setActiveView={(value) => setDataView(value)} />
            {!data.bookings ? (
                <Text.Paragraph.Normal className={'text-center'}>Aucun rendez-vous ce jour là</Text.Paragraph.Normal>
            ) : data.bookings.length > 0 ? (
                data.bookings?.filter((booking) => booking.state !== 'cancel' && !booking.isPrivate)?.length === 0 ? (
                    <Text.Paragraph.Normal className={'text-center'}>
                        Aucun rendez-vous ce jour là
                    </Text.Paragraph.Normal>
                ) : (
                    <></>
                )
            ) : (
                <Text.Paragraph.Normal className={'text-center'}>Aucun rendez-vous ce jour là</Text.Paragraph.Normal>
            )}
            {dataView === 'list' ? (
                <ListView
                    bookings={data.loadedDays[date]?.bookings
                        ?.filter((booking) => booking.state !== 'cancel')
                        .sort((a, b) => {
                            if (a.from > b.from) return 1
                            else return -1
                        })}
                    selectBooking={(value) => handleBookingSelected('general', value)}
                />
            ) : (
                <Map.Tour
                    bookings={data.loadedDays[date].bookings
                        ?.filter(
                            (booking) =>
                                (booking.state === 'booked' || booking.state === 'waiting') && !booking.isPrivate
                        )
                        .sort((a, b) => {
                            if (a.from > b.from) return 1
                            else return -1
                        })}
                    address={{ lon: 2.147899, lat: 43.9277552 }}
                    selectBooking={(value) => handleBookingSelected('general', value)}
                    dataView={dataView}
                />
            )}
        </div>
    )
}

export default Bookings
