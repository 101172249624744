import React, { useState } from 'react'

import { Auth, Client } from '../../../contexts'

import { Button, Input, Text, Icon } from '../../atoms'

const AuthForm = ({ form, setForm }) => {
    const { handleLoginClassic, handleSignup, getNewPassword } = Auth.useAuth()
    const { address } = Client.useCoiffeur()

    const [loading, setLoading] = useState(false)
    const [sexe, setSexe] = useState('')
    const [firstName, setfirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [passwordLost, setPasswordLost] = useState(false)

    return (
        <>
            {form === 'signup' && (
                <>
                    <div className="grid gap-4 text-center">
                        <Text.h1>Inscription</Text.h1>
                        <div>
                            <Text.Paragraph.Normal>Vous déjà un compte ?</Text.Paragraph.Normal>
                            <div onClick={() => setForm('login')}>
                                <Text.Link>Connexion</Text.Link>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-3">
                        <Text.Paragraph.Normal className="text-lg font-medium">Sexe</Text.Paragraph.Normal>
                        <div className="grid grid-cols-3">
                            <div className="flex space-x-3">
                                <Input.Radio
                                    initialValue={sexe === 'female'}
                                    controlled
                                    controlledValue={sexe === 'female'}
                                    setValue={(value) => {
                                        if (value) setSexe('female')
                                    }}
                                />
                                <Text.Paragraph.Normal>Femme</Text.Paragraph.Normal>
                            </div>
                            <div className="flex space-x-3">
                                <Input.Radio
                                    initialValue={sexe === 'male'}
                                    controlled
                                    controlledValue={sexe === 'male'}
                                    setValue={(value) => {
                                        if (value) setSexe('male')
                                    }}
                                />
                                <Text.Paragraph.Normal>Homme</Text.Paragraph.Normal>
                            </div>
                            <div className="flex space-x-3">
                                <Input.Radio
                                    initialValue={sexe === 'other'}
                                    controlled
                                    controlledValue={sexe === 'other'}
                                    setValue={(value) => {
                                        if (value) setSexe('other')
                                    }}
                                />
                                <Text.Paragraph.Normal>Autres</Text.Paragraph.Normal>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-5">
                        <Input.Text.Classic value={firstName} setValue={setfirstName} labelValue="Prénom" />
                        <Input.Text.Classic value={lastName} setValue={setLastName} labelValue="Nom" />
                        <Input.Text.Classic type="email" value={email} setValue={setEmail} labelValue="Email" />
                        <Input.Text.Classic
                            value={phoneNumber}
                            setValue={setPhoneNumber}
                            placeholder="06 08 06 08 06"
                            labelValue="Téléphone"
                        />
                        <Input.Text.Password
                            value={password}
                            setValue={(value) => setPassword(value)}
                            labelValue="Mot de passe"
                        />
                        <Input.Text.Password
                            value={confirmPassword}
                            setValue={(value) => setConfirmPassword(value)}
                            labelValue="Confirmation du mot de passe"
                        />
                    </div>
                    <div className="grid gap-5 text-center">
                        <div className="mx-auto">
                            <Button.Primary
                                onClick={async () => {
                                    if (!loading) {
                                        setLoading(true)
                                        await handleSignup(
                                            sexe,
                                            firstName,
                                            lastName,
                                            email,
                                            address,
                                            phoneNumber,
                                            password,
                                            confirmPassword,
                                            'client',
                                            null,
                                            true
                                        )
                                        setLoading(false)
                                    }
                                }}
                            >
                                Inscription
                            </Button.Primary>
                        </div>
                    </div>
                </>
            )}
            {form === 'login' && (
                <>
                    {!passwordLost ? (
                        <>
                            <div className="grid gap-4 text-center">
                                <Text.h2>Connexion</Text.h2>
                                <div>
                                    <Text.Paragraph.Normal>Vous n'avez pas encore de compte ?</Text.Paragraph.Normal>
                                    <div onClick={() => setForm('signup')}>
                                        <Text.Link>Inscription</Text.Link>
                                    </div>
                                </div>
                            </div>
                            <div className="grid gap-8">
                                <div className="grid gap-5">
                                    <Input.Text.Classic
                                        type="email"
                                        value={email}
                                        setValue={setEmail}
                                        labelValue="Email"
                                    />
                                    <Input.Text.Password
                                        value={password}
                                        setValue={setPassword}
                                        labelValue="Mot de passe"
                                    />
                                </div>
                                <div className="grid gap-5 text-center">
                                    <div className="mx-auto">
                                        <Button.Primary
                                            onClick={async () => {
                                                setLoading(true)
                                                await handleLoginClassic(email, password, true)
                                                setLoading(false)
                                            }}
                                        >
                                            Connexion
                                        </Button.Primary>
                                    </div>
                                    <div
                                        className="mx-auto underline"
                                        onClick={() => {
                                            setPasswordLost(true)
                                        }}
                                    >
                                        Mot de passe oublié ?
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="grid gap-8">
                            <Text.h2 className="text-center">Mot de passe oublié ?</Text.h2>

                            <Text.Paragraph.Normal>
                                Récupérer un nouveau mot de passe par mail pour pouvoir le changer.
                            </Text.Paragraph.Normal>
                            <Input.Text.Classic
                                value={email}
                                setValue={(value) => setEmail(value)}
                                labelValue="Email"
                            />
                            <Button.Primary
                                iconRight={<Icon.ArrowNext className="w-5" />}
                                onClick={async () => {
                                    if (email) {
                                        await getNewPassword(email)
                                        setPasswordLost(false)
                                    }
                                }}
                            >
                                Récupérer
                            </Button.Primary>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

const AddressValidation = ({ selected, setSelected }) => {
    const { address } = Client.useCoiffeur()
    const profil = Client.useProfil()

    return (
        <div className="grid gap-3">
            <div
                className={`border border-black rounded shadow-sm p-3 ${
                    selected === 'actual' ? 'bg-pink' : 'bg-white'
                }`}
                onClick={() => setSelected('actual')}
            >
                {address?.formatted}
            </div>
            <div
                className={`border border-black rounded shadow-sm p-3 ${
                    selected === 'profil' ? 'bg-pink' : 'bg-white'
                }`}
                onClick={() => setSelected('profil')}
            >
                {profil.address?.formatted}
            </div>
        </div>
    )
}

export { AuthForm, AddressValidation }
