import React from 'react'
import { useAuth } from '../../contexts/auth/context'
import { Modal } from '../../components/atoms'
export const CGU = ({type}) => {
    const { setIsOpenCguCgv, isOpenCguCgv } = useAuth()

    return (
        <Modal.default title={type==='coiffeur'?'CGU / CGV':'CGU'} isOpen={isOpenCguCgv === 'cgu'} setIsOpen={() => setIsOpenCguCgv('')}>
            <div className="grid gap-y-2 w-10/12 mx-auto text-[12px]">
                <div className="border-2 border-black mx-0 px-6 py-4 text-[15px]">
                    <h3 className="uppercase mb-2">condition générales de vente et d'utilisation (CGV/CGU)</h3>
                    <h4 className="mb-2">Application mobile « Iléa » et site internet https://ilea-coiffure.com</h4>
                    <h4>
                        Entre l’Application mobile « Iléa », le site internet https://ilea-coiffure.com, leurs
                        utilisateurs et les prestataires de services adhérents
                    </h4>
                </div>
                <p className="italic text-right my-2">En vigueur au 22 janvier 2024</p>
                <h5 className="font-bold text-[13px] my-2 underline">ARTICLE PREMIER - Champ d'application</h5>
                <p>
                    Les présentes Conditions Générales de Vente s'appliquent, sans restriction ni réserve à l'ensemble
                    des ventes/commandes/réservations conclues via l’Application mobile et le Site (« le Vendeur »)
                    auprès d'acheteurs non professionnels (« Les Clients ou le Client »), désirant réserver et acheter
                    en ligne des prestations de coiffure, de barbier et d’esthétique en général, référencés et proposés
                    à la vente par le Vendeur (« Les services») sur l’application mobile « Iléa » et le site Internet{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com.
                    </a>
                </p>
                <p>
                    Les présentes Conditions Générales de Vente s'appliquent également, sans restriction ni réserve à
                    l'ensemble des ventes conclues via l’Application mobile et le Site( « le Vendeur ») auprès de
                    prestataires professionnels (« Les Clients ou le Client »), désirant référencer et vendre leurs
                    prestations de coiffure, de barbier et d’esthétique en général, et acheter des produits
                    professionnels, mis en vente par le Vendeur sur l’application mobile « Iléa » et le site Internet{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com.
                    </a>
                </p>
                <p>
                    L’application mobile « Iléa » et le Site
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    sont dédiés à la mise à la relation, la recommandation, la réservation et la vente de produits et de
                    prestations de services liés à des prestations de coiffure, de barbier et d’esthétique en général.
                    Le vendeur se réserve le droit d’adjoindre toute autre activité complémentaire, sous réserve de
                    modifications éventuelles des présentes.
                </p>
                <p>
                    Les présentes CGU/CGV précisent notamment les conditions de réservation, de commande et de paiement
                    des Produits et Services commandés par les Clients.
                </p>
                <p>
                    Les présentes Conditions générales de vente et CGU s'appliquent à l'exclusion de toutes autres
                    conditions, et notamment celles applicables pour les ventes en magasin ou au moyen d'autres circuits
                    de distribution et de commercialisation. Seules les conditions générales de vente des prestataires
                    partenaires viennent compléter les présentes, en ce qu’elles seraient dérogatoires ou spécifiques à
                    un produit ou à un service.
                </p>
                <p>
                    Ces Conditions Générales de Vente et d’utilisation sont accessibles à tout moment sur l’application
                    mobile « Iléa » le site Internet{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.
                </p>
                <p>
                    Ces Conditions Générales de Vente et d’utilisation pouvant faire l'objet de modifications
                    ultérieures, la version applicable à l'achat du Client est celle en vigueur sur le site internet à
                    la date de passation de la commande.
                </p>
                <p>
                    Le Client déclare avoir pris connaissance des présentes Conditions Générales de Ventes et
                    d’utilisation des Produits et Services et les avoir acceptées en cochant la case prévue à cet effet
                    avant la mise en œuvre de la procédure de commande en ligne ainsi que des conditions générales
                    d'utilisation de l’application « Iléa » et du site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                </p>
                <h5 className="font-bold text-[13px] my-2 underline">ARTICLE 2 - DÉFINITIONS</h5>
                <p>
                    <span className="font-bold">La Société, L’application et Le « Site »</span> désignent l’application
                    mobile « Iléa » et toute extension de ladite application, ainsi que le(s) site(s) internet
                    accessible(s) à partir du lien URL{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    et leurs éventuels sous-sites permettant d’accéder à un espace de consultation de contenus, à un
                    espace réservé aux clients, etc. qui sont fournis par la Société.
                </p>
                <p>
                    <span className="font-bold text-[13px]">L’« Utilisateur »</span>désigne toute personne naviguant sur
                    le Site.
                </p>
                <p>
                    <span className="font-bold">La « Commande »</span> désigne toute souscription d’un Service ou achat
                    d’un produit par le Client auprès de la Société via le Site.{' '}
                </p>
                <p>
                    <span className="font-bold">Le « Client »</span> désigne la personne physique ou morale,
                    professionnelle ou non, souscrivant un Service auprès de la Société.{' '}
                </p>
                <p>
                    <span className="font-bold">
                        Les « Conditions Générales de Vente et d’utilisation » ou « CGV-CGU »
                    </span>{' '}
                    désignent les présentes conditions générales de vente et d’utilisation, applicables dans le cadre de
                    la relation contractuelle entre la Société et ses Clients, qui comprennent la politique de
                    confidentialité et les mentions légales disponibles sur l’application et le Site et tout élément
                    auxquels elles renvoient expressément.
                </p>
                <p>
                    <span className="font-bold">Les « Contenus »</span> désigne les données et contenus numériques
                    (notamment : vidéos, enregistrements audio, tests, documentation pédagogique...) fournis aux
                    Utilisateurs ou aux Clients par la Société à titre gratuit ou payant dans le cadre de l'accès au
                    Site ou au Service.
                </p>
                <p>
                    <span className="font-bold">L’« Espace Personnel »</span> désigne tout espace en ligne mis à
                    disposition du Client par la Société dans le Cadre du Service, accessible avec ses Identifiants.
                </p>
                <p>
                    <span className="font-bold">Les « Identifiants »</span> désigne le nom d’utilisateur et mot de passe
                    fournis par la Société au Client afin qu’il puisse accéder, via le Site ou tout autre moyen de
                    connexion, au Service souscrit.{' '}
                </p>
                <p>
                    <span className="font-bold">Le « Partenaire »</span> désigne tout partenaire professionnel avec qui
                    la Société entretient une relation d’affaires dans le cadre de la fourniture du Service et Produits
                    et vers qui le Client pourrait être renvoyé dans le cadre de la fourniture du Service et Produits
                </p>
                <p>
                    <span className="font-bold">Le « Produit »</span> désigne tout type de produit matériel ainsi que
                    toutes prestations de services vendues en ligne vendu en ligne via l’application et le Site
                </p>
                <p>
                    <span className="font-bold">Le « Service »</span> désigne toutes les prestations de vente et
                    services disponibles à la Commande sur le Site.
                </p>
                <p>
                    Ces services peuvent évoluer et être complétés par toute autre prestation en lien directe ou
                    indirect avec ces derniers, leur présentation étant directement publiée et accessible sur les Sites
                    concernés.
                </p>
                <p>
                    <span className="font-bold">Le « Vendeur »</span> est l’application mobile, le Site ou le
                    prestataire qui proposent ses Produits et Services sur le Site.
                </p>
                <h5 className="font-bold text-[13px] my-2 underline">ARTICLE 3 - Produits et Services proposés</h5>
                <p>
                    Tous Produits susceptibles d’être proposés à la vente, liée directement ou indirectement à l’objet
                    social du vendeur, commandés ou réservés via l’application et le site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                </p>
                <p>
                    Les Produits et services proposés à la vente et à la réservation via l’application et sur le site
                    Internet{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    sont ceux publiés sur l’application et le site https://ilea-coiffure.com et sur tout support
                    publicitaire, promotionnel et commercial lié audit site.
                </p>
                <p>
                    <span className="font-bold">
                        3.1 Les Produits physiques sont ceux présentés via l’application et sur le site et
                    </span>{' '}
                    qui font l’objet des Conditions Générales de vente consultables sur lesdits supports numériques.
                </p>
                <p>
                    Il s’agit essentiellement de produits directement ou indirectement liés aux activités de coiffure,
                    barbier et esthétique en général.
                </p>
                <p>
                    Ces produits et ces ventes sont réservés aux clients professionnels, prestataires, membres adhérents
                    de l’application ou du Site.
                </p>
                <p>
                    <span className="font-bold">3.2 Les Services</span> proposés via l’application « Iléa » et par le
                    Site
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    sont :
                </p>
                <ul className={`ml-4 list-["▶"]`}>
                    <li className="pl-4 mb-2">
                        Mise en ligne d’un planning de réservation de rendez-vous par les prestataires adhérents de
                        leurs services de coiffure à domicile, barbiers et esthétique en général avec accès réservé aux
                        adhérents de l’application et du Site
                    </li>
                    <li className="pl-4 mb-2">
                        Réservation pour les Utilisateurs membres via un planning de rendez-vous auprès de coiffeurs à
                        domicile, barbiers et prestataires en esthétique en général, et de toutes prestations liées
                        directement ou indirectement à ces activités
                    </li>
                    <li className="pl-4 mb-2">
                        Service de paiement en ligne, via l’application et le Site, dédiés à ces activités, selon les
                        conditions décrites aux présentes CGV/CGU
                    </li>
                </ul>
                <p>
                    Les différents Produits et Services proposés à la vente sont ceux présentés sur l’application et le
                    Site et peuvent faire l’objet d’une modification de dénomination ou de complément. Le client doit se
                    reporter impérativement aux mentions et informations du site afin de compléter sa commande.
                </p>
                <p>
                    Le contenu des différents Produits et Services est détaillé sur l’application et le site{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com.
                    </a>
                </p>
                <h5 className="font-bold text-[13px] my-2 underline">
                    ARTICLE 4 - Durée de validité de l'offre de Services
                </h5>
                <p>
                    Les offres de Services s'entendent de ceux énoncés ci-dessus, sous la responsabilité des
                    prestataires de services et tels que précisés lors de la passation de la commande/la réservation.
                </p>
                <ol className="ml-4 list-decimal">
                    <li className="mb-2 pl-4">
                        <p className="mb-2">
                            Le présent prend effet à la date où l’Application ou Le Site accepte le Prestataire de
                            services et sera valide pendant une durée{' '}
                            <span className="font-bold">d’un an renouvelable par tacite reconduction.</span>
                        </p>
                        <p className="mb-2">
                            Des durées mensuelles sont également proposées aux Prestataires professionnels, renouvelable
                            par tacite reconduction, dans les mêmes conditions, ainsi que des durées dites
                            promotionnelles.
                        </p>
                        <p className="mb-2">
                            Concernant les abonnements annuels, les Clients NON PROFESIONNELS sont informés de la date
                            d'échéance de leur contrat dans un délai de 3 à 1 mois avant la date de renouvellement. Il
                            pourra par conséquent résilier son abonnement à date anniversaire. A défaut d’information,
                            le contrat d’abonnement peut être résilié à tout moment, sous réserve des dispositions du
                            point 2 suivant.
                        </p>
                        <p className="mb-2">
                            La résiliation, quel que soit la période au cours de laquelle elle intervient, doit être
                            adressée par tous moyens, emportant preuve irréfutable de la volonté de résilier. Sa prise
                            d’effet, pour les résiliation à date anniversaire sera la date anniversaire de ce dernier ;
                            pour les résiliations en dehors de la date anniversaire, cette dernière prendra effet le
                            mois suivant la résiliation, les sommes dues restant exigibles dans les conditions des
                            présentes.
                        </p>
                    </li>
                    <li className="mb-2 pl-4">
                        <p className="mb-2">
                            Chaque Partie peut résilier ou suspendre le présent avec l'autre Partie, avec effet
                            immédiat, en cas de :
                        </p>
                    </li>
                    <ol className="ml-4 mb-2">
                        <li className="mb-2 pl-4">
                            <p className="mb-2">
                                (a) violation matérielle de tout terme du présent Accord ou de tout Accord conclu entre
                                le Prestataire de services et le Vendeur, ou
                            </p>
                        </li>
                        <li className="mb-2 pl-4">
                            <p className="mb-2">
                                (b) (déclaration de) faillite ou suspension de paiement (ou toute autre action
                                similaire) vis-à-vis de l'autre Partie.
                            </p>
                        </li>
                    </ol>
                </ol>
                <p className="mb-2">
                    En cas de résiliation ou d'expiration du présent Accord, les paiements intervenus sont non
                    remboursables.
                </p>
                <h5 className="font-bold text-\[13px\] underline my-2">
                    ARTICLE 5 - Coordonnées de l’Application mobile et du Site
                </h5>
                <span className="font-bold">La SAS ILEA CONNECT</span>
                <address>
                    Société par actions simplifiée
                    <br />
                    Siège social : 8 avenue Pierre-Gilles de Gennes 81000 ALBI
                    <br />
                    RCS d’Albi n°981 195 027
                    <br />
                    Tel : 06.06.47.92.85 (Vicent Alexandre)
                    <br />
                    Mail : ilea.app@gmail.com
                    <br />
                    Représentant légal : Madame Laura VICENT et Monsieur Alexandre VICENT
                    <br />
                </address>
                <p>
                    Conformément à la loi Informatique et Libertés du 6 janvier 1978, renforcée et complétée par le RGPD
                    (règlement général sur la protection des données) entré en vigueur le 25 mai 2018, le Client
                    dispose, à tout moment, d'un droit d'accès, de rectification, d'opposition, d'effacement et de
                    portabilité de l'ensemble de ses données personnelles en écrivant, par courrier et en justifiant de
                    son identité, à l'adresse du Vendeur, mentionnée ci-dessus.
                </p>
                <p>
                    <span className="font-bold">
                        Le VENDEUR est le prestataire de service qui propose et vend ses Produits et Services via
                        l’application et le Site, ou l’application et le Site concernant les Services et Produits
                        destinés aux Clients Professionnels.
                    </span>
                </p>
                <p>
                    La validation de la commande par le Client vaut acceptation sans restriction ni réserve des
                    présentes Conditions Générales de Vente.
                </p>
                <p>
                    Le Client reconnaît avoir la capacité requise pour contracter et acquérir les Produits proposés sur
                    l’application et sur le site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                </p>
                <h5 className="font-bold text-[13px] underline my-2">ARTICLE 6 : LES PRESTATAIRES DE SERVICES</h5>
                <p>
                    Les Prestataires de service proposés sur l’Application et sur le Site ont fait l’objet d’une
                    sélection préalable et répondent à un cahier des charges notamment en termes de conformité juridique
                    de leurs structures. Cependant l’Application et le Site ne sont pas responsables de fausses
                    déclarations ou de toutes mentions inexactes fournies par les Prestataires.
                </p>
                <p>
                    Les Partenaires, en publiant sur l’Application et sur le Site, acceptent le cahier des charges via
                    le formulaire d’inscription, préalablement mis à leur disposition par le Site.
                </p>
                <p>Afin de figurer sur le Site, les partenaires doivent :</p>
                <ul className='list-["-"] ml-4'>
                    <li className="pl-4 mb-2">
                        Contacter le site à l’aide du formulaire de contact dédié, ou de l’adresse mail indiquée
                    </li>
                    <li className="pl-4 mb-2">
                        Après acceptation de leur demande d’enregistrement sur l’Application et sur le Site et
                        acceptation des présentes CGV/CGU dont ils sont prévenus par tous moyens numériques : créer leur
                        espace professionnel privé,
                    </li>
                    <li className="pl-4 mb-2">
                        Un identifiant de connexion sera adressé par l’application et/ou le Site aux différents
                        partenaires
                    </li>
                    <li className="pl-4 mb-2">
                        Les prestataires sont alors libres de proposer leurs prestations sur le site dans le respect de
                        la charte et du cahier des charges acceptés par eux.
                    </li>
                </ul>
                <p>
                    Les partenaires sont responsables des contenus publiés par leurs soins, de leurs agendas de
                    réservations, de la mise à jour de leurs informations et toute publication en général liée à la
                    commercialisation de leurs produits et services.
                </p>
                <p>Le Site ne peut être tenu responsable d’informations erronées publiées par les partenaires.</p>
                <h5 className="font-bold text-[13px] underline my-2">ARTICLE 7 – COMMANDES/RESERVATIONS</h5>
                <p>Les réservations et les commandes provenant des pays suivants sont acceptées :</p>
                <ul className={`ml-4 list-["▶"]`}>
                    <li className="pl-4 mb-2">en France Métropolitaine, Corse, Monaco</li>
                    <li className="pl-4 mb-2">
                        Pays membres de l’Union Européenne : Autriche, Belgique, Bulgarie, Croatie, République de
                        Chypre, République tchèque, Danemark, Espagne, Estonie, Finlande, Allemagne, Grèce, Hongrie,
                        Irlande, Italie, Lettonie, Lituanie, Luxembourg, Malte, Pays-Bas, Norvège, Pologne, Portugal,
                        Roumanie, Slovaquie, Suède, Royaume-Uni.
                    </li>
                    <li className="pl-4 mb-2">
                        Autres pays du Monde, sous réserve des conjonctures politiques, légales, règlementaires et
                        commerciales en vigueur.
                    </li>
                </ul>
                <p>
                    Chaque prestataire est libre de renseigner le territoire géographique sur lequel il propose ses
                    prestations ainsi que les frais éventuellement portés à la charge du client pour ses déplacements.
                </p>
                <p>
                    <span className="font-bold">7.1 – Passation de commandes/réservations</span>
                </p>
                <p>
                    Il appartient au Client non professionnel de sélectionner sur l’Application et/ou le site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    les Services définis à l’article 3 des présentes qu'il désire commander, selon les modalités
                    suivantes :{' '}
                </p>
                <p>
                    <span className="font-bold">Pour les Clients non professionnels et non professionnels :</span>
                </p>
                <ul className={`ml-6 list-disc`}>
                    <li className="pl-2 mb-2">création d’un compte avec identifiants</li>
                    <li className="pl-2 mb-2">ajout au panier</li>
                    <li className="pl-2 mb-2">récapitulatif commande /réservation</li>
                    <li className="pl-2 mb-2">acceptation des conditions générales de vente</li>
                    <li className="pl-2 mb-2">validation définitive de la commande/réservation</li>
                    <li className="pl-2 mb-2">paiement en ligne des services réservés</li>
                </ul>
                <p>
                    Le Client a la possibilité de vérifier le détail de sa commande/réservation, son prix total et de
                    corriger d'éventuelles erreurs avant de confirmer son acceptation. Il lui incombe de vérifier
                    l'exactitude de la commande et de signaler ou rectifier immédiatement toute erreur.
                </p>
                <p>
                    L'enregistrement d'une commande/réservation via l’Application et/ou sur le site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    est réalisé lorsque le Client accepte les présentes Conditions Générales de Vente en cochant la case
                    prévue à cet effet et valide sa commande. Cette validation implique l'acceptation de l'intégralité
                    des présentes Conditions Générales de Vente ainsi que des conditions générales d'utilisation de
                    l’Application et du site internet .
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>
                </p>
                <p>
                    La vente n'est définitive qu'après l'envoi au Client de la confirmation de l'acceptation de la
                    commande/réservation par l’application ou le site par courrier électronique sous la forme d’un
                    message automatique ou personnalisé de ladite commande/réservation.
                </p>
                <p>
                    Toute commande/réservation passée, validée par le Client et confirmée par le Vendeur, dans les
                    conditions et selon les modalités ci-dessus décrites, sur l’application et le site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    constitue la formation d'un contrat conclu à distance entre le Client et le Vendeur.
                </p>
                <p>
                    Sauf preuve contraire, les données enregistrées dans le système informatique du Vendeur constituent
                    la preuve de l'ensemble des transactions conclues avec le Client.
                </p>
                <p>
                    Le Vendeur se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il
                    existerait un litige relatif au paiement d'une commande antérieure.
                </p>
                <p>
                    <span className="font-bold">
                        7.2 – Modification de la commande/réservation par le Client non-professionnel
                    </span>
                </p>
                <p>
                    Les éventuelles modifications de la commande/réservation par le Client non professionnel ne pourront
                    être prises en compte par le prestataire que dans la limite de ses possibilités et à condition
                    d'être notifiées :
                </p>
                <ul className='list-["-"] ml-4'>
                    <li className="pl-4 mb-2">
                        via l’application ou le site : 48 heures avant la date de réservation,
                    </li>
                    <li className="pl-4 mb-2">
                        passé ce délai de 48 heures avant la date de réservation : par téléphone directement auprès du
                        prestataire concerné
                    </li>
                    <li className="pl-4 mb-2">le prestataire se chargera de modifier son planning en conséquence.</li>
                </ul>
                <p>
                    Les prestations payées via l’Application ou le Site, qui font l’objet d’une modification ne donnent
                    pas lieu au paiement d’une indemnité compensatrice par le Client (report de rendez-vous).
                </p>
                <p>
                    <span className="font-bold">7.3 – Annulation de la réservation et/ou de la commande</span>{' '}
                </p>
                <p>Sous réserves des modalités d’application de la clause rappelées à l’article 17 des présentes :</p>
                <ul className={`ml-4 list-["▶"]`}>
                    <li className="pl-4 mb-2">
                        <p>
                            <span className="font-bold">
                                7.3.1 Annulation de la réservation par le Client non-professionnel :
                            </span>
                        </p>
                    </li>
                    <ul className='list-["-"] ml-4'>
                        <li className="pl-4 mb-2">
                            via l’application ou le site : 48 heures avant la date de réservation, sans frais
                        </li>
                        <li className="pl-4 mb-2">
                            passé ce délai de 48 heures avant la date de réservation : par téléphone directement auprès
                            du prestataire concerné En cas de paiement des prestations via l’application ou le site, des
                            frais d’annulation tardive à hauteur de 20% du montant de la commande TTC seront retenus sur
                            le montant remboursé par l’application ou le Site.
                        </li>
                        <li className="pl-4 mb-2">
                            le prestataire se chargera de modifier son planning en conséquence et est libre d’appliquer,
                            en cas de paiement direct de ses services, des frais d’annulation. Le site et/ou
                            l’application ne sont pas responsables des tarifs et frais pratiqués par le prestataire.
                        </li>
                    </ul>
                    Le Site n’est pas responsable des annulations de réservations envers les Prestataires. Le Site
                    s’engage néanmoins à mettre tout en œuvre pour faciliter les échanges entre les clients et les
                    Partenaires
                    <li className="pl-4 mb-2">
                        <p className="mb-2">
                            <span className="font-bold">
                                7.3.2 Annulation de la commande par le Client professionnel :
                            </span>
                        </p>
                        <p className="mb-2">
                            <span className="font-bold">7.3.2.1 L’annulation de la commande de Services</span> par le
                            Client professionnel est possible à tout moment tant pour les abonnements annuels que
                            mensuels.
                        </p>
                        <p className="mb-2">
                            L’annulation de l’abonnement du Client professionnel aux services souscrits via
                            l’application et le Site s’effectue par le bouton « annuler l’abonnement » présent sur les
                            supports numériques. Cette action entraîne la résiliation immédiate des abonnements
                            souscrits dans les conditions suivantes : l’abonnement en cours (annuel ou mensuel) reste
                            valable jusqu’à la fin du mois au cours duquel l’annulation intervient
                        </p>
                        <p className="mb-2">
                            Aucun remboursement n’interviendra lors de l’annulation de la commande, sauf cas énoncés
                            ci-après entraînant la faute ou la responsabilité de l’Application et du Site, et quelle que
                            soit la durée restant à courir sur ledit abonnement.
                        </p>

                        <p className="mb-2">
                            <span className="font-bold">7.3.2.2 L’annulation de la commande de produits</span> par le
                            Client professionnel n’est possible qu’aux conditions Générales de Vente, dans les délais et
                            tarifs prescrits par ces dernières, savoir :
                        </p>
                    </li>
                    <ul className='list-["-"] ml-4'>
                        <li className="pl-4 mb-2">
                            via l’application ou le site : 12 heures après la commande, sans frais
                        </li>
                        <li className="pl-4 mb-2">
                            passé ce délai, et sous réserve de l’application des clauses relatives à la force majeure
                            notamment, l’annulation est possible, aucun remboursement de la commande ne pourra
                            intervenir.
                        </li>
                    </ul>
                </ul>
                <h5 className="font-bold text-[13px] my-2">ARTICLE 8 – Tarifs et responsabilité</h5>
                <p>
                    Les Produits et services sont fournis aux tarifs en vigueur figurant sur l’application et sur le
                    site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    lors de l'enregistrement de la commande/réservation par le Vendeur. Les prix sont exprimés en Euros,
                    TTC.{' '}
                </p>
                <p>
                    Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Vendeur sur
                    l’application et le site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com.
                    </a>{' '}
                </p>
                <p>
                    <span className="font-bold">Ces tarifs sont fermes et non révisables</span> pendant leur période de
                    validité, telle qu'indiquée sur les supports numériques concernés le Vendeur se réservant le droit,
                    hors cette période de validité, de modifier les prix à tout moment.
                </p>
                <p>
                    Ils ne comprennent pas d’éventuels frais de traitement qui sont facturés en supplément, dans les
                    conditions fixées par le Vendeur, calculés préalablement à la passation de la commande/réservation
                    et acceptés par le Client.
                </p>
                <p>Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais.</p>
                <p>
                    Une facture est établie par le Vendeur et remise au Client lors de la commande des Produits et
                    Services.{' '}
                </p>
                <ol className="list-decimal ml-4">
                    <li className="pl-4 mb-2">
                        <p className="mb-2">
                            L’application et le Site s'occupent (via un processeur de paiement tiers) du paiement du
                            produit ou du service concerné pour et au nom des Clients non professionnels aux
                            prestataires concernés qui proposent leurs services sur l’Application et le Site.
                        </p>
                        <p className="mb-2">
                            Le paiement est versé au prestataire par l'intermédiaire d'un processeur de paiement tiers.
                            Tout paiement que nous gérons pour vous et transférons au prestataire constitue dans chaque
                            cas le règlement définitif du tarif dû de la réservation (ou d'une partie de celui-ci) en
                            votre nom pour le service correspondant.
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            L’application et le Site ne sont ni responsables ni redevables des tarifs affichés par les
                            Prestataires pour les services concernés (y compris les taxes, frais, suppléments ou
                            prélèvements), ou de toute erreur ou omission à cet égard. Le Prestataire de services ne
                            doit pas tenir l’application et le Site responsables des erreurs ou fautes évidentes (y
                            compris les erreurs typographiques) qui ne sont pas exécutoires. Toutes les offres spéciales
                            ou promotions doivent être indiquées comme telles.
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            L’application et le Site ne sont pas responsables (et déclinent toute responsabilité à cet
                            égard) de l'utilisation, la validité, la qualité, l'adéquation, la pertinence et la
                            divulgation des produits ou services (proposés ou rendus disponibles sur ces supports
                            numériques), et n'assument aucune représentation, garantie ou condition à cet égard,
                            qu'elles soient tacites, légales ou autres, y compris toute garantie implicite de qualité
                            marchande, de titre, de non-contrefaçon ou de pertinence dans un but précis.
                        </p>
                    </li>
                </ol>
                <p>
                    Le Prestataire de services reconnaît et accepte son entière responsabilité dans le cadre du
                    produit/service (y compris pour toute garantie et représentation assumée par le
                    fournisseur/prestataire).
                </p>
                <p>
                    L’application et le Site n'agissent pas en tant que (re)vendeur, offrant ou fournisseur d'un
                    produit/service rendu disponible sur le Site.{' '}
                </p>
                <p>
                    Toute plainte ou réclamation concernant le produit/service (y compris relative au tarif
                    (spécial/promotionnel) proposé, aux conditions ou aux demandes spéciales effectuées par le
                    Prestataire de services) sera traitée par le prestataire. L’application et le Site ne sont pas
                    responsables de ces plaintes et réclamations, ni de toute carence (du produit), et décline toute
                    responsabilité à cet égard.
                </p>
                <h5 className="font-bold text-[13px] my-2">ARTICLE 9 - Conditions de paiement</h5>
                <p>
                    Pour toutes les commandes via l’application et le Site, le client non professionnel et professionnel
                    effectuera son paiement par carte bancaire (Carte bleue/Visa ou Eurocard/Mastercard), en dehors des
                    cas de paiement direct du Client au Prestataire qui seul choisit ses conditions de paiement.
                </p>
                <p>
                    L’application et le Site utilisent les systèmes de paiement sécurisés les plus fiables : protocoles
                    SSL, 3D Secure.{' '}
                </p>
                <p>
                    Lors de la transaction de règlement, le client est automatiquement connecté au serveur du centre de
                    traitement des règlements. Les données bancaires sont transmises de façon cryptée (norme de sécurité
                    SSL). Le système de paiement par authentification (3D Secure) permet de renforcer la sécurité lors
                    des achats en ligne. A l’étape paiement, la banque du Client vérifie l’identité de l’utilisateur de
                    la carte avant de valider la transaction.
                </p>
                <p>
                    Aucun frais supplémentaire, supérieur aux coûts supportés par le Vendeur pour l'utilisation d'un
                    moyen de paiement ne pourra être facturé au Client.
                </p>
                <p>
                    Aucune commission n’est due par le Client professionnel abonné à l’application et au site, à chaque
                    réservation du Client final, seul l’abonnement est facturé dans les conditions ici décrites.
                </p>
                <h5 className="font-bold text-[13px] my-2">ARTICLE 10 - Droit de rétractation</h5>
                <ul className="ml-4">
                    <li className="mb-2">
                        <p>
                            <span className="font-bold">10.1 Pour les Clients non professionnels :</span>
                        </p>
                    </li>
                    <ul className="pl-4">
                        <li className="mb-2">
                            <p>
                                <span className="font-bold">10.1.1 Délai.</span>
                            </p>
                            <p>
                                Le Client peut se rétracter de sa commande sans donner de motif dans un délai de
                                quatorze jours calendaires commençant le jour suivant la Commande (le lendemain du jour
                                de la souscription du contrat). Si le délai expire un samedi, un dimanche ou un jour
                                férié, il est prorogé jusqu'à l’expiration de la dernière heure du premier jour ouvrable
                                suivant.
                            </p>
                        </li>
                        <li className="mb-2">
                            <p className="mb-2">
                                <span className="font-bold">10.1.2 Exercice.</span>
                            </p>
                            <p className="mb-2">Pour exercer le droit de rétractation, le Client notifie à :</p>
                            <p>
                                <span className="font-bold">La SAS ILEA CONNECT</span>
                            </p>
                            <p>
                                Siège social : 8 avenue Pierre-Gilles de Gennes 81000 ALBI Tel : 06.06.47.92.85 (Vicent
                                Alexandre) Mail : ilea.app@gmail.com
                            </p>
                        </li>
                    </ul>

                    <p className="mb-2">
                        sa décision de rétractation du présent contrat au moyen d'une déclaration dénuée d'ambiguïté.
                    </p>
                    <p className="mb-2">
                        Le Client peut utiliser le modèle de{' '}
                        <span className="font-bold">formulaire de rétractation</span> suivant.
                    </p>

                    <p className="mb-2">
                        En cas de rétractation, la Société rembourse le prix réglé au plus tard quatorze jours à compter
                        du jour après réception de la décision de rétractation, par le même moyen de paiement que celui
                        utilisé pour la transaction initiale, à moins que le Client n’accepte un moyen différent.
                    </p>

                    <div className="grid gap-y-2 border-2 border-black p-1">
                        <h6 className="text-center font-bold">MODÈLE DE FORMULAIRE DE RÉTRACTATION</h6>
                        <p>
                            Veuillez compléter et renvoyer le présent formulaire uniquement si vous souhaitez vous
                            rétracter du contrat.
                        </p>
                        <p>
                            A l'attention de <span className="font-bold">SAS ILEA CONNECT</span>
                        </p>
                        <p>Mail : ilea.app@gmail.com</p>
                        <p>
                            Je/nous (*) vous notifie/notifions (*) par la présente ma/notre (*) rétractation du contrat
                            portant pour la prestation de services (*) ci-dessous :
                        </p>
                        <p>Commandé le (*) :</p>
                        <p>Nom du (des) consommateur(s) :</p>
                        <p>Adresse du (des) consommateur(s) :</p>
                        <p>
                            Signature du (des) consommateur(s) (uniquement en cas de notification du présent formulaire
                            sur papier) :
                        </p>
                        <p className="flex justify-between">
                            Date : <span className="italic text-2xs text-right">(*) Rayez la mention inutile.</span>
                        </p>
                    </div>

                    <p className="mb-2">
                        <span className="font-bold">
                            Ce droit de rétractation ne fait pas obstacle à l’application des dispositions de l’article
                            7.3 et suivants des présentes conditions générales de vente et d’utilisation.
                        </span>
                    </p>
                    <ul className="pl-4 mb-2">
                        <li>
                            <p className="mb-2">
                                <span className="font-bold">
                                    10.1.3 ATTENTION, CE DROIT DE RÉTRACTATION{' '}
                                    <span className="underline">NE POURRA PAS</span> ÊTRE EXERCÉ SOUS CERTAINES
                                    CONDITIONS EN CAS DE :
                                </span>
                            </p>
                            <p className="mb-2">
                                Conformément aux dispositions légales en vigueur, et l’article L.212-28 du Code de la
                                Consommation le droit de rétractation ne peut être exercé pour les contrats :
                            </p>
                            <ul className="pl-4 mb-2">
                                <li>
                                    1° De fourniture de services pleinement exécutés avant la fin du délai de
                                    rétractation et, si le contrat soumet le consommateur à une obligation de payer,
                                    dont l'exécution a commencé avec son accord préalable et exprès et avec la
                                    reconnaissance par lui de la perte de son droit de rétractation, lorsque la
                                    prestation aura été pleinement exécutée par le professionnel ;
                                </li>
                            </ul>
                            <p className="mb-2">
                                <span className="font-bold">
                                    Par conséquent, le Client non professionnel ayant réservé et payé une prestation,
                                    objet des présentes, via l’application et/ou le Site, et qui a bénéficié de cette
                                    prestation tout en ayant renoncé expressément au moment du paiement, à son droit de
                                    rétraction, ne peut pas en bénéficier par la suite.
                                </span>
                            </p>
                            <p className="mb-2">
                                Ce droit de rétractation ne fait pas obstacle à l’application des dispositions de
                                l’article 7.3 et suivants des présentes conditions générales de vente et d’utilisation.
                            </p>
                        </li>
                        <li>
                            <p className="mb-2">
                                <span className="font-bold">10.1.4 Cessation du Service en cas d’exercice.</span>
                            </p>
                            <p className="mb-2">
                                L'exercice du droit de rétractation met fin à l'obligation des parties d'exécuter le
                                contrat. L'exercice du droit de rétractation d'un contrat principal met automatiquement
                                fin à tout contrat accessoire, sans frais pour le consommateur autres que ceux prévus
                                par la loi. Le Client est donc informé que l’application et le site cesseront
                                immédiatement le Service en cas d’exercice du droit de rétractation.
                            </p>
                        </li>
                    </ul>

                    <li>
                        <p className="mb-2">
                            <span className="font-bold underline">10.2 Pour les Clients professionnels :</span>
                        </p>
                        <p className="mb-2">
                            Conformément à la règlementation en vigueur, et les dispositions du Code de commerce, aucun
                            droit de rétractation ne peut être exercé par les Clients professionnels.
                        </p>
                        <p className="mb-2">
                            Toute commande est ferme et définitive et ne peut faire l’objet d’une annulation que dans
                            les conditions ci-dessus indiquées, aux dispositions de l’article 7.3.2 des présentes.
                        </p>
                    </li>
                </ul>
                <h5 className="font-bold text-[13px] underline my-2">ARTICLE 11- Garanties</h5>
                <ul className="ml-4 mb-2">
                    <li className="mb-2">
                        <p>
                            <span className="font-bold">11.1</span> Chaque Partie atteste et garantit à l'autre Partie
                            que, pour la durée de validité du présent document :
                        </p>
                    </li>
                    <ul className="ml-4 mb-2">
                        <li className="mb2">
                            <p>
                                <span className="font-bold">a-</span> elle détient tous les pouvoirs et autorisations
                                nécessaires pour signer le présent et répondre aux obligations qui en découlent ;
                            </p>
                        </li>
                        <li className="mb2">
                            <p>
                                <span className="font-bold">b-</span> elle a entrepris toutes les actions requises pour
                                autoriser la mise en place et l'exécution du présent document ;
                            </p>
                        </li>
                        <li className="mb2">
                            <p>
                                <span className="font-bold">c-</span> le présent document constitue des obligations
                                légales valides et contractuelles pour ladite Partie, conformément à ses conditions ; et
                            </p>
                        </li>
                        <li className="mb2">
                            <p>
                                <span className="font-bold">d-</span> elle doit respecter les lois de la juridiction à
                                laquelle la Partie concernée est soumise au regard des produits proposés et/ou des
                                services rendus par ladite Partie.
                            </p>
                        </li>
                    </ul>
                    <li className="mb-2">
                        <p>
                            <span className="font-bold">11.2</span> Le Prestataire de services atteste et garantit
                            l’Application et le Site que, pour la durée de validité du présent Accord :
                        </p>
                    </li>
                    <ul className="ml-4 mb-2">
                        <li className="mb-2">
                            <p>
                                <span className="font-bold">a-</span> le Prestataire de services possède tous les
                                droits, pouvoirs et autorités nécessaires pour utiliser, gérer, détenir (le cas
                                échéant), et permettre à l’Application et au Site de rendre disponible, sur ses supports
                                numériques
                            </p>
                        </li>
                        <ul className="ml-4 mb-2 list-['-']">
                            <li className="mb-2 pl-4">
                                <p>le Prestataire de services et</p>
                            </li>
                            <li className="mb-2 pl-4">
                                <p>
                                    les droits de Propriété Intellectuelle qui sont établis, évoqués ou qui concernent
                                    les informations du Prestataire (qu'ils soient utilisés pour ou au bénéfice des
                                    Services ou disponibles sur l’Application et le Site) ;
                                </p>
                            </li>
                        </ul>
                        <li className="mb-2">
                            <p>
                                <span className="font-bold">b-</span> l’exécution et la mise en pratique du présent
                                document et des obligations par le Prestataire de services envers le Site
                            </p>
                        </li>
                        <ul className="ml-4 mb-2 list-['-']">
                            <li className="mb-2 pl-4">
                                <p>ne représentent aucune violation d'accord avec un tiers et</p>
                            </li>
                            <li className="mb-2 pl-4">
                                <p>
                                    ne violent aucun droit de propriété intellectuelle de tiers (y compris les droits
                                    d'auteur, marques déposées, brevets ou protection des bases de données)
                                </p>
                            </li>
                        </ul>
                        <li className="mb-2">
                            <p>
                                <span className="font-bold">c-</span> le Prestataire de services ainsi que ses
                                responsables et propriétaires ((bénéficiaires) directs, indirects et principaux) ne sont
                                en aucun cas liés, membres, impliqués, en relation ou sous le contrôle, la gestion ou la
                                propriété de :
                            </p>
                        </li>
                        <ul className="ml-4 mb-2 list-['-']">
                            <li className="mb-2 pl-4">
                                <p>terroristes ou organisations terroristes</p>
                            </li>
                            <li className="mb-2 pl-4">
                                <p>
                                    personnes ou tiers considérés comme citoyens/entités (spéciaux) désignés ou
                                    personnes/entités bloquées, ou soumis à des embargos commerciaux ou à des sanctions
                                    financières, économiques et commerciales
                                </p>
                            </li>
                            <li className="mb-2 pl-4">
                                <p>
                                    et personnes ou tiers coupables de blanchiment d'argent, de fraudes ou de
                                    corruption.
                                </p>
                            </li>
                        </ul>
                    </ul>
                    <li className="mb-2">
                        <p>
                            <span className="font-bold">11.3</span> Le Prestataire de services devra, sur première
                            demande de l’Application et du Site, coopérer et assister entièrement les supports
                            numériques (et communiquer toute information jugée nécessaire à cet égard) dans
                            l'identification du propriétaire (final), du responsable et/ou du directeur du Prestataire
                            de services, et informer rapidement Le Site de tout manquement aux garanties présentées
                            ci-dessus.
                        </p>
                    </li>
                    <li className="mb-2">
                        <p>
                            <span className="font-bold">11.4 Garantie de Conformité</span>
                        </p>
                    </li>
                    <p className="mb-2">
                        La garantie de conformité est une garantie légale qui s’applique en dehors de tout engagement
                        commercial :
                    </p>
                    <ul className="list-decimal ml-4 mb-2">
                        <li className="mb-2 pl-4">
                            <p>
                                Lorsque le contrat prévoit une opération de fourniture ponctuelle du service numérique,
                                ou une série d'opérations de fourniture distinctes, le professionnel répond des défauts
                                de conformité existant au moment de la fourniture et qui apparaissent dans un délai de
                                deux ans à compter de celle-ci.
                            </p>
                        </li>
                        <li className="mb-2 pl-4">
                            <p>
                                Lorsque le contrat prévoit que le service numérique est fourni de manière continue, le
                                professionnel répond des défauts de conformité qui apparaissent au cours de la période
                                durant laquelle celui-ci est fourni en vertu du contrat.
                            </p>
                        </li>
                    </ul>
                    <p className="mb-2">
                        Le professionnel répond également, dans les mêmes délais, des défauts de conformité résultant de
                        l'intégration incorrecte du contenu numérique ou du service numérique dans l'environnement
                        numérique du consommateur lorsque celle-ci a été réalisée par le professionnel ou sous sa
                        responsabilité, ou encore lorsque l'intégration incorrecte réalisée par le consommateur résulte
                        de lacunes dans les instructions que le professionnel a fournies.
                    </p>
                    <p className="mb-2">
                        Cette période court à compter de la demande d'intervention du consommateur ou de la mise à
                        disposition du contenu numérique ou du service numérique en cause, si ce point de départ s'avère
                        plus favorable au consommateur.
                    </p>
                    <p className="mb-2">
                        Lorsqu’il agit en garantie légale de conformité, le consommateur a droit à la mise en conformité
                        du contenu numérique ou du service numérique ou, à défaut, à la réduction du prix ou à la
                        résolution du contrat conformément aux dispositions légales.{' '}
                    </p>
                    <p className="mb-2">
                        Le professionnel peut refuser la mise en conformité si elle s'avère impossible ou entraîne des
                        coûts disproportionnés, au regard notamment de l'importance du défaut de conformité et de la
                        valeur du contenu numérique ou du service numérique en l'absence de défaut.{' '}
                    </p>
                    <p className="mb-2">
                        Par ailleurs, le consommateur peut décider de mettre en œuvre la garantie relative aux vices
                        cachés, due par le vendeur conformément aux dispositions des articles 1641 à 1649 du code civil,
                        et dans cette hypothèse, il peut choisir entre la résolution de la vente ou une réduction du
                        prix de vente conformément à l’article 1644 du code civil.
                    </p>
                    <p className="mb-2">
                        LE CLIENT EST EXPRESSÉMENT INFORMÉ QUE CETTE GARANTIE NE S’APPLIQUE PAS AUX CLIENTS
                        PROFESSIONNELS ET AUX SERVICES AUTRES QUE LES SERVICES NUMÉRIQUES, QUE LE PROFESSIONNEL UTILISE
                        OU NON DES FORMATS OU DES MOYENS NUMÉRIQUES POUR CRÉER LE PRODUIT DU SERVICE, LE FOURNIR OU LE
                        TRANSMETTRE AU CONSOMMATEUR.
                    </p>
                    <li className="mb-2">
                        <span className="font-bold">11.5 Limitation de responsabilité.</span>
                    </li>
                    <p className="mb-2">
                        Quel que soit le type de formule du Service sélectionné par le Client professionnel, la
                        responsabilité de la Société est expressément limitée à l’indemnisation des dommages directs
                        prouvés par le Client professionnel. En aucun cas, la responsabilité de la Société ne saurait
                        être engagée au titre des dommages indirects tels que la perte de données, de fichier(s), perte
                        d’exploitation, préjudice commercial, manque à gagner, atteinte à l’image et à la réputation du
                        Client professionnel. De la même manière, la Société ne pourra être tenue responsable des
                        dommages directs et indirects causés au matériel de l’Utilisateur, lors de l’accès à la Société,
                        et résultant soit de l’utilisation d’un matériel ne répondant pas aux conditions prévues, soit
                        de l’apparition d’un bug ou d’une incompatibilité.
                    </p>
                    <p className="mb-2">
                        EN TOUT ÉTAT DE CAUSE, LA RESPONSABILITÉ DE LA SOCIÉTÉ EST PLAFONNÉE À L'ÉGARD DES
                        PROFESSIONNELS AU MONTANT DU PRIX PAYÉ HORS TAXES PAR LE CLIENT EN CONTREPARTIE DE LA FOURNITURE
                        DU SERVICE DANS LE CADRE DUQUEL S’INSCRIT LE LITIGE. CE MONTANT S’ENTEND COMME LE MAXIMUM QUE
                        POURRAIT ETRE AMENE A REGLER LA SOCIÉTÉ À TITRE D'INDEMNITÉ (DOMMAGE ET INTÉRÊTS) ET DE
                        PÉNALITÉS, QUELS QUE SOIENT LES CHEFS DE PRÉJUDICES ALLÉGUÉS ET LES FONDEMENTS JURIDIQUES
                        RETENUS À MOINS QUE LA LOI OU LA JURISPRUDENCE NE S’Y OPPOSE.{' '}
                    </p>
                </ul>
                <h5 className="font-bold text-[13px] underline my-2">
                    Article 12. Conditions d’accès à l’Application et au Site
                </h5>
                <p>
                    <span className="font-bold">12.1. Accès à l’Application et au Site.</span>
                </p>
                <p>
                    L’Application et le Site sont accessibles gratuitement et sous réserve des abonnements souscrits par
                    les Clients professionnels concernant son utilisation, à toute personne disposant d’un accès à
                    Internet.{' '}
                </p>
                <p>
                    Tous les coûts afférents à l’accès à l’Application et au Site, que ce soient les frais matériels,
                    logiciels ou d’accès à Internet sont exclusivement à la charge de l’Utilisateur. La Société ne
                    pourra être tenue responsable de dommages matériels liés à l’utilisation du Site. De plus,
                    l’Utilisateur s’engage à accéder à l’Application et au Site en utilisant un matériel récent, ne
                    contenant pas de virus et avec un navigateur de dernière génération mis à jour. L’Utilisateur est
                    seul responsable du bon fonctionnement de son équipement informatique ainsi que de son accès à
                    l’internet, pour accéder au Site et au Service.
                </p>
                <p>
                    <span className="font-bold">12.2. Accès au Service.</span>
                </p>
                <p>
                    La Société fournira automatiquement les Identifiants permettant d’accéder au Service et au plus tard
                    dans un délai d’une heure à compter de la date et de l’heure auxquelles le Client a passé sa
                    Commande, sous réserve de l’acceptation du paiement par sa banque. Il est donc primordial de fournir
                    une adresse électronique valide. Les conditions d’accès au Service peuvent varier selon les
                    conditions d’exercice du droit de rétractation, conformément aux modalités prévues par les CGV et la
                    loi.{' '}
                </p>
                <p>
                    Le Client est invité à contacter la Société s’il n’a pas reçu ou s’il a égaré ses Identifiants afin
                    que ceux-ci soient réinitialisés sous 48 heures. La mise à disposition ultérieure des Identifiants
                    dégage la Société de toute responsabilité vis-à-vis du Client qui ne pourra la tenir pour
                    responsable d’une indisponibilité du Service.{' '}
                </p>
                <p>
                    <span className="font-bold">12.3. Caractère individuel et personnel des Identifiants.</span>
                </p>
                <p>
                    Tout Identifiant de connexion fourni par la Société au Client est strictement personnel, individuel,
                    confidentiel et intransmissible. Le Client s’engage à ne souscrire au Service qu’à des fins
                    exclusivement personnelles et déclare ne pas revendre, distribuer ou louer à des tiers tout ou
                    partie des Services et notamment le contenu des Services ou tout autre produit reçu dans le cadre de
                    sa Commande. Le Client qui ne respecterait pas cet engagement s’expose à des poursuites. Le Client
                    répondra de toute utilisation non autorisée, frauduleuse ou abusive de ses Identifiants, et
                    informera sans délai la Société de la perte ou du vol de ces derniers. En cas de violation avérée
                    des conditions d’accès au Site ou Service, la Société se réserve le droit de suspendre l'accès au
                    Service, sans indemnité, préavis, ni information préalable.
                </p>
                <p>
                    <span className="font-bold">12.4. Nombre d’accès.</span>
                </p>
                <p>
                    Sauf conditions particulières prévoyant des accès plus étendus, la souscription d’un Service par un
                    Client ne comprend des droits d’accès que pour une seule personne et ce quels que soient le nombre
                    de collaborateurs ou d’établissements du Client. Si le Client souhaite que plusieurs de ses
                    employés, collaborateurs, partenaires ou toute autre personne puisse accéder au Service, il devra
                    souscrire autant de contrats que de personnes pour lesquelles un accès au Service est souhaité.
                    Cette clause pourra être adaptée en fonction du nombre d’utilisateur autorisé / achat.
                </p>
                <p>
                    <span className="font-bold">12.5. Maintenance.</span>
                </p>
                <p>
                    L’Application et le Site peuvent faire l’objet d’opérations de maintenance et la Société se réserve
                    la possibilité d’interrompre, de suspendre momentanément ou de modifier sans préavis l’accès à tout
                    ou partie de ses supports numériques afin d’en assurer la maintenance (notamment par le biais de
                    mises à jour) ou pour toute autre raison, sans que l’interruption n’ouvre droit à aucune obligation
                    ni indemnisation. L’Application et le Site se réservent expressément le droit d’intervenir sur les
                    comptes ouverts par les Utilisateurs professionnels et non professionnels en cas de nécessité de
                    maintenance et/ou de difficultés liés aux systèmes numériques mis à disposition par eux.
                </p>
                <p>
                    <span className="font-bold">12.6. Responsabilité contractuelle.</span>
                </p>
                <p>
                    La Société met en œuvre tous les moyens raisonnables à sa disposition pour assurer un accès continu
                    et de qualité aux supports numériques, mais n’est tenue à aucune obligation d’y parvenir. Notamment,
                    la Société ne peut être tenue responsable de tout dysfonctionnement du réseau ou des serveurs ou de
                    tout autre événement échappant à son contrôle raisonnable, qui empêcherait l’accès à l’application,
                    au Site ou au Service.
                </p>
                <p>
                    <span className="font-bold">12.7. Utilisation de bonne foi - Sites tiers.</span>
                </p>
                <p>
                    Tout espace de contribution sur l’Application et le Site et auquel a éventuellement accès le Client
                    dans le cadre d’un Service, y compris sur les réseaux sociaux et groupes, doit être utilisé de bonne
                    foi. Le Client s’interdit tout propos diffamatoire, menaçant, haineux, intolérant, obscène, etc. et
                    toute publication dénigrante de nature à porter atteinte aux droits de la Société, des autres
                    Utilisateurs, des tiers ou contraire à la loi.{' '}
                </p>
                <p>
                    La Société se réserve le droit de refuser l’accès à tout ou partie de l’Application et du Site, du
                    Service, des espaces de contribution et groupes ou de limiter les droits d’accès aux espaces de
                    contribution et groupes, unilatéralement et sans notification préalable à tout Client ne respectant
                    pas les CGV, les éventuelles conditions d’utilisation de l’Application, du Site, du Service et/ou de
                    tous sites, plateformes et outils tiers accessibles dans le cadre du Service ou plus généralement,
                    toute obligation légale ou réglementaire.{' '}
                </p>
                <p>
                    Le Client s’engage à s’informer des conditions d’utilisation de tous sites, réseaux sociaux,
                    plateformes et outils tiers accessibles dans le cadre du Service et à s’y conformer. La Société
                    n’exerce aucun contrôle sur ces conditions et décline toute responsabilité en cas de bannissement ou
                    de litige du Client avec ces sites tiers.
                </p>
                <h5 className="font-bold text-[13px] underline my-2">
                    ARTICLE 13- Protection des données personnelles – Politique de confidentialité
                </h5>
                <p>
                    En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, il
                    est rappelé que les données nominatives demandées au Client sont nécessaires au traitement de sa
                    commande et à l'établissement des factures, notamment.
                </p>
                <p>
                    Ces données peuvent être communiquées aux éventuels partenaires chargés de l'exécution, du
                    traitement, de la gestion et du paiement des commandes en conformité avec le Règlement Général de
                    Protection des Données.
                </p>
                <p>
                    Le traitement des informations communiquées par l'intermédiaire de l’application et du site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    répond aux exigences légales en matière de protection des données personnelles, le système
                    d'information utilisé assurant une protection optimale de ces données.
                </p>
                <p>
                    Le Client dispose, conformément aux réglementations nationales et européennes en vigueur d'un droit
                    d'accès permanent, de modification, de rectification, d'opposition de portabilité et de limitation
                    du traitement s'agissant des informations le concernant.
                </p>
                <p>
                    Ce droit peut être exercé dans les conditions et selon les modalités définies à la Politique de
                    confidentialité publiée sur l’Application et le site internet{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com.
                    </a>
                </p>
                <p>
                    Les utilisateurs de l’Application et du Site et de tout autre support numérique directement ou
                    indirectement rattachés à ces derniers doivent prendre connaissance de la Politique de
                    confidentialité publiée sur ces supports. En utilisant ces supports numériques, l’acceptation de la
                    Politique de confidentialité est réputée acquise.
                </p>
                <h5 className="font-bold text-[13px] underline my-2">
                    ARTICLE 14 : RÉFÉRENCES CLIENT ET COMMUNICATION
                </h5>
                <p>
                    <span className="font-bold">14.1. Avis en ligne.</span>
                </p>
                <p>
                    Les Clients peuvent déposer un avis sur l’Application et le Site en remplissant le formulaire prévu
                    à cet effet. Ils s’engagent à fournir une description fidèle de leur expérience de consommation.{' '}
                </p>
                Les avis sur le Site sont datés et classés par ordre chronologique.
                <p>Ces commentaires font l’objet d’un contrôle par la Société et sont gérés par elle.</p>
                <p>
                    Les avis sont publiés dans leur intégralité. Tous les avis, qu’ils soient positifs ou négatifs, sont
                    susceptibles d’être publiés. Cependant, la publication d’un avis peut être modérée ou refusée pour
                    l’un des motifs suivants :
                </p>
                <ul className='ml-4 list-["-"]'>
                    <li className="pl-4 mb-2">
                        <p>commentaire émis par une personne n’étant pas Cliente;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>commentaire injurieux, diffamatoire, haineux, discriminatoire ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>commentaire contenant un lien hypertexte vers un site tiers ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>commentaire sans lien avec la prestation sur laquelle porte l’avis ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>commentaire mentionnant des données à caractère personnel ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>commentaire inintelligible ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            commentaire contraire à toute disposition légale ou réglementaire en vigueur au jour de la
                            publication de l’avis ;
                        </p>
                    </li>
                </ul>
                <p>
                    Ce contrôle est effectué avant la publication de l’avis, dans un délai de 8 jours (huit) à compter
                    de son dépôt par l’utilisateur.
                </p>
                <p>Les Clients peuvent modifier ou supprimer leur avis à tout moment.</p>
                <p>
                    En tout état de cause, la Société se réserve le droit de prendre contact avec l’Utilisateur qui a
                    déposé un avis sur le Site.{' '}
                </p>
                <p>
                    <span className="font-bold">14.2. Référence-Client.</span>
                </p>
                <p>
                    Le Client pourra être sollicité par la Société afin d’être cité comme acheteur d’un Produit ou d’un
                    Service. Avec l’accord du Client, la Société pourra être autorisée à mentionner le nom du Client,
                    l’avis qu’il a donné à la Société sur le Produit et Services ainsi qu’une description objective de
                    la nature du Produit et Service qui lui a été vendu dans ses listes de références et propositions à
                    l’attention de ses prospects et de sa clientèle notamment sur l’Application et le Site, à des fins
                    promotionnelles et publicitaires, à l’occasion d’entretiens avec des tiers, de communications à son
                    personnel, de documents internes de gestion prévisionnelle, ainsi qu’en cas de dispositions légales,
                    réglementaires ou comptables l’exigeant.
                </p>
                <p>
                    <span className="font-bold">14.3. Autorisation d'exploitation.</span>{' '}
                </p>
                <p>
                    Lorsque le Client adresse des écrits, des vidéos et/ou photographies à la Société pour donner son
                    avis ou témoigner sur le Produit et service fourni par la Société, et le cas échéant émet des
                    commentaires ou publications concernant la Société (par exemple, sur ses réseaux sociaux), auxquels
                    sont rattachés son identifiant et sa photo de profil, alors le Client autorise la Société à
                    exploiter ces contenus pour la promotion de ses activités commerciales. Les contenus sont
                    susceptibles d'être protégés par des droits à l'image et/ou des droits d'auteur, et dans ce cas, le
                    Client concède à la Société la possibilité de les adapter (sur la forme) et de les reproduire sur
                    tous supports, notamment en les présentant comme référence commerciale et/ou en tant qu'avis. Par
                    exemple, la Société pourra prendre des captures d'écran des publications sur les réseaux sociaux la
                    concernant ou sur le Produit et Service livré au Client, et les reproduire sur le Site en tant
                    qu'avis.
                </p>
                <p>
                    Le Client reconnaît être entièrement rempli de ses droits et ne pourra prétendre à aucune
                    rémunération pour l’exploitation des droits visés par le présent paragraphe. Ces droits sont
                    concédés pour la durée de vie du Client concerné, augmentée d'une durée de 70 ans, et pour le monde
                    entier. La Société se réserve la possibilité de soumettre au Client toute autre demande
                    d'autorisation de prise de vue et de cession de droits, pour tous les cas non prévus aux présentes
                    ou à titre ponctuel.{' '}
                </p>
                <p>
                    <span className="font-bold">14.4. Évènement.</span>{' '}
                </p>
                <p>
                    Les dispositions du paragraphe précédent s’appliquent de la même manière s’agissant de vidéos et
                    photographies prises à l’occasion de tout type d'événement (public, réservé aux clients, etc.)
                    organisés ou coorganisé par la Société. Les captations pourront être enregistrées et publiées par la
                    Société, y compris sur les réseaux sociaux. Si le Client ne souhaite pas apparaître sur une
                    éventuelle publication des images, il lui plaira de se placer en fond de salle, de ne pas participer
                    aux photos groupées, de ne pas faire un signe au photographe / cameraman dans le champ duquel il
                    pourrait être placé.
                </p>
                <h5 className="font-bold text-[13px] underline my-2">ARTICLE 15- Propriété intellectuelle</h5>
                <span className="font-bold underline">15.1 - Propriété et tous droits réservés</span>
                <ul className="grid gap-y-2">
                    <li>
                        <p className="mb-2">
                            <span className="font-bold">15.1.1</span> Sans limiter les présentes Conditions à une seule
                            langue, le Client et/ou le Prestataire de services accepte et reconnaît que les Services et
                            le contenu de l’Application et du Site sont protégés par : droits d'auteur, marque déposée,
                            protection des bases de données et autres Lois et que le contenu de l’application et du Site
                            (y compris les droits de propriété intellectuelle associés) constitue la seule et exclusive
                            propriété du Site et des personnes accordant la licence.
                        </p>
                        <p className="mb-2">
                            Le Client et/ou le Prestataire de services reconnaît et accepte également que toutes les
                            Marques Déposées apparaissant sur l’Application et le Site, les Services de l’Application et
                            du Site Internet et leur contenu sont détenues par la Société ou les tiers les fournissant.
                            Le Client et/ou le Prestataire de services ne doit pas retirer, modifier, dissimuler ou
                            réclamer les droits d'auteur, la marque déposée, le logo ou tout autre droit de propriété
                            incorporé à ou accompagnant le contenu du Site.
                        </p>
                    </li>

                    <li>
                        <p>
                            <span className="font-bold">15.1.2</span> Le Client et/ou le Prestataire de services accepte
                            et reconnaît que la reproduction, la copie, l'utilisation, l'intégration, l'imitation, la
                            publication ou la distribution de
                        </p>
                    </li>

                    <ul className="ml-4 mb2 list-['-']">
                        <li className="pl-4 mb-2">tout le contenu de l’Application et du Site</li>
                        <li className="pl-4 mb-2">
                            tout le moteur de réservation lui-même (y compris l'apparence et la composition, certains
                            éléments ou particularités uniques du moteur de réservation pour n'importe quel but ou
                            utilisation non expressément autorisés par les présentes Conditions,
                        </li>
                    </ul>
                    <p>est strictement interdit sans l'accord écrit préalable de la Société.</p>
                    <p>
                        Tous droits non expressément accordés par les présentes sont réservés à la Société. Le Client
                        et/ou le Prestataire de services devra se conformer sans délai, à ses propres frais, à toutes
                        les demandes raisonnables provenant de la Société pour effectuer des modifications, changements
                        ou corrections concernant tout aspect ou élément de son moteur de réservation, qui est ou
                        pourrait être identique ou confusément semblable à tout élément du Site.
                    </p>

                    <li>
                        <p>
                            <span className="font-bold">15.1.3</span> Le Client et/ou le Prestataire de services accepte
                            de ne pas spécifiquement viser les marques de l’Application et du Site (y compris les
                            marques affiliées) par l'achat de mots-clés qui utiliseraient des droits de propriété
                            intellectuelle des supports numériques visés et de ses sociétés affiliées.
                        </p>
                    </li>
                    <li>
                        <p>
                            <span className="font-bold">15.1.4</span> Le Client et/ou le Prestataire de services ne doit
                            pas (inverser/dé)composer, effectuer d'ingénierie (inversée) sur ou (dé)compiler (ou essayer
                            d'extraire le code source) de l’Application et du Site (y compris les fonctionnalités) et le
                            Client et/ou le Prestataire de services ne doit pas utiliser les l’Application et le Site (y
                            compris les fonctionnalités) pour tout motif autre que ceux prévus aux présentes conditions
                            générales de vente et d’utilisation. Cet Article reste applicable après la fin des relations
                            contractuelles.
                        </p>
                    </li>

                    <p className="font-bold underline">15.2 Responsabilité du Prestataire de services</p>
                    <ul className="grid gap-y-2">
                        <li>
                            <p className="mb-2">
                                <span className="font-bold">15.2.1</span> Rappel : le Prestataire de services accepte et
                                reconnaît qu’il est le seul responsable de tout contenu qu’il met à disposition. Ainsi
                                le Prestataire de services déclare et garantit qu’il est le propriétaire du contenu
                                fourni à ou mis à la disposition de la Société, ou qu’il détient tous les droits,
                                licences, accords et décharges nécessaires pour accorder à la Société les droits et
                                licences énoncés dans ces Conditions ou nécessaires pour remplir ses obligations dans le
                                cadre du présent.
                            </p>
                        </li>
                        <li>
                            <p className="mb-2">
                                <span className="font-bold">15.2.2</span> le Prestataire de services reconnaît et
                                accepte que la Société réalise certaines opérations de traitement des données pour son
                                compte en tant que Responsable du Traitement, conformément à la Politique de
                                confidentialité.
                            </p>
                            <p className="mb-2">
                                Le Prestataire de services reconnaît et accepte qu’il détient la responsabilité
                                d’informer les Utilisateurs Finaux du traitement des données effectué par lui-même, et
                                qu'il est également le seul responsable d'obtenir le consentement desdits Utilisateurs
                                Finaux,{' '}
                                <span className="underline">si ce consentement n’a pas été obtenu par la Société</span>{' '}
                                tel qu'exigé par les lois de protection des données en matière de Traitement des Données
                                des Utilisateurs Finaux, y compris le transfert de Données des Utilisateurs Finaux vers
                                le Site, et/ou ses affiliés, l'utilisation de ces données à des fins analytiques et
                                publicitaires, ainsi que l’utilisation de ces données conformément à la Politique de
                                confidentialité.
                            </p>
                            <p className="mb-2">
                                Afin d’informer les Utilisateurs Finaux du Traitement des Données effectué par la
                                Société ou les Prestataires, ce dernier doit consulter la Politique de confidentialité
                                publiée sur les supports numériques concernés.
                            </p>
                        </li>
                    </ul>
                </ul>
                <h5 className="font-bold text-[13px] underline my-2">ARTICLE 16 – Imprévision</h5>
                <p>
                    En cas de changement de circonstances imprévisibles lors de la conclusion du contrat, conformément
                    aux dispositions de l'article 1195 du Code civil, la Partie qui n'a pas accepté d'assumer un risque
                    d'exécution excessivement onéreuse peut demander une renégociation du contrat à son cocontractant.
                </p>
                <p>
                    <span className="font-bold underline">ARTICLE 17 - Force majeure et cas fortuit</span>
                </p>
                <p>
                    L’exécution des obligations de la Société aux termes des présentes est suspendue en cas de
                    survenance d’un cas fortuit ou de force majeure qui en empêcherait l’exécution. Cette suspension
                    peut concerner tout ou partie de la Commande. Dans ce cas, la Société avisera le Client de la
                    survenance d’un tel évènement dès que possible et de la durée estimée de la suspension. Seul un
                    empêchement définitif pourra donner lieu à restitution. Si l’empêchement définitif est partiel,
                    seule une restitution partielle sera accordée.
                </p>
                <p>
                    Sont considérés comme cas de force majeure ou cas fortuit, outre ceux habituellement reconnus par la
                    jurisprudence des cours et tribunaux et sans que cette liste ne soit restrictive : les grèves ou
                    conflits sociaux internes ou externes à la Société, les désastres naturels, les incendies,
                    l’interruption des télécommunications, les épidémies et pandémies, l’interruption de
                    l’approvisionnement en énergie, interruption des communications ou des transports de tout type ou
                    toute autre circonstance échappant au contrôle raisonnable de la Société.
                </p>
                <p>
                    La responsabilité de la Société ne saurait en aucun cas être engagée en raison de difficultés
                    rencontrées par l’Utilisateur ou le Client dans l’accès au Site en raison d’une défaillance
                    technique ou logicielle ou de toute autre cause qui lui est étrangère. Le Client reconnaît être
                    averti des aléas techniques inhérents à Internet et au réseau mobile et des dysfonctionnements qui
                    peuvent en résulter. En conséquence, la Société ne saurait être tenue responsable des éventuelles
                    indisponibilités, ralentissements ou défaillances du réseau Internet ou de solutions informatiques
                    quelconques, sauf en cas de négligence avérée de sa part.
                </p>
                <p>
                    Ainsi, les Parties ne pourront être tenues pour responsables si la non-exécution ou le retard dans
                    l'exécution de l'une quelconque de leurs obligations, telles que décrites dans les présentes découle
                    d'un cas de force majeure, au sens de l'article 1218 du Code civil.
                </p>
                <p>
                    <span className="font-bold underline">ARTICLE 18 - Droit applicable – Langue</span>
                </p>
                <p>
                    Les présentes Conditions Générales de Vente et d’utilisation ainsi que les opérations qui en
                    découlent sont régies et soumises au droit français. Les présentes Conditions Générales de Vente et
                    d’utilisation sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou
                    plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.
                </p>
                <h5 className="font-bold text-[13px] underline my-2">ARTICLE 19 - Litiges</h5>
                <p>
                    <span className="font-bold underline">
                        Article 19.1 Concernant les Clients non professionnels, consommateurs :
                    </span>
                </p>
                <p>
                    <span className="font-bold">1. Résolution amiable.</span>
                </p>
                <p>
                    En cas de litige, le Client a l’obligation de s’adresser en priorité à la Société pour tenter de
                    trouver une solution amiable.
                </p>
                <p>
                    <span className="font-bold">2. Médiation.</span>
                </p>
                <p>
                    En cas de difficulté dans l’exécution des présentes CGV/CGU, le Client consommateur résidant en
                    Europe a la possibilité, avant toute action en justice, de solliciter le recours du médiateur de la
                    consommation suivant :{' '}
                </p>
                <address>
                    <p>
                        <span className="not-italic font-bold">Association Nationale des Médiateurs</span>
                    </p>
                    <p>
                        <span className="not-italic font-bold">2, rue de Colmar 94300 VINCENNES</span>
                    </p>
                </address>
                <p>
                    Le médiateur tentera, en toute indépendance et impartialité, de rapprocher les parties en vue
                    d'aboutir à une solution amiable. Les parties restent libres d'accepter ou de refuser le recours à
                    la médiation ainsi que, en cas de recours à la médiation, d'accepter ou de refuser la solution
                    proposée par le médiateur.
                </p>
                <p>
                    Dans ce contexte, tout consommateur européen peut saisir également la Plateforme de Règlement en
                    ligne des litiges (RLL) accessible à partir de l’adresse URL suivant :
                </p>
                <a
                    href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=FR"
                    target="_blank"
                    rel="noreferrer"
                    className="text-[blue] underline"
                >
                    Règlement en ligne des litiges | Commission européenne (europa.eu)
                </a>
                <p>
                    Le client consommateur est informé que le recours à la Médiation préalable n’est pas obligatoire
                    mais fortement recommandé, et qu’il est libre de saisir le tribunal compétent du litige.
                </p>
                <p>
                    <span className="font-bold underline">Article 19.2 Concernant les Clients professionnels,</span>
                </p>
                <p>
                    Les présentes CGV/CGU sont exécutées de bonne foi par les Parties qui s’engagent à examiner
                    ensemble, dans le plus grand esprit de concertation, tout différend qui pourrait survenir quant à
                    leur existence, leur interprétation ou leur exécution.
                </p>
                <p>
                    En cas d’échec de leur négociation directe et avant toute saisine des juridictions compétentes, les
                    Parties s’engagent à mettre en place un processus de médiation.{' '}
                </p>
                <p>
                    Il est rappelé que la Médiation n’est pas obligatoire entre Professionnels et que les parties sont
                    libres de mettre en place ce processus de tentative de règlement amiable de leur différend.
                    Cependant, dans le cadre de relations contractuelles significatives, les parties s’engagent à
                    solliciter :
                </p>
                <ul className="list-['-']">
                    <li className="pl-4 mb-2">
                        <p>
                            un service de médiation ou une association de médiateurs dûment agréés, librement choisis
                            par les parties.
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            En cas de désaccord sur le choix du Médiateur, il sera considéré que la tentative de
                            médiation a échoué et fera l’objet d’une notification écrite, par tout moyen, de la partie
                            la plus diligente.
                        </p>
                    </li>
                </ul>
                <p>
                    Sauf recommandations issues de la Médiation, dont le succès ou l’échec est prévisible à très court
                    terme, et/ou accord des Parties la durée de médiation ne pourra pas excéder trois mois.
                </p>
                <p>
                    <span className="font-bold">
                        Attribution de compétence : A défaut, toute contestation relative au présent contrat entre
                        professionnels, relèvera de la compétence du Tribunal de Commerce d’Albi, la saisine du
                        Médiateur étant possible même en cas de procédure judiciaire en cours.
                    </span>
                </p>
                <h5 className="font-bold text-[13px] underline my-2">
                    ARTICLE 20 - Information précontractuelle - Acceptation du Client
                </h5>
                <p>
                    Le Client reconnaît avoir eu communication, préalablement à la passation de sa commande et à la
                    conclusion du contrat, d'une manière lisible et compréhensible, des présentes Conditions Générales
                    de Vente et d’utilisation et de toutes les informations listées à l'article L. 221-5 du Code de la
                    consommation, et notamment les informations suivantes :
                </p>
                <ul className="list-['-']">
                    <li className="pl-4 mb-2">
                        <p>
                            les caractéristiques essentielles des Produits et Services, compte tenu du support de
                            communication utilisé et des Produits et Services concernés ;
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>le prix des Produits et Services et des frais annexes ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            les informations relatives à l'identité du Vendeur, à ses coordonnées postales,
                            téléphoniques et électroniques, et à ses activités, si elles ne ressortent pas du contexte,
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            les informations relatives aux garanties légales et contractuelles et à leurs modalités de
                            mise en œuvre ;
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>les fonctionnalités du contenu numérique et, le cas échéant, à son interopérabilité ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>la possibilité de recourir à une médiation en cas de litige ;</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            les informations relatives au droit de rétractation (existence, conditions, délai, modalités
                            d'exercice de ce droit et formulaire type de rétractation), aux modalités de résiliation et
                            autres conditions contractuelles importantes.
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>les moyens de paiement acceptés.</p>
                    </li>
                </ul>
                <p>
                    Le fait pour une personne physique (ou morale), de commander via l’Application et sur le site
                    internet{' '}
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        https://ilea-coiffure.com
                    </a>{' '}
                    emporte adhésion et acceptation pleine et entière des présentes Conditions Générales de Vente et
                    obligation au paiement des Produits et services commandés, ce qui est expressément reconnu par le
                    Client, qui renonce, notamment, à se prévaloir de tout document contradictoire, qui serait
                    inopposable au Vendeur.
                </p>
                <p>
                    Le Client déclare avoir pris connaissance des présentes Conditions Générales de Ventes et
                    d’Utilisation et les avoir acceptées en cochant la case prévue à cet effet avant la mise en œuvre de
                    la procédure de commande en ligne ainsi que des conditions générales d'utilisation de l’Application
                    et du site internet
                    <a
                        href="https://ilea-coiffure.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-[blue] underline"
                    >
                        {' '}
                        https://ilea-coiffure.com.
                    </a>
                </p>
                <div className="w-full border-2 border-black my-4">
                    <h3 className="px-12 font-bold text-center">
                        ANNEXE I - Dispositions relatives aux garanties légales
                    </h3>
                </div>
                <p>
                    <span className="font-bold underline">Article L217-4 du Code de la consommation</span>
                </p>
                <p>
                    Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de conformité
                    existant lors de la délivrance. Il répond également des défauts de conformité résultant de
                    l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa
                    charge par le contrat ou a été réalisée sous sa responsabilité.
                </p>
                <p>
                    <span className="font-bold underline">Article L217-5 du Code de la consommation</span>
                </p>
                <p>Pour être conforme au contrat, le bien doit :</p>
                <ul className="ml-4 list-['-']">
                    <li className="pl-4 mb-2">
                        <p>être propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :</p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            correspondre à la description donnée par le vendeur et posséder les qualités que celui-ci a
                            présentées à l'acheteur sous forme d'échantillon ou de modèle
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            présenter les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations
                            publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans
                            la publicité ou l'étiquetage
                        </p>
                    </li>
                    <li className="pl-4 mb-2">
                        <p>
                            ou présenter les caractéristiques définies d'un commun accord par les parties ou être propre
                            à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce
                            dernier a accepté.
                        </p>
                    </li>
                </ul>
                <p>
                    <span className="font-bold underline">Article L217-12 du Code de la consommation</span>
                </p>
                <p>
                    L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du
                    bien.
                </p>
                <p>
                    <span className="font-bold underline">Article L217-16 du Code de la consommation</span>
                </p>
                <p>
                    Lorsque l'acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été
                    consentie lors de l'acquisition ou de la réparation d'un bien meuble, une remise en état couverte
                    par la garantie, toute période d'immobilisation d'au moins sept jours vient s'ajouter à la durée de
                    la garantie qui restait à courir. Cette période court à compter de la demande d'intervention de
                    l'acheteur ou de la mise à disposition pour réparation du bien en cause, si cette mise à disposition
                    est postérieure à la demande d'intervention.
                </p>
                <p>
                    <span className="font-bold underline">Article 1218 du Code civil</span>
                </p>
                <p>
                    Il y a force majeure en matière contractuelle lorsqu'un événement échappant au contrôle du débiteur,
                    qui ne pouvait être raisonnablement prévu lors de la conclusion du contrat et dont les effets ne
                    peuvent être évités par des mesures appropriées, empêche l'exécution de son obligation par le
                    débiteur.
                </p>
                <p>
                    Si l'empêchement est temporaire, l'exécution de l'obligation est suspendue à moins que le retard qui
                    en résulterait ne justifie la résolution du contrat. Si l'empêchement est définitif, le contrat est
                    résolu de plein droit et les parties sont libérées de leurs obligations dans les conditions prévues
                    aux articles 1351 et 1351-1.
                </p>
                <p>
                    <span className="font-bold underline">Article 1641 du Code civil</span>
                </p>
                <p>
                    Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent
                    impropre à l'usage auquel on la destine, ou qui diminuent tellement cet usage, que l'acheteur ne
                    l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait connus.
                </p>
                <p>
                    <span className="font-bold underline">Article 1648 alinéa 1er du Code civil</span>
                </p>
                <p>
                    L'action résultant des vices rédhibitoires doit être intentée par l'acquéreur dans un délai de deux
                    ans à compter de la découverte du vice.
                </p>
            </div>
        </Modal.default>
    )
}