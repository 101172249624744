import React, { useState, useEffect, useRef } from 'react'

import { Client, Hairdresser, Request } from '../../../contexts'

import { useLoadScript, Autocomplete } from '@react-google-maps/api'
const libraries = ['places']

const IleaAutocomplete = ({ initialValue, setValue }) => {
    const { setMessage } = Request.useRequest()

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDLLGftOmQX8SG8n8Swzh6ooOoxrfbtWVA',
        libraries,
    })

    const [autocomplete, setAutocomplete] = useState(null)
    const [inputValue, setInputValue] = useState(initialValue || '')
    const inputRef = useRef(null)

    const onLoad = (autocomplete) => {
        setAutocomplete(autocomplete)
    }

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace()

            if (
                place.formatted_address &&
                (place?.types?.includes('premise') ||
                    place?.types?.includes('street_address') ||
                    place?.types?.includes('subpremise') ||
                    place?.types?.includes('route'))
            ) {
                setInputValue(place.formatted_address)
                const formattedObject = {
                    formatted: place.formatted_address,
                    place_id: place.place_id,
                    lat: place.geometry.location.lat(),
                    lon: place.geometry.location.lng(),
                }

                setValue(formattedObject)
                localStorage.setItem('address', JSON.stringify(formattedObject))
            } else {
                setMessage({
                    type: 'warning',
                    message:
                        "Adresse invalide, veuillez renseigner l'adresse exacte avec un numéro, une rue et une ville",
                })
            }
        }
    }

    useEffect(() => {
        setInputValue(initialValue || '')
    }, [initialValue])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputValue.length])

    return isLoaded ? (
        <div>
            <div className="w-full px-6 py-4 bg-white border border-black rounded-full">
                {inputValue?.length > 5 ? (
                    <Autocomplete
                        onLoad={onLoad}
                        onPlaceChanged={onPlaceChanged}
                        options={{ types: ['address'] }}
                        className="w-full"
                    >
                        <input
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            type="text"
                            className="w-full"
                            ref={inputRef}
                        />
                    </Autocomplete>
                ) : (
                    <input
                        type="text"
                        placeholder="Entrez votre adresse"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        ref={inputRef}
                    />
                )}
            </div>
        </div>
    ) : (
        <div className="w-full px-6 py-4 bg-white border border-black rounded-full">
            <input
                type="text"
                placeholder="Entrez votre adresse"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />
        </div>
    )
}

const Default = ({ initialValue, setValue }) => {
    return (
        <div className="grid w-full gap-1">
            <label className="ml-2 text-lg font-medium">Adresse du domicile</label>
            <IleaAutocomplete initialValue={initialValue || ''} setValue={(value) => setValue(value)} />
        </div>
    )
}

const ProfilHairdresser = () => {
    const { address, handleSaveAttribute } = Hairdresser.useProfil()

    const [text, setText] = useState(address.formatted)

    useEffect(() => {
        setText(address.formatted)
    }, [address])

    return (
        <div className="grid w-full gap-1">
            <label className="ml-2 text-lg font-medium">Adresse du domicile</label>
            <IleaAutocomplete initialValue={text} setValue={(value) => handleSaveAttribute('address', value)} />
        </div>
    )
}

const ProfilClient = () => {
    const { address, handleSaveAttribute } = Client.useProfil()

    const [text, setText] = useState(address?.formatted)

    useEffect(() => {
        if (address) {
            setText(address.formatted)
        }
    }, [address])

    return (
        <div className="grid w-full gap-1">
            <label className="ml-2 text-lg font-medium">Adresse du domicile</label>
            <IleaAutocomplete initialValue={text} setValue={(value) => handleSaveAttribute('address', value)} />
        </div>
    )
}

const DefaultClient = ({ setValue }) => {
    const profil = Client.useProfil()
    const { address } = Client.useCoiffeur()

    const [text, setText] = useState('')

    useEffect(() => {
        if (address.formatted !== text) {
            setText(address.formatted)
        }
    }, [address])

    useEffect(() => {
        setValue(profil.address)
    }, [])

    return (
        <div className="grid w-full gap-1">
            <label className="ml-2 text-lg font-medium">Adresse du domicile</label>
            <IleaAutocomplete initialValue={text} setValue={(value) => setValue(value)} />
        </div>
    )
}

export { Default, ProfilHairdresser, ProfilClient, DefaultClient }
