import React, { useEffect, useState } from 'react'
import { Input, Button, Icon, Text, Map, Modal, Tooltips } from '../../../atoms'
import { Image } from '../..'
import Boy from '../../../../assets/avatar/Boy.svg'
import { Auth, Hairdresser } from '../../../../contexts'

import { formatSecondsToHHhmm, formatSecondsToInput } from '../../../../utils'

const Form = () => {
    const { email, handleChangePassword, getNewPassword } = Auth.useAuth()
    const { firstName, lastName, phoneNumber, rayon, avatar, handleSaveAttribute, schedules, saveSchedule, address } =
        Hairdresser.useProfil()

    const [newFirstName, setNewFirstName] = useState(firstName)
    const [newLastName, setNewLastName] = useState(lastName)
    const [newphoneNumber, setNewPhoneNumber] = useState(phoneNumber?.phone)
    const [newRayon, setNewRayon] = useState(rayon)

    const [changePassword, setChangePassword] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [forgotPassword, setForgotPassword] = useState(false)
    const [success, setSuccess] = useState(false)

    const [changeAvatar, setChangeAvatar] = useState(false)

    const [newSchedules, setNewSchedules] = useState([])
    const [changeSchedules, setChangeSchedules] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    useEffect(() => {
        setNewFirstName(firstName)
    }, [firstName])

    useEffect(() => {
        setNewLastName(lastName)
    }, [lastName])

    useEffect(() => {
        setNewPhoneNumber(phoneNumber?.phone)
    }, [phoneNumber])

    useEffect(() => {
        setNewRayon(rayon)
    }, [rayon])

    useEffect(() => {
        if (schedules) {
            const daysOfWeekOrder = ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi', 'dimanche']

            // Filtrer les doublons
            const uniqueNames = new Set()
            const uniqueSchedules = schedules.filter((schedule) => {
                const lowercaseName = schedule.name.toLowerCase()
                if (!uniqueNames.has(lowercaseName) && daysOfWeekOrder.includes(lowercaseName)) {
                    uniqueNames.add(lowercaseName)
                    return true
                }
                return false
            })

            // Trier les horaires en fonction de l'ordre des jours de la semaine
            uniqueSchedules.sort((a, b) => {
                return daysOfWeekOrder.indexOf(a.name.toLowerCase()) - daysOfWeekOrder.indexOf(b.name.toLowerCase())
            })

            setNewSchedules(uniqueSchedules)
        }
    }, [schedules])

    return (
        <form className="grid gap-5">
            <div className="flex">
                <div className="flex flex-col mr-6">
                    <Input.Text.Classic
                        labelValue="Prénom"
                        placeholder="Prénom"
                        value={newFirstName}
                        setValue={(value) => setNewFirstName(value)}
                        onBlur={() => handleSaveAttribute('firstName', newFirstName)}
                    />
                    <Input.Text.Classic
                        labelValue="Nom"
                        placeholder="Nom"
                        value={newLastName}
                        setValue={(value) => setNewLastName(value)}
                        onBlur={() => handleSaveAttribute('lastName', newLastName)}
                    />
                </div>
                <div className="flex flex-col gap-y-4">
                    <Text.Paragraph.Normal>Avatar</Text.Paragraph.Normal>
                    <div
                        className="rounded-full w-[30vw] h-[30vw] bg-light-gray border border-black border-opacity-10"
                        style={{
                            backgroundImage: `url(${avatar ? avatar?.thumbnailUrl : Boy})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover',
                        }}
                        onClick={() => setChangeAvatar(true)}
                    ></div>
                </div>
            </div>
            <Input.AutocompleteSearch.ProfilHairdresser />
            <Input.Text.Classic
                labelValue="Rayon de déplacement"
                placeholder="20 km"
                value={newRayon}
                setValue={(value) => setNewRayon(value)}
                onBlur={() => handleSaveAttribute('rayon', parseInt(newRayon))}
            />
            {address.lat && address.lon && rayon > 0 && <Map.Rayon />}
            <div className="grid gap-1 relative">
                <label className="text-xl font-bold">Temps de déplacement max</label>
                <div className="flex items-center justify-between">
                    <Text.Paragraph.Normal>Entre 2 rendez-vous :</Text.Paragraph.Normal>
                    <Tooltips text="Vous ne ferez pas plus de 15 minutes de trajet entre 2 rendez-vous">
                        <div className="flex space-x-2">
                            <Text.Paragraph.Normal>15 min</Text.Paragraph.Normal>
                            <Icon.Info className="w-6 h-6" />
                        </div>
                    </Tooltips>
                </div>
                <div className="flex items-center justify-between">
                    <Text.Paragraph.Normal>Entre matin et après-midi :</Text.Paragraph.Normal>
                    <Tooltips text="Vous ne ferez pas plus de 30 minutes de trajet pour passer du secteur du matin au secteur de l'après-midi">
                        <div className="flex space-x-2">
                            <Text.Paragraph.Normal>30 min</Text.Paragraph.Normal>
                            <Icon.Info className="w-6 h-6" />
                        </div>
                    </Tooltips>
                </div>
            </div>
            <Input.Text.Classic
                type="email"
                labelValue="Adresse email"
                placeholder="exemple@exemple.com"
                value={email}
                setValue={() => {}}
            />
            <Input.Text.Classic
                labelValue="Téléphone"
                placeholder="06 12 34 56 78"
                value={newphoneNumber}
                setValue={(value) => setNewPhoneNumber(value)}
                onBlur={() =>
                    handleSaveAttribute('phoneNumber', { code: '+33', short: 'FR', phone: newphoneNumber.toString() })
                }
            />
            <div className="grid gap-2">
                <div className="flex justify-between font-bold">
                    <Text.Paragraph.Normal>Horaires</Text.Paragraph.Normal>
                    <div className="flex" onClick={() => setChangeSchedules(true)}>
                        <Icon.Pencil className="w-5" />
                        <Text.Link>Modifier</Text.Link>
                    </div>
                </div>
                <div className="grid gap-1">
                    {newSchedules?.map((schedule) => {
                        return (
                            <div className="flex justify-between" key={schedule.name}>
                                <Text.Paragraph.Normal className="capitalize">{schedule.name}</Text.Paragraph.Normal>
                                <div>
                                    {schedule.isWorkingDay ? (
                                        <>
                                            {`${formatSecondsToHHhmm(schedule.startOfDay)} - ${formatSecondsToHHhmm(
                                                schedule.endOfDay
                                            )}`}
                                        </>
                                    ) : (
                                        'Repos'
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="flex justify-center mt-6">
                <Button.Primary
                    iconLeft={<Icon.Lock className={`h-6`} />}
                    onClick={() => {
                        setChangePassword(true)
                    }}
                >
                    Changer de mot de passe
                </Button.Primary>
            </div>
            <Modal.default
                isOpen={changePassword}
                title="Changer de mot de passe"
                confirmButton={
                    <Button.Primary
                        iconRight={<Icon.ArrowNext />}
                        onClick={async () => {
                            const response = await handleChangePassword(oldPassword, password, confirmPassword)
                            if (response?.data) {
                                setOldPassword()
                                setPassword()
                                setConfirmPassword()
                                setChangePassword(false)
                            }
                        }}
                    >
                        Valider
                    </Button.Primary>
                }
                setIsOpen={() => setChangePassword(false)}
            >
                <div
                    onClick={() => {
                        setChangePassword(false)
                        setForgotPassword(true)
                    }}
                >
                    <Text.Link>Mot de passe oublié</Text.Link>
                </div>
                <Input.Text.Password
                    labelValue="Mot de passe actuel"
                    placeholder="Taper votre mot de passe actuel..."
                    value={oldPassword}
                    setValue={setOldPassword}
                />
                <Input.Text.Password
                    labelValue="Nouveau mot de passe"
                    placeholder="Taper votre nouveau mot de passe..."
                    value={password}
                    setValue={setPassword}
                />
                <Input.Text.Password
                    labelValue="Confirmer le nouveau mot de pass"
                    placeholder="Retaper le mot de passe..."
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                />
            </Modal.default>
            <Modal.default
                title="Mot de passe oublié ?"
                isOpen={forgotPassword}
                setIsOpen={() => setForgotPassword(false)}
                backButton={
                    <Button.Secondary className="mx-auto" onClick={() => setForgotPassword(false)}>
                        Annuler
                    </Button.Secondary>
                }
                confirmButton={
                    <Button.Primary
                        iconRight={<Icon.ArrowNext className="w-5" />}
                        onClick={async () => {
                            if (email) {
                                await getNewPassword(email)
                                setSuccess(true)
                                setForgotPassword(false)
                            }
                        }}
                    >
                        Envoyer
                    </Button.Primary>
                }
            >
                <Text.Paragraph.Normal className="text-center">
                    Récupérer un nouveau mot de passe par mail pour pouvoir le changer.
                </Text.Paragraph.Normal>
                <Input.Text.Classic value={email} setValue={() => {}} labelValue="Email" />
            </Modal.default>
            <Modal.default
                title="Mot de passe réinitialisé envoyé"
                isOpen={success}
                setIsOpen={() => setSuccess(false)}
                backButton={<Button.Secondary onClick={() => setSuccess(false)}>Retour</Button.Secondary>}
            >
                <Text.Paragraph.Normal className="text-center">
                    Veuillez vous rendre sur votre messagerie électronique et utiliser le mot de passe que nous vous
                    avons envoyé pour vous connecter.
                </Text.Paragraph.Normal>
            </Modal.default>
            <Modal.default
                title="Mot de passe réinitialisé envoyé"
                isOpen={success}
                setIsOpen={() => setSuccess(false)}
                backButton={<Button.Secondary onClick={() => setSuccess(false)}>Retour</Button.Secondary>}
            >
                <Text.Paragraph.Normal className="text-center">
                    Veuillez vous rendre sur votre messagerie électronique et utiliser le mot de passe que nous vous
                    avons envoyé pour vous connecter.
                </Text.Paragraph.Normal>
            </Modal.default>
            <Image.Upload
                title="Changer d'avatar"
                setIsOpen={() => setChangeAvatar(false)}
                isOpen={changeAvatar}
                afterUpload={async (value) => {
                    await handleSaveAttribute('avatar', value)
                    setChangeAvatar(false)
                }}
            />
            <Modal.default
                title="Horaires de travail"
                isOpen={changeSchedules}
                setIsOpen={() => {}}
                disabled={loading}
                backButton={<Button.Secondary onClick={() => setChangeSchedules(false)}>Retour</Button.Secondary>}
                confirmButton={
                    <Button.Primary
                        onClick={async () => {
                            setLoading(true)
                            setShowLoading(true)
                            setChangeSchedules(false)
                            await saveSchedule(newSchedules)
                            setShowLoading(false)
                            setLoading(false)
                        }}
                        disabled={loading}
                        iconRight={
                            loading ? <Icon.Reload className="w-5 animate-spin" /> : <Icon.ArrowNext className="w-5" />
                        }
                    >
                        Enregistrer
                    </Button.Primary>
                }
            >
                {newSchedules.map((schedule, scheduleIndex) => {
                    return (
                        <div className="grid" key={schedule.name}>
                            <div className="flex space-x-2">
                                {showLoading ? (
                                    <div>
                                        {!schedule.changed && loading ? (
                                            <Icon.Check className="w-5" />
                                        ) : (
                                            <Icon.Reload className="w-5 animate-spin" />
                                        )}
                                    </div>
                                ) : null}
                                <Text.Paragraph.Normal className="capitalize">{schedule.name}</Text.Paragraph.Normal>
                                <div
                                    className={`flex items-center space-x-1 ${
                                        schedule.isWorkingDay ? 'opacity-40' : 'opacity-100'
                                    }`}
                                    onClick={() => {
                                        const newSchedulesEdit = [...newSchedules]

                                        newSchedulesEdit[scheduleIndex].isWorkingDay = !schedule.isWorkingDay
                                        newSchedulesEdit[scheduleIndex].changed = true

                                        setNewSchedules(newSchedulesEdit)
                                    }}
                                >
                                    <Input.ChecboxLittle initialValue={!schedule.isWorkingDay} /> <span>Jour off</span>
                                </div>
                            </div>

                            <div className="flex items-center">
                                {!schedule.isWorkingDay ? (
                                    <div className="z-10 flex items-center w-full space-x-4 opacity-50 cursor-not-allowed">
                                        <Input.Text.Classic
                                            readonly="readonly"
                                            type="time"
                                            value={'-'}
                                            setValue={() => {}}
                                        />
                                        <span> à </span>
                                        <Input.Text.Classic
                                            className="z-0"
                                            readonly="readonly"
                                            type="time"
                                            value={'-'}
                                            setValue={() => {}}
                                        />
                                    </div>
                                ) : (
                                    <div className="grid w-full">
                                        <div className="flex items-center justify-between w-full space-x-1">
                                            <Input.Text.TimePickerInput
                                                value={formatSecondsToInput(schedule.startOfDay)}
                                                onSave={(value) => {
                                                    const newSchedulesWithBloc = [...newSchedules]

                                                    newSchedulesWithBloc[scheduleIndex].startOfDay =
                                                        value.split(':')[0] * 3600 + value.split(':')[1] * 60
                                                    newSchedulesWithBloc[scheduleIndex].changed = true
                                                    setNewSchedules(newSchedulesWithBloc)
                                                }}
                                            />
                                            <span> à </span>
                                            <Input.Text.TimePickerInput
                                                value={formatSecondsToInput(schedule.endOfDay)}
                                                onSave={(value) => {
                                                    const newSchedulesWithBloc = [...newSchedules]

                                                    newSchedulesWithBloc[scheduleIndex].endOfDay =
                                                        value.split(':')[0] * 3600 + value.split(':')[1] * 60
                                                    newSchedulesWithBloc[scheduleIndex].changed = true

                                                    setNewSchedules(newSchedulesWithBloc)
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </Modal.default>
        </form>
    )
}

export default Form
