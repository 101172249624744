import dayjs from 'dayjs'
import React, { createContext, useContext, useState, useEffect, useRef } from 'react'

import { useRequest } from '../../request'
import { useAuth } from '../../auth'
import { useLocation, useNavigate } from 'react-router-dom'

import { socket } from '../../../api/api'

/* eslint no-unused-vars: 0 */
const AgendaContext = createContext({
    date: String,
    loaded: Boolean,
    changeDateSchedules: Boolean,
    daySelected: Boolean,
    data: {
        selectedBooking: Object,
        bookings: Array,
        loadedDays: Object,
        waitings: Array,
        cancels: Array,
    },
    view: {
        calendarView: {
            type: String,
            enum: ['mois', 'semaine', 'jour'],
        },
        bookingView: {
            type: String,
            enum: ['map', 'list'],
        },
    },
    calendarViewValues: ['mois', 'semaine', 'jour'],
    bookingViewValues: ['map', 'list'],
    handleDate: async (date) => {
        return Promise.resolve()
        // change date, currentWeek, currentYear, currentMonth
        // fetch month if currentMonth change
        // change bookings en prenant la valeur dans loadedDays
    },
    fetchMonth: async () => {
        return Promise.resolve()
        // fetch month if currentMonth change onCalendar
    },
    refreshMonth: async () => {},
    changeCalendarView: (view) => {},
    changeBookingView: (view) => {},
    updateLoadedDay: (date, value) => {},
    fetchWaitings: async () => {
        return Promise.resolve
    },
    setWaitings: (value) => {},
    setChangeDateSchedules: (daySelected) => {},
    setLoaded: (value) => {},
    stateRefresh: Boolean,
})

export function AgendaContextProvider({ children }) {
    const location = useLocation()
    const navigate = useNavigate()

    const { handleRequest } = useRequest()
    const { id, token, type, isLogged } = useAuth()

    const [loaded, setLoaded] = useState(false)
    const [stateRefresh, setStateRefresh] = useState(true)
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [selectedBooking, setSelectedBooking] = useState({})
    const [bookings, setBookings] = useState([])
    const loadedDays = useRef({})
    const setLoadedDays = (value) => (loadedDays.current.value = value)
    const [waitings, setWaitings] = useState([])

    const [calendarView, setCalendarView] = useState('month')
    const [bookingView, setBookingView] = useState('list')

    const [daySelected, setDaySelected] = useState()
    const [changeDateSchedules, setChangeDateSchedule] = useState()

    const handleDate = async (date) => {
        const formattedDate = dayjs(date).format('YYYY-MM-DD')
        setDate(formattedDate)
        const response = await handleRequest(
            'get',
            `coiffeur/days/${id}/${formattedDate}/month`,
            {
                date: date,
            },
            token
        )
        if (response) {
            if (!response?.error) {
                const loadedDaysTemp = loadedDays.current

                response?.data?.days?.map((day) => {
                    loadedDaysTemp[day.date] = day
                })

                setBookings(loadedDaysTemp[formattedDate]?.bookings)
                setStateRefresh(!stateRefresh)
                setLoadedDays(loadedDaysTemp)
                if (location.pathname.indexOf('waiting') !== -1) {
                    navigate('/hairdresser', { state: { from: '/hairdresser/waiting' } })
                }
            }
        }
    }

    const handleChangeDateSchedules = (value) => {
        if (value) {
            setDaySelected(value)
            setChangeDateSchedule(true)
        } else {
            setChangeDateSchedule(false)
        }
    }

    const fetchMonth = async () => {
        let allNewLoadedDays = {}
        const getOneMonth = async (monthToFecth) => {
            let newLoadedDays = { ...allNewLoadedDays }
            const response = await handleRequest(
                'get',
                `coiffeur/days/${id}/${dayjs(monthToFecth).format('YYYY-MM-DD')}/month`,
                null,
                token
            )

            response?.data?.days.map((day) => {
                newLoadedDays[day.date] = day
            })

            allNewLoadedDays = newLoadedDays
            setLoadedDays(newLoadedDays)
            setLoaded(true)
        }

        if (isLogged() && type === 'coiffeur') {
            const initialDate = dayjs()
            await getOneMonth(initialDate.format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(1, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(2, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(3, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(4, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(5, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(6, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(7, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(8, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(9, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(10, 'month').format('YYYY-MM-DD'))
            await getOneMonth(initialDate.add(11, 'month').format('YYYY-MM-DD'))
        }
        return
    }
    const changeCalandarView = (view) => {}
    const changeBookingView = (view) => {}

    const updateLoadedDay = (date, value) => {
        const newValue = { ...loadedDays.current }
        newValue[date] = value
        setLoadedDays(newValue)
        setBookings(value)
    }

    const fetchWaitings = async () => {
        const response = await handleRequest('get', `coiffeur/bookings/${id}/waiting`, null, token)

        if (response?.data) {
            const bookingUnsorted = response.data.bookings
            bookingUnsorted.sort((a, b) => {
                if (a.date < b.date) return -1
                else if (a.date > b.date) return 1
                else if (a.from < b.from) return -1
                else return 1
            })

            return bookingUnsorted
        }

        return []
    }

    const fetchCancels = async () => {
        const response = await handleRequest('get', `coiffeur/bookings/${id}/cancel`, null, token)

        if (response?.data) {
            const bookingUnsorted = response.data.bookings
            bookingUnsorted.sort((a, b) => {
                if (a.date < b.date) return -1
                else if (a.date > b.date) return 1
                else if (a.from < b.from) return -1
                else return 1
            })

            return bookingUnsorted
        }

        return []
    }

    const getBookingsNeedingValidation = async () => {
        const waitingsBookings = await fetchWaitings()
        const cancelsBookings = await fetchCancels()

        const bookingsMixed = [...waitingsBookings, ...cancelsBookings]
        bookingsMixed.sort((a, b) => {
            if (a.date < b.date) return -1
            else if (a.date > b.date) return 1
            else if (a.from < b.from) return -1
            else return 1
        })

        setWaitings(bookingsMixed)
    }

    useEffect(() => {
        if (isLogged()) {
            if (location.pathname.indexOf('hairdresser') !== -1 && type === 'coiffeur') {
                setLoaded(false)
                getBookingsNeedingValidation()
                fetchMonth()
            }
        }
    }, [token, id])

    useEffect(() => {
        socket.on('receive_newBooking', (data) => {
            if (data.data && isLogged()) {
                setLoaded(false)
                getBookingsNeedingValidation()
                fetchMonth()
            }
        })
    }, [token, id, socket])

    useEffect(() => {
        if (isLogged()) {
            getBookingsNeedingValidation()
        }
    }, [location])

    return (
        <AgendaContext.Provider
            value={{
                changeDateSchedules: changeDateSchedules,
                daySelected: daySelected,
                date: date,
                loaded: loaded,
                data: {
                    selectedBooking: selectedBooking,
                    bookings: bookings,
                    loadedDays: loadedDays.current,
                    waitings: waitings,
                },
                view: {
                    calendarView: calendarView,
                    bookingView: bookingView,
                },
                handleDate: handleDate,
                fetchMonth: fetchMonth,
                changeCalendarView: changeCalandarView,
                changeBookingView: changeBookingView,
                updateLoadedDay: updateLoadedDay,
                fetchWaitings: getBookingsNeedingValidation,
                setWaitings: setWaitings,
                setChangeDateSchedules: handleChangeDateSchedules,
                setLoaded: setLoaded,
                stateRefresh: stateRefresh,
                setDate: setDate,
            }}
        >
            {children}
        </AgendaContext.Provider>
    )
}

export const useAgenda = () => useContext(AgendaContext)
