'use client'

import React, { useState } from 'react'

import { Button, Input, Text } from '../../components/atoms'
import { Return } from '../../components/molecules'
import { Modal } from '.'

import { Auth } from '../../contexts'

import { useNavigate } from 'react-router-dom'

const Client = () => {
    const { handleSignup, setIsOpenCguCgv } = Auth.useAuth()
    const [sexe, setSexe] = useState()
    const [firstName, setfirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [loading, setLoading] = useState(false)
    const [isCheck, setIsCheck] = useState(false)

    const handleCheckboxChange = (event) => {
        setIsCheck(event.target.checked)
    }

    return (
        <div className="grid gap-8">
            {loading && (
                <div className="fixed top-0 bottom-0 left-0 flex flex-col justify-center items-center w-[100vw] h-[100vh] bg-white bg-opacity-80 z-[99]">
                    <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                    <Text.Paragraph.Normal>
                        Création de votre compte en cours, la configuration peut prendre quelques minutes
                    </Text.Paragraph.Normal>
                </div>
            )}
            <div className="grid gap-3">
                <Text.Paragraph.Normal className="text-lg font-medium">Sexe</Text.Paragraph.Normal>
                <div className="grid grid-cols-3">
                    <div className="flex space-x-3">
                        <Input.Radio
                            initialValue={sexe === 'female'}
                            controlled
                            controlledValue={sexe === 'female'}
                            setValue={(value) => {
                                if (value) setSexe('female')
                            }}
                        />
                        <Text.Paragraph.Normal>Femme</Text.Paragraph.Normal>
                    </div>
                    <div className="flex space-x-3">
                        <Input.Radio
                            initialValue={sexe === 'male'}
                            controlled
                            controlledValue={sexe === 'male'}
                            setValue={(value) => {
                                if (value) setSexe('male')
                            }}
                        />
                        <Text.Paragraph.Normal>Homme</Text.Paragraph.Normal>
                    </div>
                    <div className="flex space-x-3">
                        <Input.Radio
                            initialValue={sexe === 'other'}
                            controlled
                            controlledValue={sexe === 'other'}
                            setValue={(value) => {
                                if (value) setSexe('other')
                            }}
                        />
                        <Text.Paragraph.Normal>Autres</Text.Paragraph.Normal>
                    </div>
                </div>
            </div>
            <div className="grid gap-5">
                <Input.Text.Classic value={firstName} setValue={setfirstName} labelValue="Prénom" />
                <Input.Text.Classic value={lastName} setValue={setLastName} labelValue="Nom" />
                <Input.Text.Classic type="email" value={email} setValue={setEmail} labelValue="Email" />
                <Input.AutocompleteSearch.Default setValue={(value) => setAddress(value)} />
                <Input.Text.Classic
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    placeholder="06 08 06 08 06"
                    labelValue="Téléphone"
                />
                <Input.Text.Password
                    value={password}
                    setValue={(value) => setPassword(value)}
                    labelValue="Mot de passe"
                />
                <Input.Text.Password
                    value={confirmPassword}
                    setValue={(value) => setConfirmPassword(value)}
                    labelValue="Confirmation du mot de passe"
                />
            </div>
            <div className="grid gap-5 text-center">
                <div className="flex flex-col items-center justify-center gap-4 mb-8">
                    <input
                        type="checkbox"
                        checked={isCheck}
                        onChange={handleCheckboxChange}
                        className="w-4 h-4 text-green-500 border-gray-300 focus:ring-green-500 focus:border-green-500"
                    />
                    <label>
                        <Text.Paragraph.Small className="text-center">
                            Si vous cochez vous acceptez les{' '}
                            <div
                                onClick={() => setIsOpenCguCgv('cgu')}
                                className="underline text-[blue] cursor-pointer"
                            >
                                CGU
                            </div>
                        </Text.Paragraph.Small>
                    </label>
                </div>
                <div className="mx-auto">
                    <Button.Primary
                        disabled={!isCheck}
                        onClick={async () => {
                            if (!loading) {
                                setLoading(true)
                                await handleSignup(
                                    sexe,
                                    firstName,
                                    lastName,
                                    email,
                                    address,
                                    phoneNumber,
                                    password,
                                    confirmPassword,
                                    'client',
                                    null
                                )
                                setLoading(false)
                            }
                        }}
                    >
                        Inscription
                    </Button.Primary>
                </div>
            </div>
            <Modal.CGU type={'client'} />
        </div>
    )
}

const Coiffeur = () => {
    const { handleSignup, setIsOpenCguCgv } = Auth.useAuth()
    const [sexe, setSexe] = useState()
    const [firstName, setfirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [siret, setSiret] = useState('')

    const [loading, setLoading] = useState(false)
    const [isCheck, setIsCheck] = useState(false)

    const handleCheckboxChange = (event) => {
        setIsCheck(event.target.checked)
    }

    return (
        <div className="grid gap-8">
            {loading && (
                <div className="fixed top-0 bottom-0 left-0 flex flex-col justify-center items-center w-[100vw] h-[100vh] bg-white bg-opacity-80 z-[99]">
                    <div className="w-10 h-10 mx-auto border-b-[0.5px] border-l-[1px] border-t-[3px] border-r-[3px] rounded-full border-pink animate-spin"></div>
                    <Text.Paragraph.Normal>
                        Création de votre compte en cours, la configuration peut prendre quelques minutes
                    </Text.Paragraph.Normal>
                </div>
            )}
            <div className="grid gap-3">
                <Text.Paragraph.Normal className="text-lg font-medium">Sexe</Text.Paragraph.Normal>
                <div className="grid grid-cols-3">
                    <div className="flex space-x-3">
                        <Input.Radio
                            initialValue={sexe === 'female'}
                            controlled
                            controlledValue={sexe === 'female'}
                            setValue={(value) => {
                                if (value) setSexe('female')
                            }}
                        />
                        <Text.Paragraph.Normal>Femme</Text.Paragraph.Normal>
                    </div>
                    <div className="flex space-x-3">
                        <Input.Radio
                            initialValue={sexe === 'male'}
                            controlled
                            controlledValue={sexe === 'male'}
                            setValue={(value) => {
                                if (value) setSexe('male')
                            }}
                        />
                        <Text.Paragraph.Normal>Homme</Text.Paragraph.Normal>
                    </div>
                    <div className="flex space-x-3">
                        <Input.Radio
                            initialValue={sexe === 'other'}
                            controlled
                            controlledValue={sexe === 'other'}
                            setValue={(value) => {
                                if (value) setSexe('other')
                            }}
                        />
                        <Text.Paragraph.Normal>Autres</Text.Paragraph.Normal>
                    </div>
                </div>
            </div>
            <div className="grid gap-5">
                <Input.Text.Classic value={firstName} setValue={setfirstName} labelValue="Prénom" />
                <Input.Text.Classic value={lastName} setValue={setLastName} labelValue="Nom" />
                <Input.Text.Classic type="email" value={email} setValue={setEmail} labelValue="Email" />
                <Input.AutocompleteSearch.Default setValue={(value) => setAddress(value)} />
                <Input.Text.Classic
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    placeholder="06 08 06 08 06"
                    labelValue="Téléphone"
                />
                <Input.Text.Classic
                    value={siret}
                    setValue={setSiret}
                    placeholder="98119502700017"
                    labelValue="N° SIRET"
                />
                <Input.Text.Password
                    value={password}
                    setValue={(value) => setPassword(value)}
                    labelValue="Mot de passe"
                />
                <Input.Text.Password
                    value={confirmPassword}
                    setValue={(value) => setConfirmPassword(value)}
                    labelValue="Confirmation du mot de passe"
                />
            </div>
            <div className="flex flex-col items-center justify-center gap-4 mb-8">
                <input
                    type="checkbox"
                    checked={isCheck}
                    onChange={handleCheckboxChange}
                    className="w-4 h-4 text-green-500 border-gray-300 focus:ring-green-500 focus:border-green-500"
                />
                <label>
                    <Text.Paragraph.Small className="text-center">
                        Si vous cochez vous acceptez les{' '}
                        <div onClick={() => setIsOpenCguCgv('cgu')} className="underline text-[blue] cursor-pointer">
                            CGU / CGV
                        </div>
                    </Text.Paragraph.Small>
                </label>
            </div>
            <div className="grid gap-5 text-center">
                <div className="mx-auto">
                    <Button.Primary
                        disabled={!isCheck}
                        onClick={async () => {
                            if (!loading) {
                                setLoading(true)
                                await handleSignup(
                                    sexe,
                                    firstName,
                                    lastName,
                                    email,
                                    address,
                                    phoneNumber,
                                    password,
                                    confirmPassword,
                                    'coiffeur',
                                    siret
                                )
                                setLoading(false)
                            }
                        }}
                    >
                        Inscription
                    </Button.Primary>
                </div>
            </div>
            <Modal.CGU type={'coiffeur'} />
        </div>
    )
}

const SelectType = ({ type, setType }) => {
    return (
        <div className="grid gap-5 text-center">
            <Text.Paragraph.Normal>Vous êtes :</Text.Paragraph.Normal>
            <div className="grid grid-cols-2 gap-5">
                <div
                    className={`border-black border py-3 rounded ${type === 'client' ? 'bg-purple' : 'bg-white'}`}
                    onClick={() => setType('client')}
                >
                    Client
                </div>
                <div
                    className={`border-black border py-3 rounded ${type === 'coiffeur' ? 'bg-purple' : 'bg-white'}`}
                    onClick={() => setType('coiffeur')}
                >
                    Professionnel
                </div>
            </div>
        </div>
    )
}

const Signup = () => {
    const navigate = useNavigate()
    const [type, setType] = useState()

    return (
        <div className="grid gap-20">
            <Return onClick={() => navigate('/')} />

            <div className="grid gap-4 text-center">
                <Text.h1>Inscription</Text.h1>
                <div>
                    <Text.Paragraph.Normal>Vous avez déjà un compte ?</Text.Paragraph.Normal>
                    <Text.Link href="/login">Connexion</Text.Link>
                </div>
            </div>
            <div className="grid gap-10">
                <SelectType type={type} setType={setType} />
                {type === 'client' && <Client />}
                {type === 'coiffeur' && <Coiffeur />}
            </div>
        </div>
    )
}

export default Signup
