import React, { useEffect, useState } from 'react'
import { Text } from '../../../atoms'
import { Client, Auth } from '../../../../contexts'
import { PrestationComing } from '../../../molecules/Card'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { Modal } from '.'
import { formatSecondsToInput } from '../../../../utils'

export const Historical = () => {
    const navigate = useNavigate()
    const { isLogged, id, token } = Auth.useAuth()
    const { setCoiffeurSelected } = Client.useCoiffeur()
    const { bookingsClient, getBookings, handleBookingSelected } = Client.useBookingsClient()

    const [activeView, setActiveView] = useState()

    const [passedBookings, setPassedBookings] = useState(
        bookingsClient
            .filter(
                (booking) =>
                    dayjs(`${booking.date}T${formatSecondsToInput(booking.from)}:00`).toISOString() <
                    dayjs().toISOString()
            )
            .reverse()
    )

    const [futureBookings, setFutureBookings] = useState(
        bookingsClient.filter(
            (booking) =>
                dayjs(`${booking.date}T${formatSecondsToInput(booking.from)}:00`).toISOString() >= dayjs().toISOString()
        )
    )

    useEffect(() => {
        if (isLogged()) {
            getBookings()
            setActiveView('futur')
        }
    }, [id, token])

    useEffect(() => {
        setPassedBookings(
            bookingsClient
                .filter(
                    (booking) =>
                        dayjs(`${booking.date}T${formatSecondsToInput(booking.from)}:00`).toISOString() <
                        dayjs().toISOString()
                )
                .reverse()
        )

        setFutureBookings(
            bookingsClient.filter(
                (booking) =>
                    dayjs(`${booking.date}T${formatSecondsToInput(booking.from)}:00`).toISOString() >=
                    dayjs().toISOString()
            )
        )
    }, [bookingsClient])

    return (
        <div className="grid gap-1">
            <Text.h1 className="mb-3 text-center">Mes réservations</Text.h1>
            <div className="grid grid-cols-2 gap-2">
                <button
                    className={`inline-block px-4 py-2 rounded transition-all ${
                        activeView === 'futur' ? 'bg-blue border border-black' : 'bg-light-gray'
                    }`}
                    onClick={() => setActiveView('futur')}
                >
                    À venir
                </button>
                <button
                    className={`inline-block px-4 py-2 rounded transition-all ${
                        activeView === 'pass' ? 'bg-blue border border-black' : 'bg-light-gray'
                    }`}
                    onClick={() => setActiveView('pass')}
                >
                    Passées
                </button>
            </div>
            {futureBookings?.map((booking) => {
                if (activeView === 'futur') {
                    if (
                        dayjs(`${booking.date}T${formatSecondsToInput(booking.from)}:00`).toISOString() >=
                        dayjs().toISOString()
                    ) {
                        const displayPrice = booking.priceVariable
                            ? `À partir de ${booking.price}€`
                            : `${booking.price}€`
                        const countMap = new Map()

                        booking._prestation.forEach((prestation) => {
                            countMap.set(prestation._id, (countMap.get(prestation._id) || 0) + 1)
                        })

                        return (
                            <div
                                className="cursor-pointer"
                                key={booking._id}
                                onClick={() => {
                                    handleBookingSelected('details', booking)
                                }}
                            >
                                <PrestationComing
                                    rebook={() => {
                                        setCoiffeurSelected(booking._coiffeur)
                                        navigate(
                                            `/client/hairdresser/${
                                                booking._coiffeur._id
                                            }/services?prestation=${booking._prestation.map(
                                                (prestation, index) =>
                                                    `${prestation._id}${
                                                        index !== booking._prestation.length - 1 ? ',' : ''
                                                    }`
                                            )}`
                                        )
                                    }}
                                    key={booking._id}
                                    srcImage={booking?._coiffeur?._profil?.avatar?.thumbnailUrl}
                                    title={`${booking?._coiffeur?._profil?.firstName} ${booking?._coiffeur?._profil?.lastName}`}
                                    infoRdv={[...countMap.keys()].map((prestationId, index) => {
                                        const prestation = booking._prestation.find(
                                            (prestation) => prestation._id === prestationId
                                        )

                                        const quantity = countMap.get(prestationId)
                                        if (quantity > 1) {
                                            return `${prestation.name} x${countMap.get(prestationId)}${
                                                index !== countMap.size - 1 ? ', ' : ''
                                            }`
                                        } else {
                                            return `${prestation.name}${index !== countMap.size - 1 ? ', ' : ''}`
                                        }
                                    })}
                                    text={`${dayjs(booking.date).format('DD MMM YYYY')}- ${displayPrice}`}
                                    bookingState={booking?.state}
                                />
                            </div>
                        )
                    }
                }
            })}
            {passedBookings?.map((booking) => {
                if (activeView === 'pass') {
                    if (
                        dayjs(`${booking.date}T${formatSecondsToInput(booking.from)}:00`).toISOString() <
                        dayjs().toISOString()
                    ) {
                        const displayPrice = booking.priceVariable
                            ? `À partir de ${booking.price}€`
                            : `${booking.price}€`
                        const countMap = new Map()

                        booking._prestation.forEach((prestation) => {
                            countMap.set(prestation._id, (countMap.get(prestation._id) || 0) + 1)
                        })

                        return (
                            <div
                                className="cursor-pointer"
                                key={booking._id}
                                onClick={() => {
                                    handleBookingSelected('details', booking)
                                }}
                            >
                                <PrestationComing
                                    rebook={() => {
                                        setCoiffeurSelected(booking._coiffeur)
                                        navigate(
                                            `/client/hairdresser/${
                                                booking._coiffeur._id
                                            }/services?prestation=${booking._prestation.map(
                                                (prestation, index) =>
                                                    `${prestation._id}${
                                                        index !== booking._prestation.length - 1 ? ',' : ''
                                                    }`
                                            )}`
                                        )
                                    }}
                                    key={booking._id}
                                    srcImage={booking?._coiffeur?._profil?.avatar?.thumbnailUrl}
                                    title={`${booking?._coiffeur?._profil?.firstName} ${booking?._coiffeur?._profil?.lastName}`}
                                    infoRdv={[...countMap.keys()].map((prestationId, index) => {
                                        const prestation = booking._prestation.find(
                                            (prestation) => prestation._id === prestationId
                                        )

                                        const quantity = countMap.get(prestationId)
                                        if (quantity > 1) {
                                            return `${prestation.name} x${countMap.get(prestationId)}${
                                                index !== countMap.size - 1 ? ', ' : ''
                                            }`
                                        } else {
                                            return `${prestation.name}${index !== countMap.size - 1 ? ', ' : ''}`
                                        }
                                    })}
                                    text={`${dayjs(booking.date).format('DD MMM YYYY')}- ${displayPrice}`}
                                    bookingState={booking?.state}
                                />
                            </div>
                        )
                    }
                }
            })}
            <Modal.BookingDetails />
            <Modal.Confirmation />
        </div>
    )
}
